import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GenericWorkItem, LoadingSpinnerService, TaskType, WorkItemOfTracesDocomItemContent, WorkItemOfVlmMadItemContent, WorkItemStatus, WorkService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { AlertMessage, AlertType } from '@app/_shared-components/alert/alert.component';
import { faHome } from '@fortawesome/pro-regular-svg-icons';
import { TaskTypePipe } from '../../_shared-components/enum/task-type.pipe';
import { WorkItemStatusPipe } from '../../_shared-components/enum/work-item-status.pipe';
import { WorkTypePipe } from '../../_shared-components/enum/work-type.pipe';
import { AlertComponent } from '../../_shared-components/alert/alert.component';
import { VlmMadItemComponent } from '../../editors/vlm/mad/vlm-mad-item/vlm-mad-item.component';
import { TracesDocomItemComponent } from '../../editors/traces/docom/traces-docom-item/traces-docom-item.component';
import { WorkItemLogComponent } from '../work-item-log/work-item-log.component';
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-work-item',
    templateUrl: './work-item.component.html',
    styleUrls: ['./work-item.component.scss'],
    standalone: true,
    imports: [RouterLink, FaIconComponent, NgClass, NgIf, WorkItemLogComponent, NgSwitch, NgSwitchCase, TracesDocomItemComponent, VlmMadItemComponent, NgSwitchDefault, AlertComponent, WorkTypePipe, WorkItemStatusPipe, TaskTypePipe]
})
export class WorkItemComponent implements OnInit {
    faHome = faHome;
    workItemStatus = WorkItemStatus;

    constructor(
        private route: ActivatedRoute,
        private workService: WorkService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService) { }

    private sub: any;
    workItemId: number | undefined;

    workItem: GenericWorkItem | undefined;
    taskType = TaskType;
    alertsUnknownTaskType: Array<AlertMessage> = [new AlertMessage({ message: $localize`:Unkown work item type@@traces_docom_unknown_work_item_type:Unknown task type`, type: AlertType.Error })]


    ngOnInit(): void {
        this.sub = this.route.queryParams.subscribe(params => {
            this.workItemId = +params['id']; // (+) converts string 'id' to a number
            this.loadWorkItem();
        });

    }

    private loadWorkItem(): void {
        this.spinnerService.showLoader(this.workService.getGenericWorkItem(this.workItemId)).subscribe((result) => {
            this.workItem = result;
        })
    }

}

