import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingSpinnerService, TracesDocomService, TracesDocomCancel, WorkItemOfTracesDocomCancel } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { TracesDocomBaseItem } from '../traces-docom-base-item';
import { TracesDocomItemHeaderComponent } from '../traces-docom-item-header/traces-docom-item-header.component';
@Component({
    selector: 'app-traces-docom-cancel',
    templateUrl: './traces-docom-cancel.component.html',
    styleUrls: ['./traces-docom-cancel.component.scss'],
    standalone: true,
    imports: [TracesDocomItemHeaderComponent]
})
export class TracesDocomCancelComponent extends TracesDocomBaseItem implements OnInit {

    cancelWorkItem: WorkItemOfTracesDocomCancel | undefined;

    constructor(
        private service: TracesDocomService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.workItemId) {
            this.fetchCancelWorkItem();
        }
    }

    private fetchCancelWorkItem(): void {
        this.spinnerService.showLoader(this.service.getCancel(this.workItemId)).subscribe({
            next: result => {
                this.cancelWorkItem = result;
            }
        })
    }

    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateCancel(this.cancelWorkItem!)).subscribe({
            next: result => {
                this.cancelWorkItem = result;
                this.workItemUpdated.emit(this.cancelWorkItem);
            }
        })
    }
}
