import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService, ConfirmDialogService, ExceptionHandlerService, LoadingSpinnerService } from '@app/_services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BatchContentComponent } from './batch-content/batch-content.component';
import { BatchHeaderComponent } from './batch-header/batch-header.component';
import { BatchItemComponent } from './batch-item/batch-item.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { CompanySettingComponent } from './company-setting/company-setting.component';
import { WorkItemComponent } from './work-item/work-item.component';
import { WorkItemListComponent } from './work-item-list/work-item-list.component';
import { WorkItemLogComponent } from './work-item-log/work-item-log.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TaskTypePipe, WorkBatchStatusPipe, WorkItemStatusPipe, WorkTypePipe } from '../_shared-components/enum';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VlmModule } from '@app/editors/vlm/vlm.module';
import { TracesModule } from '@app/editors/traces/traces.module';
import { SharedComponentsModule } from '@app/_shared-components/shared-components.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { WorkItemLogLevelPipe } from '@app/_shared-components/enum/work-item-log-level.pipe';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { FilterHeaderComponent } from '@app/_shared-components/filter-header/filter-header.component';
import { MatExpansionModule } from '@angular/material/expansion';






@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        FontAwesomeModule,
        MatDatepickerModule,
        MatIconModule,
        RouterModule,
        WorkTypePipe,
        WorkBatchStatusPipe,
        WorkItemStatusPipe,
        MatPaginatorModule,
        VlmModule,
        TracesModule,
        SharedComponentsModule,
        MatPaginatorModule,
        MatIconModule,
        MatFormFieldModule,
        MatTabsModule,
        TaskTypePipe,
        WorkItemLogLevelPipe,
        FontAwesomeModule,
        MatSortModule,
        FilterHeaderComponent,
        MatExpansionModule,
        ConfirmDialogComponent,
        BatchContentComponent,
        BatchHeaderComponent,
        BatchItemComponent,
        BatchListComponent,
        CompanySettingComponent,
        ConfirmDialogComponent,
        WorkItemComponent,
        WorkItemListComponent,
        WorkItemLogComponent,
    ],
    exports: [],
    providers: [
        ExceptionHandlerService,
        AlertService,
        LoadingSpinnerService,
        ConfirmDialogService,
    ]
})
export class ComponentsModule { }
