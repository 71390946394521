import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WorkItemOfVlmMadCreate, WorkItemOfVlmMadItemContent, AlertService, Translation } from '@app/_services';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-vlm-mad-item-header',
    templateUrl: './vlm-mad-item-header.component.html',
    styleUrls: ['./vlm-mad-item-header.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatInput, FormsModule]
})
export class VlmMadItemHeaderComponent implements OnInit, OnChanges {
    @Input() workItem: WorkItemOfVlmMadItemContent | undefined | null;
    @Output() changed: EventEmitter<unknown> = new EventEmitter();


    constructor(private alertService: AlertService) { }

    get isCreate(): boolean {
        if (!this.workItem) return false;
        return this.workItem instanceof WorkItemOfVlmMadCreate;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workItem?.currentValue?.content && !changes.workItem.currentValue.content.operatorCode) changes.workItem.currentValue.content.operatorCode = new Translation();
    }

    ngOnInit(): void {
    }

    dataChanged(): void {
        this.changed.emit(null);
    }
}
