import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, Traces2FAMethod } from '@app/_services';

@Pipe({
    name: 'traces2faMethod',
    standalone: true
})
export class Traces2faMethodPipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: Traces2FAMethod | undefined | null): string {
        if (value == null) {
            return $localize`:Traces2faMethod undefined:`;
        }
        switch (value) {
            case Traces2FAMethod.Unknown:
                return $localize`:Traces2faMethod unknown:`;
            case Traces2FAMethod.Password:
                return $localize`:Traces2faMethod:Password only`;
            case Traces2FAMethod.AuthenticationAppPincode:
                return $localize`:Traces2faMethod:Authentication app with pincode`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `Traces2faMethod enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
