import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, TaskType } from '@app/_services';
import { ca } from 'date-fns/locale';

@Pipe({
    name: 'taskType',
    standalone: true,
})
export class TaskTypePipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: TaskType | undefined | null, fullName: boolean = false): string {
        if (value == null) return $localize`:Task type undefined:`;
        switch (value) {
            case TaskType.Undefined:
                return $localize`:Task type undefined:`;
            case TaskType.Traces_Docom_Cancel:
                return fullName ? $localize`:Task type Traces_Docom_Cancel:Traces DOCOM cancel` : $localize`:Task type Traces_Docom_Cancel:Cancel`;
            case TaskType.Traces_Docom_Create:
                return fullName ? $localize`:Task type Traces_Docom_Create:Traces DOCOM create` : $localize`:Task type Traces_Docom_Create:Create`;
            case TaskType.Traces_Docom_Download:
                return fullName ? $localize`:Task type Traces_Docom_Download:Traces DOCOM download` : $localize`:Task type Traces_Docom_Download:Download`;
            case TaskType.Vlm_Mad_Cancel:
                return fullName ? $localize`:Task type Vlm_Mad_Cancel:VLM MAD cancel` : $localize`:Task type Vlm_Mad_Cancel:Cancel`;
            case TaskType.Vlm_Mad_Close:
                return fullName ? $localize`:Task type Vlm_Mad_Close:VLM MAD close` : $localize`:Task type Vlm_Mad_Close:Close`;
            case TaskType.Vlm_Mad_Create:
                return fullName ? $localize`:Task type Vlm_Mad_Create:VLM MAD create` : $localize`:Task type Vlm_Mad_Create:Create`;
            case TaskType.Vlm_Mad_Download:
                return fullName ? $localize`:Task type Vlm_Mad_Download:VLM MAD download` : $localize`:Task type Vlm_Mad_Download:Download`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `TaskType enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
