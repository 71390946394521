import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BatchItemComponent } from './components/batch-item/batch-item.component';
import { AuthGuard } from './_helpers';
import { WorkItemComponent } from './components/work-item/work-item.component';
import { UserComponent } from './users/user/user.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { BatchListComponent } from './components/batch-list/batch-list.component';
import { CompanySettingComponent } from './components/company-setting/company-setting.component';
import { RecoverPasswordComponent } from './users/recover-password/recover-password.component';
import { UserRole } from './_services/api.service';
import { LoginComponent } from './users/login/login.component';
import { DataTranslationEditorComponent } from './components/data-translation-editor/data-translation-editor.component';

const routes: Routes = [
    // {
    //     path: '',
    //     component: HomeComponent,
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        //data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'batches',
        component: BatchListComponent,
        canActivate: [AuthGuard],
        // data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'batch',
        component: BatchItemComponent,
        canActivate: [AuthGuard],
        // data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'batch/:id',
        component: BatchItemComponent,
        canActivate: [AuthGuard],
        // data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'workItem',
        component: WorkItemComponent,
        canActivate: [AuthGuard],
        // data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'workItem/:id',
        component: WorkItemComponent,
        canActivate: [AuthGuard],
        // data: { roles: [Role.Admin, Role.Editor] }
    },
    {
        path: 'translations',
        component: DataTranslationEditorComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'users',
        component: UserListComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserRole.Admin, UserRole.UserAdmin] }
    },
    {
        path: 'user/change-password/:token',
        component: ChangePasswordComponent,
    },
    {
        path: 'user/change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/recover-password',
        component: RecoverPasswordComponent,
    },
    {
        path: 'user/:id',
        component: UserComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserRole.Admin, UserRole.UserAdmin] }
    },
    {
        path: 'login',
        component: LoginComponent
    },

    {
        path: 'settings/document',
        component: CompanySettingComponent,
        canActivate: [AuthGuard],
        data: { roles: [UserRole.Admin] }
    },




    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
