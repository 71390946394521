import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { LoadingSpinnerService, VlmMadService, WorkItemOfVlmMadCreate } from '@app/_services';
import { VlmMadBaseItem } from '../vlm-mad-base-item';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { CommonModule, NgIf } from '@angular/common';
import { VlmMadItemHeaderComponent } from '../vlm-mad-item-header/vlm-mad-item-header.component';
import { createTranslationFormGroup } from '@app/editors/helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, Observable, debounceTime, filter, mergeMap, shareReplay } from 'rxjs';
import { deepMerge } from '@app/_helpers';
import { NgLetModule } from 'ng-let';

@Component({
    selector: 'app-vlm-mad-create',
    templateUrl: './vlm-mad-create.component.html',
    styleUrls: ['./vlm-mad-create.component.scss'],
    standalone: true,
    imports: [VlmMadItemHeaderComponent, MatInputModule, MatFormField, MatLabel, MatInput, FormsModule, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, ReactiveFormsModule, CommonModule, NgLetModule]
})
export class VlmMadCreateComponent extends VlmMadBaseItem implements OnInit {
    destroyRef = inject(DestroyRef)

    workItem$: Observable<WorkItemOfVlmMadCreate> = EMPTY;
    private workItem!: WorkItemOfVlmMadCreate;

    form: FormGroup;

    constructor(
        private service: VlmMadService,
        private spinnerService: LoadingSpinnerService,
        private fb: FormBuilder,
    ) {
        super();
        this.form = this.fb.group({
            deliveredApproval: createTranslationFormGroup(this.fb),
            deliveredApprovalNumber: createTranslationFormGroup(this.fb),
            transporter: createTranslationFormGroup(this.fb),
            transporterStreet: createTranslationFormGroup(this.fb),
            transporterZipCode: createTranslationFormGroup(this.fb),
            transporterCity: createTranslationFormGroup(this.fb),
            transporterNumber: createTranslationFormGroup(this.fb),
            contractCode: createTranslationFormGroup(this.fb),
            sender: createTranslationFormGroup(this.fb),
            senderAlias: createTranslationFormGroup(this.fb),
            senderCountry: createTranslationFormGroup(this.fb),
            senderTransportCharacter: createTranslationFormGroup(this.fb),
            senderCharacterNumber: createTranslationFormGroup(this.fb),
            receiver: createTranslationFormGroup(this.fb),
            receiverAlias: createTranslationFormGroup(this.fb),
            receiverCountry: createTranslationFormGroup(this.fb),
            receiverTransportCharacter: createTranslationFormGroup(this.fb),
            receiverCharacterNumber: createTranslationFormGroup(this.fb),
            manureType: createTranslationFormGroup(this.fb),
            manureContentValue: createTranslationFormGroup(this.fb),
            date: new FormControl<Date | null>(null),
            resumeDate: new FormControl<Date | null>(null),
            licensePlate: createTranslationFormGroup(this.fb),
            licensePlateTrailer: createTranslationFormGroup(this.fb),
            plannedLoad: new FormControl<Number | null>(null),
            contentKgTonN: new FormControl<Number | null>(null),
            contentKgTonP2O2: new FormControl<Number | null>(null),
            plannedFreights: new FormControl<Number | null>(null),
            previousDocumentReference: [''],
            previousDocumentId: ['']
        })


    }


    ngOnInit(): void {
        this.form.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            filter(formValues => !!formValues?.sender?.documentValue),
            debounceTime(this.debounceTime),
            mergeMap(formValues => this.service.updateCreate(this.prepareUpdate(formValues)))
        ).subscribe(result => {
            this.patchForm(result)
            this.workItemUpdated.emit(result)
        });

        if (this.workItemId)
            this.workItem$ = this.spinnerService.showLoader(this.service.getCreate(this.workItemId)
                .pipe(
                    shareReplay({ bufferSize: 1, refCount: true }),
                ));

        this.workItem$.subscribe(workItem => {
            this.patchForm(workItem);
        });
    }


    private patchForm(workItem: WorkItemOfVlmMadCreate): void {
        if (!this.workItem) {
            this.form.patchValue(workItem.content!, { emitEvent: false });
            if (!workItem.canEdit) this.form.disable();
        }
        this.workItem = workItem;
    }

    private prepareUpdate(formValues: any): WorkItemOfVlmMadCreate {
        let update = this.workItem;
        update.content = deepMerge(update.content, formValues);
        return update;
    }

    /**
     * @deprecated Remove method after header migration to reactive forms
     */
    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateCreate(this.workItem!)).subscribe(result => {
            this.workItem = result;
            this.workItemUpdated.emit(result);
        })
    }
}
