import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { deepMerge } from '@app/_helpers/deep-merge';
import { AlertService, Credentials, LoadingSpinnerService, Traces2FAMethod, TracesDocomCompanySetting, TracesDocomService } from '@app/_services';
import { Observable, Subscription, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { FormatTimeSpanPipe } from '../../../../_shared-components/format-time-span.pipe';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, AsyncPipe, CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { Traces2faMethodPipe } from '@app/_shared-components/enum/traces-2fa-method.pipe';

@Component({
    selector: 'app-traces-docom-setting',
    templateUrl: './traces-docom-setting.component.html',
    styleUrls: ['./traces-docom-setting.component.scss'],
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatCheckbox, AsyncPipe, FormatTimeSpanPipe, MatRadioModule, Traces2faMethodPipe]
})
export class TracesDocomSettingComponent implements OnInit, OnDestroy {
    public methods2FA: Array<Traces2FAMethod> = Object.values(Traces2FAMethod).filter(m => m != Traces2FAMethod.Unknown);
    form: FormGroup;
    private subscriptions = new Subscription();
    public setting: TracesDocomCompanySetting = new TracesDocomCompanySetting();
    public autoStartBatchTimeout$!: Observable<string>;
    traces2FAMethod = Traces2FAMethod;

    constructor(
        private service: TracesDocomService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            credentials: this.fb.group({
                userName: [''],
                password: ['']
            }),
            method2FA: [Traces2FAMethod.AuthenticationAppPincode],
            deviceName: [''],
            autoStartBatch: [false],
            testMode: [false]
        });
    }

    get userNameControl() {
        return this.form.get('credentials.userName') as FormControl;
    }
    get passwordControl() {
        return this.form.get('credentials.password') as FormControl;
    }
    get method2FAControl() {
        return this.form.get('method2FA') as FormControl<Traces2FAMethod>;
    }
    get deviceNameControl() {
        return this.form.get('deviceName') as FormControl;
    }
    get autoStartBatchControl() {
        return this.form.get('autoStartBatch') as FormControl<boolean>;
    }

    private getSettingsObservable() {
        return this.spinnerService.showLoader(this.service.getSetting())
            .pipe(
                tap((setting: TracesDocomCompanySetting) => {
                    setting.credentials ??= new Credentials();
                    this.form.patchValue(setting, { emitEvent: false });
                    this.setting = setting;
                })
            );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.form.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap((value: TracesDocomCompanySetting) => {
                    let update = deepMerge(this.setting ?? new TracesDocomCompanySetting(), value);
                    if (!update.credentials?.userName || !update.credentials.password)
                        update.autoStartBatch = false;
                    this.service.updateSetting(update)
                        .subscribe()
                })
            )
            .subscribe());
        this.subscriptions.add(this.getSettingsObservable().subscribe());
        this.autoStartBatchTimeout$ = this.service.getAutoStartTimeout();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
