import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, WorkBatchStatus } from '@app/_services';

@Pipe({
    name: 'workBatchStatus',
    standalone: true,
})
export class WorkBatchStatusPipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: WorkBatchStatus | undefined | null): string {
        if (value == null) {
            return $localize`:Work batch status undefined:`;
        }
        switch (value) {
            case WorkBatchStatus.Undefined:
                return $localize`:Work batch status undefined:`;
            case WorkBatchStatus.Invalid:
                return $localize`:Work batch status Invalid:Invalid`;
            case WorkBatchStatus.Created:
                return $localize`:Work batch status Created:Created`;
            case WorkBatchStatus.Queued:
                return $localize`:Work batch status Queued:Queued`;
            case WorkBatchStatus.Processing:
                return $localize`:Work batch status Processing:Processing`;
            case WorkBatchStatus.Finished:
                return $localize`:Work batch status Finished:Finished`;
            case WorkBatchStatus.Faulted:
                return $localize`:Work batch status Faulted:Faulted`;
            case WorkBatchStatus.Deleted:
                return $localize`:Work batch status Deleted:Deleted`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `WorkBatchStatus enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
