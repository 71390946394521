<div *ngIf="!workItemId && workItem">
    <mat-form-field>
        <mat-label i18n="Traces Docom task type">Task</mat-label>
        <mat-select [(ngModel)]="workItem.taskType">
            <mat-option value="" selected></mat-option>
            <mat-option *ngFor="let task of taskTypes" [value]="task">
                {{task | taskType}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<ng-container *ngIf="workItem" [ngSwitch]="workItem.taskType">
    <app-traces-docom-cancel *ngSwitchCase="taskType.Traces_Docom_Cancel" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-traces-docom-cancel>
    <app-traces-docom-create *ngSwitchCase="taskType.Traces_Docom_Create" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-traces-docom-create>
    <app-traces-docom-download *ngSwitchCase="taskType.Traces_Docom_Download" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-traces-docom-download>
    <app-alert *ngSwitchDefault [alerts]="alertsUnknownTaskType"></app-alert>
</ng-container>