<div class="batchContent" *ngLet="batch$ | async as batch">
    <form *ngIf="batch?.content" [formGroup]="form">
        <div class="credentials" formGroupName="credentials">
            <mat-form-field>
                <mat-label i18n="Traces Docom batch content username">username</mat-label>
                <input matInput i18n-placeholder="races Docom batch content username placeholder" placeholder="username" [formControl]="userNameControl">

            </mat-form-field>
            <mat-form-field>
                <mat-label i18n="races Docom batch contents password">password</mat-label>
                <input matInput i18n-placeholder="races Docom batch content password placeholder" type="password" placeholder="password" [formControl]="passwordControl">
            </mat-form-field>
        </div>

        <div class="loginMethod">
            <mat-label i18n="Traces DOCOM settings login method">2FA method</mat-label>
            <mat-radio-group formControlName="method2FA">
                <mat-radio-button *ngFor="let method of methods2FA" class="py-1" [value]="method">
                    {{method | traces2faMethod}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="loginDevice" *ngIf="method2FAControl.value == traces2FAMethod.AuthenticationAppPincode">
            <mat-form-field>
                <mat-label i18n="Traces DOCOM settings authentication device">authentication device name</mat-label>
                <input matInput i18n-placeholder="Traces DOCOM authentication device placeholder" placeholder="device name" formControlName="deviceName">
            </mat-form-field>
        </div>
        <div class="testMode">
            <mat-checkbox [formControl]="testModeControl" i18n="Traces DOCOM settings testmode checkbox">Test mode</mat-checkbox>
        </div>
    </form>
    <button mat-icon-button matSuffix aria-label="Edit batch content" (click)="enableBatchEdit()" *ngIf="form.disabled">
        <fa-icon [icon]="faPencil"></fa-icon>
    </button>
</div>
<hr />
<div>
    <mat-form-field class="col-2">
        <mat-label i18n>Search work item</mat-label>
        <input matInput #searchInput>
        <fa-icon matSuffix [icon]="faMagnifyingGlass" class="me-3"></fa-icon>
    </mat-form-field>
</div>
<div matSort>
    <div class="gridRow rsGridHeader">
        <div mat-sort-header="id" class="id">
            <h5 i18n="Work item list work item id header">ID</h5>
        </div>
        <div class="reference">
            <h5 mat-sort-header="reference" i18n="Work item reference header">Reference</h5>
        </div>
        <div class="documentReference">
            <h5 mat-sort-header="documentReference" i18n="Work item document reference header">Document</h5>
        </div>
        <div class="task">
            <h5 mat-sort-header="task" i18n="Work item task header">Task</h5>
        </div>
        <div class="status">
            <h5 mat-sort-header="status" i18n="Work item status header">Status</h5>
        </div>
        <div class="log">

        </div>
        <div class="controls">
            <button mat-icon-button aria-label="Add work item" i18n-aria-label="MAD add work item" i18n-matTooltip="MAD add work item" matTooltip="Add work item" [disabled]="!canEditBatch" (click)="addWorkItem(null)">
                <fa-icon [icon]="faPlus"></fa-icon>
            </button>
        </div>
    </div>
    <hr />
    <ng-container *ngIf="batch$ | async as batch; else noWorkItems">
        <ng-container *ngIf="batch.workItemCount; else noWorkItems">
            <ng-container *ngFor="let workItem of batch?.workItems">
                <div class="gridRow">
                    <div class="id">
                        <a [routerLink]="['/workItem']" [queryParams]="{id: workItem.workItemId}">
                            {{workItem.workItemId}}
                        </a>
                    </div>
                    <div class="reference">
                        <a [attr.href]="workItem.documentUrl" target="_blank">
                            {{workItem.customerReference ? workItem.customerReference : workItem.documentReference}}
                        </a>
                    </div>
                    <div class="documentReference">
                        {{workItem.documentReference}}
                        <button mat-button (click)="downloadDocument(workItem.workItemId)" *ngIf="workItem.hasDocument">
                            <fa-icon [icon]="faDownload"></fa-icon>
                        </button>
                    </div>
                    <div class="task">
                        {{workItem.taskType| taskType}}
                    </div>
                    <div class="status">
                        <span [ngClass]="{'text-danger': workItem.status === workItemStatus.Faulted, 'text-success': workItem.status === workItemStatus.Finished}">
                            {{workItem.status| workItemStatus}}
                        </span>
                    </div>
                    <div class="log">
                        <fa-icon size="lg" *ngIf="hasWarnings(workItem)" class="text-warning" [icon]="faExclamationTriangle"></fa-icon>
                        <fa-icon size="lg" *ngIf="hasErrors(workItem)" class="text-danger" [icon]="faDiamondExclamation"></fa-icon>
                    </div>
                    <div class="controls">
                        <!-- <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{workItem: workItem}" i18n-matTooltip="MAD work item tools" matTooltip="Work item tools">
                        <mat-icon>more_vert</mat-icon>
                    </button> -->
                    </div>
                </div>
                <hr />
            </ng-container>
        </ng-container>
    </ng-container>
    <mat-paginator [pageSizeOptions]="pageSizes" [length]="totalRecordCount" [pageSize]="filter.pageSize"></mat-paginator>
    <ng-template #noWorkItems>
        <span i18n="No work items found">No work items found</span>
    </ng-template>
</div>


<mat-menu #appMenu="matMenu">
    <ng-template matMenuContent let-workItem="workItem">
        <!-- <button type="button" *ngIf="!workItem.canEdit" mat-menu-item (click)="openDialog(workItem)" i18n="MAD batch view workitem button">View</button>
        <button type="button" [disabled]="!workItem.canEdit" mat-menu-item (click)="openDialog(workItem)" i18n="MAD batch edit workitem button">Edit</button> -->
        <button type="button" [disabled]="!workItem.canEdit" mat-menu-item (click)="confirmRemove(workItem)" i18n="MAD batch delete workitem button">Delete</button>
        <!-- <mat-divider></mat-divider> -->
        <!-- <button type="button" mat-menu-item (click)="showLog(workItem)" i18n="MAD batch view workitem log button">Log</button> -->
        <!-- TODO: -->
        <!-- <mat-divider></mat-divider>
        <button type="button"  mat-menu-item (click)="showResult(workItem)" i18n="MAD batch view workitem result button">Result</button> -->
    </ng-template>
</mat-menu>