import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkItemOfTracesDocomCancel, WorkItemOfTracesDocomCreate, WorkItemOfTracesDocomDownload, WorkItemOfTracesDocomItemContent } from '@app/_services';

@Component({
    selector: 'app-traces-docom-base-item',
    template: ''
})
export class TracesDocomBaseItem {
    @Input() workItemId: number | undefined;
    @Output() workItemUpdated: EventEmitter<WorkItemOfTracesDocomItemContent> = new EventEmitter<WorkItemOfTracesDocomItemContent>();
}
