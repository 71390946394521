<div class="rsPageHeader">
    <h1 i18n="Users page title">Users</h1>
    <button mat-raised-button matTooltip="Add user" i18n-matTooltip="User list add user" [routerLink]='["/user/null"]'>
        <fa-icon [icon]="faPlus"></fa-icon> Add user
    </button>
</div>

<div>

    <mat-form-field>
        <mat-label i18n="User list search box label">Search </mat-label>
        <input matInput #searchInput>
        <fa-icon matSuffix [icon]="faMagnifyingGlass" class="me-3"></fa-icon>
    </mat-form-field>


    <div matSort>
        <div class="rowWrapper">
            <div class="header firstName" mat-sort-header="firstName" i18n="User list first name">
                First name
            </div>
            <div class="header lastName" mat-sort-header="lastName" i18n="User list last name">
                Last name
            </div>
            <div class="header email" mat-sort-header="email" i18n="User list email">
                Email
            </div>
        </div>
        <hr />
        <ng-container *ngIf="list$ | async as list; else noItems">
            <ng-container *ngIf="list.length > 0; else noItems">
                <ng-container *ngFor="let item of list">
                    <div class="rowWrapper">
                        <div class="firstName">
                            <a [routerLink]="['/user', item?.id]">
                                {{item.firstName}}
                            </a>
                        </div>
                        <div class="lastName">
                            <a [routerLink]="['/user', item?.id]">
                                {{item.lastName}}
                            </a>
                        </div>
                        <div class="email">
                            <a [routerLink]="['/user', item?.id]">
                                {{item.username}}
                            </a>
                        </div>
                    </div>
                    <hr />
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template i18n="User list no items found" #noItems>No users found!</ng-template>
        <mat-paginator [pageSizeOptions]="pageSizes" [length]="totalRecordCount" [pageSize]="filter.pageSize"></mat-paginator>
    </div>
</div>