import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, DestroyRef, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Router, RouterLink } from '@angular/router';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { ConfirmDialogData } from '@app/components/confirm-dialog/confirm-dialog.component';
import { BaseBatchEditor } from '@app/editors/base-batch-editor-component';
import { AlertService, BatchUpdateRequestOfTracesDocomBatchAndTracesDocomBatchContentAndTracesDocomItemContent, LoadingSpinnerService, SignalRService, Traces2FAMethod, TracesDocomBatch, TracesDocomService, WorkItemFilter, WorkItemLogLevel, WorkItemOfTracesDocomItemContent, WorkItemStatus, WorkService } from '@app/_services';
import { ConfirmDialogService } from '@app/_services/confirm-dialog.service';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { faExclamationTriangle, faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { deepMerge, withRestoreFilter } from '@app/_helpers';
import { faDownload, faMagnifyingGlass, faPencil, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { WorkItemStatusPipe } from '../../../../_shared-components/enum/work-item-status.pipe';
import { TaskTypePipe } from '../../../../_shared-components/enum/task-type.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MatSuffix } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatRadioModule } from '@angular/material/radio';
import { Traces2faMethodPipe } from '@app/_shared-components/enum/traces-2fa-method.pipe';
import { NgLetModule } from 'ng-let';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    selector: 'app-traces-docom-batch',
    templateUrl: './traces-docom-batch.component.html',
    styleUrls: ['./traces-docom-batch.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgLetModule, MatIconButton, MatSuffix, MatCheckboxModule, MatSortModule,
        MatRadioModule, RouterLink, Traces2faMethodPipe, MatPaginatorModule, MatMenuModule, TaskTypePipe, WorkItemStatusPipe, MatButtonModule, FontAwesomeModule]
})
export class TracesDocomBatchComponent extends BaseBatchEditor implements OnInit, AfterViewInit {
    destroyRef = inject(DestroyRef)
    form: FormGroup;


    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
    public methods2FA: Array<Traces2FAMethod> = Object.values(Traces2FAMethod).filter(m => m != Traces2FAMethod.Unknown);
    private batch!: TracesDocomBatch;
    batch$!: Observable<TracesDocomBatch | null>;

    filter!: WorkItemFilter;
    pageSizes: Array<number> = [10, 25, 50, 100];
    totalRecordCount: number = 0;

    storageKey: string = 'tracesDocomBatchFilter';

    faExclamationTriangle = faExclamationTriangle;
    faDiamondExclamation = faDiamondExclamation;
    faMagnifyingGlass = faMagnifyingGlass;
    faDownload = faDownload;
    faPencil = faPencil;
    faPlus = faPlus;
    workItemStatus = WorkItemStatus;
    traces2FAMethod = Traces2FAMethod;

    constructor(
        private spinnerService: LoadingSpinnerService,
        private service: TracesDocomService,
        protected workService: WorkService,
        public dialog: MatDialog,
        private confirmService: ConfirmDialogService,
        private signalRService: SignalRService,
        private alertService: AlertService,
        private fb: FormBuilder
    ) {
        super(workService);
        this.form = this.fb.group({
            credentials: this.fb.group({
                userName: [''],
                password: ['']
            }),
            method2FA: [Traces2FAMethod.AuthenticationAppPincode],
            deviceName: [''],
            testMode: [false]
        });
        this.form.disable();
    }

    get userNameControl() {
        return this.form.get('credentials.userName') as FormControl;
    }
    get passwordControl() {
        return this.form.get('credentials.password') as FormControl;
    }
    get method2FAControl() {
        return this.form.get('method2FA') as FormControl<Traces2FAMethod>;
    }
    get deviceNameControl() {
        return this.form.get('deviceName') as FormControl;
    }
    get testModeControl() {
        return this.form.get('testMode') as FormControl<boolean>;
    }

    ngOnInit(): void {
        this.filter = withRestoreFilter(WorkItemFilter, this.storageKey).restoreFilter();
        this.filter.batchId = this.batchId!;
        this.loadBatch();

        this.signalRService.workItemStatusChanged.subscribe(key => {
            if (key?.workBatchId && this.batch?.workBatchId === key.workBatchId) this.loadBatch();
        })

        this.form.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(formValues =>
                this.spinnerService.showLoader(this.service.updateBatch(new BatchUpdateRequestOfTracesDocomBatchAndTracesDocomBatchContentAndTracesDocomItemContent({ batch: this.prepareUpdate(formValues), filter: this.filter })))
                    .pipe(
                        tap(batch => {
                            this.batch = batch;
                        })
                    )
            )
        ).subscribe();
    }

    ngAfterViewInit(): void {
        const search$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            takeUntilDestroyed(this.destroyRef),
            map((event: any) => event.target.value),
            filter(res => res.length >= 1 || res.length === 0),
            debounceTime(300),
            distinctUntilChanged(),
            tap((searchTerm: string) => {
                this.filter.searchTerm = searchTerm;
                this.filter.pageNumber = null;
                this.paginator.pageIndex = 0;
            })
        );

        const sort$ = this.sort.sortChange.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );

        const page$ = this.paginator.page.pipe(
            takeUntilDestroyed(this.destroyRef),
            tap((event: PageEvent) => {
                this.filter.pageNumber = event.pageIndex;
                this.filter.pageSize = event.pageSize;
            })
        );

        merge(search$, sort$, page$).pipe(
            takeUntilDestroyed(this.destroyRef),
            tap(() => {
                this.filter.storeFilter(this.storageKey);
                this.loadBatch();
            })
        ).subscribe();
    }

    public get canEditBatch(): boolean {
        if (!this.batch) return false;
        return this.batchEditableStatusses.findIndex(s => s === this.batch!.status) >= 0;
    }

    loadBatch() {
        this.batch$ = this.spinnerService.showLoader(this.service.getWorkBatch(this.filter))
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                shareReplay({ bufferSize: 1, refCount: true }),
                tap(batch => {
                    this.patchForm(batch);
                })
            );
    }

    private patchForm(batch: TracesDocomBatch | null | undefined): void {
        if (batch?.content == null) {
            return;
        }
        this.form.patchValue(batch.content, { emitEvent: false });
        this.batch = batch;
    }

    private prepareUpdate(formValues: any): TracesDocomBatch {
        this.batch.content = deepMerge(this.batch.content, formValues);

        return this.batch;
    }


    enableBatchEdit(): void {
        this.form.enable({ emitEvent: false });
    }

    addWorkItem(id: number | null): void {
        //this.openDialog(new WorkItemOfTracesDocomItemContent({ batch: new GenericWorkBatch(this.batch), status: WorkItemStatus.Undefined, taskType: TaskType.Undefined, canEdit: true }));
    }


    confirmRemove(workItem: WorkItemOfTracesDocomItemContent): void {
        this.confirmService.showDialog(new ConfirmDialogData({
            title: $localize`:Traces Docom confirm delete work item dialog title@@traces_docom_confirm_delete_dialog_title:Delete work item`,
            message: $localize`:Traces Docom confirm delete work item dialog content@@traces_docom_confirm_delete_dialog_content:Are you sure to delete work item with reference <strong>${workItem.customerReference ? workItem.customerReference : workItem.documentReference}</strong>? <br />This action is irreversibel.`,
            returnData: workItem
        })).subscribe(workItem => {
            if (workItem?.workItemId) {
                this.workService.deleteWorkItem(workItem.workItemId).subscribe(result => {
                    if (result) {
                        this.loadBatch();
                    }
                    else {
                        this.alertService.addMessage($localize`:Traces Docom could not delete work item:@@traces_docom_could_not_delete_work_item:Couldn't delete work item ${workItem.customerReference}.`, AlertType.Warning);
                    }
                }, error => {
                    this.alertService.addErrorMessage(error);
                });
            }
        })
    }

    hasErrors(batch: WorkItemOfTracesDocomItemContent): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Error || s.logLevel == WorkItemLogLevel.Critical) ?? false;
    }
    hasWarnings(batch: WorkItemOfTracesDocomItemContent): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Warning) ?? false;
    }
}
