import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingSpinnerService, WorkItemLog, WorkService, API_BASE_URL, WorkLogFilter, WorkItemLogLevel, ExceptionHandlerService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { Observable, Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { ColumnFilterComponent, ColumnFilterItem, ColumnFilterModalData } from '@app/_shared-components/column-filter/column-filter.component';
import { WorkItemLogLevelPipe } from '@app/_shared-components/enum';
import { FilterHeaderComponent, FilterItem } from '@app/_shared-components/filter-header/filter-header.component';
import { withRestoreFilter } from '@app/_helpers';
import { WorkItemLogLevelPipe as WorkItemLogLevelPipe_1 } from '../../_shared-components/enum/work-item-log-level.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { FilterHeaderComponent as FilterHeaderComponent_1 } from '../../_shared-components/filter-header/filter-header.component';


@Component({
    selector: 'app-work-item-log',
    templateUrl: './work-item-log.component.html',
    styleUrls: ['./work-item-log.component.scss'],
    standalone: true,
    imports: [MatSort, MatSortHeader, FilterHeaderComponent_1, NgIf, NgFor, MatMiniFabButton, MatIcon, MatPaginator, AsyncPipe, DatePipe, WorkItemLogLevelPipe_1]
})
export class WorkItemLogComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() public workItemId!: number;
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('filterHeaderCategory', { static: true }) filterHeaderTaskType!: FilterHeaderComponent<WorkItemLogLevel>;


    public logLevel = WorkItemLogLevel;
    public logs!: WorkItemLog[];
    filter: WorkLogFilter = new WorkLogFilter();

    pageSizes: Array<number> = [25, 50, 100, 200];
    totalRecordCount!: number;



    categories$ = new Subject<FilterItem<WorkItemLogLevel>[]>();
    list$: Observable<WorkItemLog[] | null | undefined> | null = null;


    private readonly storageKey = "WorkItemLogComponent.filter";

    constructor(
        private workService: WorkService,
        private spinnerService: LoadingSpinnerService,
        @Inject(API_BASE_URL) public baseUrl: string,
        private dialog: MatDialog,
        private exceptionHandlerService: ExceptionHandlerService
    ) { }

    ngAfterViewInit(): void {
        let sort$ = this.sort.sortChange.pipe(
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );
        let page$ = this.paginator.page.pipe(
            tap((event: PageEvent) => {
                this.filter.pageSize = event.pageSize;
                this.filter.pageNumber = event.pageIndex;
            })
        );
        let categoryFilter$ = this.filterHeaderTaskType.selectedFilterItems$.pipe(
            tap((logLevels) => {
                this.filter.logLevels = logLevels;
            })
        );
        this.list$ = merge(sort$, page$, categoryFilter$).pipe(
            startWith(null),
            tap(() => {
                this.filter.storeFilter(this.storageKey);
            }),
            switchMap(() => this.getListObservable()),
            shareReplay(1)
        );

        this.categories$.next(Object.values(WorkItemLogLevel).filter(l => l != WorkItemLogLevel.None).map(l => {
            return {
                value: l,
                label: new WorkItemLogLevelPipe(this.exceptionHandlerService).transform(l),
                selected: !!this.filter.logLevels && this.filter.logLevels?.includes(l),
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workItemId.currentValue) {
            if (this.filter != null) {
                this.filter.workItemId = this.workItemId;
                this.list$ = this.getListObservable();
            }
        }
    }

    ngOnInit(): void {
        this.filter = withRestoreFilter(WorkLogFilter, this.storageKey).restoreFilter();
        this.filter.workItemId = this.workItemId;
    }

    private getListObservable(): Observable<WorkItemLog[] | null | undefined> {
        if (this.filter.workItemId == null) return new Observable<WorkItemLog[] | null | undefined>();
        return this.spinnerService.showLoader(
            this.workService.getWorkLog(this.filter).pipe(
                tap((result) => {
                    if (result.rowCount != null) this.totalRecordCount = result.rowCount;
                }),
                map((result) => result.resultSet)
            )
        );
    }

    openDialog(workItemLog: WorkItemLog) {
        this.dialog.open(WorkItemLogScreenshotDialog, {
            data: workItemLog
        });
    }


}


@Component({
    selector: 'work-item-log-screenshot-dialog',
    template: `
<div mat-dialog-content>
    <img [src]="baseUrl + '/ngApi/Work/ScreenShot?id=' + (data.screenShotId ? data.screenShotId : '') + '&screenShotVerify=' + (data.screenShotVerify)"/>
    <a (click)="showPageSource()">*</a>
</div>

  `,
})
export class WorkItemLogScreenshotDialog {
    constructor(
        public dialogRef: MatDialogRef<WorkItemLogScreenshotDialog>,
        @Inject(MAT_DIALOG_DATA) public data: WorkItemLog,
        @Inject(API_BASE_URL) public baseUrl: string,
        private workService: WorkService,
    ) { }

    showPageSource(): void {
        this.workService.pageSource(this.data.screenShotId, this.data.screenShotVerify).subscribe({
            next: result => {
                result = result.replace('onerror=\"this.src=', '');
                var win = window.open('', '_blank');
                win!.document.open();
                win!.document.write(result);
                win!.document.write('<script>setTimeout(function(){window.stop(); throw new Error(""); debugger;}, 1000);</script>');
                win!.document.close();
            },
        });
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}

