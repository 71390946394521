import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, UserRole } from '@app/_services';

@Pipe({
    name: 'userRole',
    standalone: true,
})
export class UserRolePipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: UserRole): string {
        switch (value) {
            case UserRole.None:
                return $localize`:User role None:`;
            case UserRole.Admin:
                return $localize`:User role Admin:Administrator`;
            case UserRole.UserAdmin:
                return $localize`:User role UserAdmin:User Administrator`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `UserRole enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
