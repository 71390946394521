import { Component, OnInit } from '@angular/core';
import { TracesDocomBaseItem } from '../traces-docom-base-item';
import { LoadingSpinnerService, TracesDocomService, TracesDocomCreate, WorkItemOfTracesDocomCreate, AlertService, WorkItemOfTracesDocomCancel, WorkItemOfTracesDocomDownload } from '@app/_services';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { TracesDocomItemHeaderComponent } from '../traces-docom-item-header/traces-docom-item-header.component';

@Component({
    selector: 'app-traces-docom-create',
    templateUrl: './traces-docom-create.component.html',
    styleUrls: ['./traces-docom-create.component.scss'],
    standalone: true,
    imports: [TracesDocomItemHeaderComponent, NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker]
})
export class TracesDocomCreateComponent extends TracesDocomBaseItem implements OnInit {
    workItem: WorkItemOfTracesDocomCreate | undefined;
    constructor(private service: TracesDocomService, private spinnerService: LoadingSpinnerService, private alertService: AlertService) {
        super();
    }

    ngOnInit(): void {
        if (this.workItemId) {
            this.fetchCreateWorkItem();
        }
    }

    private fetchCreateWorkItem(): void {
        this.spinnerService.showLoader(this.service.getCreate(this.workItemId)).subscribe(result => {
            this.workItem = result;
        })
    }

    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateCreate(this.workItem!)).subscribe(result => {
            this.workItem = result;
            this.workItemUpdated.emit(this.workItem);
        })
    }
}
