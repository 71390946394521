<app-traces-docom-item-header [workItem]="workItem" (changed)="updateWorkItem()"></app-traces-docom-item-header>
<ng-container *ngIf="workItem?.content">
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM previous document reference">Previous document reference</mat-label>
                <input matInput i18n-placeholder="DOCOM previous document reference placeholder" placeholder="previous document reference" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.previousDocumentReference" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM local reference">Local Reference</mat-label>
                <input matInput i18n-placeholder="Docom local reference placeholder" placeholder="local reference" [(ngModel)]="workItem!.content!.localReference" [disabled]="!workItem!.canEdit" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM fertilizer classification">Fertilizer classification</mat-label>
                <input matInput i18n-placeholder="DOCOM fertilizer classification code placeholder" placeholder="fertilizer classification" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.fertilizerClassification.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM FertilizerClassification TRACES">Fertilizer classification in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM FertilizerClassification TRACES placeholder" placeholder="fertilizer classification TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.fertilizerClassification.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM product temperature">Product temperature</mat-label>
                <input matInput i18n-placeholder="DOCOM product temperature placeholder" placeholder="product temperature" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.productTemperature.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM product temperature TRACES">Product temperature in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM product temperature TRACES placeholder=" placeholder="product temperature TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.productTemperature.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM product certified as">Product certified as</mat-label>
                <input matInput i18n-placeholder="DOCOM product certified as placeholder" placeholder="product certified as" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.productCertifiedAs.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM product certified as TRACES">Product certified as in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM product certified as TRACES placeholder=" placeholder="product certified as TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.productCertifiedAs.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM nomenclature code">Nomenclature code</mat-label>
                <input matInput i18n-placeholder="DOCOM nomenclature code placeholder" placeholder="nomenclature code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.nomenclatureCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM nomenclature code TRACES">Nomenclature code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM nomenclature code TRACES placeholder=" placeholder="nomenclature code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.nomenclatureCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM goods nature">Goods nature</mat-label>
                <input matInput i18n-placeholder="DOCOM goods nature placeholder" placeholder="goods nature" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.goodsNature.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM goods nature TRACES">Goods nature in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM goods nature TRACES placeholder=" placeholder="goods nature TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.goodsNature.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM treatment type">Treatment type</mat-label>
                <input matInput i18n-placeholder="DOCOM treatment type placeholder" placeholder="treatment type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.treatmentType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM treatment type TRACES">Treatment type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM treatment type TRACES placeholder=" placeholder="treatment type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.treatmentType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM animal species">Animal species</mat-label>
                <input matInput i18n-placeholder="DOCOM animal species placeholder" placeholder="animal species" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.animalSpecies.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM category TRACES">Animal species in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM animal species TRACES placeholder=" placeholder="animal species TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.animalSpecies.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM lot number">Lot number</mat-label>
                <input matInput i18n-placeholder="lot number placeholder" placeholder="lot number" [(ngModel)]="workItem!.content!.lotNumber" [disabled]="!workItem!.canEdit" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM colli count">Colli count</mat-label>
                <input matInput i18n-placeholder="colli count placeholder" placeholder="colli count" type="number" [(ngModel)]="workItem!.content!.colliCount" [disabled]="!workItem!.canEdit" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM colli type">Colli type</mat-label>
                <input matInput i18n-placeholder="DOCOM colli type placeholder" placeholder="colli type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.colliType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM colli type TRACES">Colli type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM colli type TRACES placeholder=" placeholder="colli type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.colliType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender">Sender</mat-label>
                <input matInput i18n-placeholder="DOCOM sender placeholder" placeholder="sender" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.sender.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender TRACES">Sender in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM sender TRACES placeholder=" placeholder="sender TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.sender.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender type">Sender type</mat-label>
                <input matInput i18n-placeholder="DOCOM sender type placeholder" placeholder="sender type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.senderType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender type TRACES">Sender type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM sender type TRACES placeholder=" placeholder="sender type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.senderType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender country code">Sender country code</mat-label>
                <input matInput i18n-placeholder="DOCOM sender country code placeholder" placeholder="sender country code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.senderCountryCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM sender country code TRACES">Sender country code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM sender country code TRACES placeholder=" placeholder="sender country code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.senderCountryCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver">Receiver</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver placeholder" placeholder="receiver" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiver.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver TRACES">Receiver in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver TRACES placeholder=" placeholder="receiver TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiver.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver type">Receiver type</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver type placeholder" placeholder="receiver type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiverType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver type TRACES">Receiver type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver type TRACES placeholder=" placeholder="receiver type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiverType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver country code">Receiver country code</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver country code placeholder" placeholder="receiver country code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiverCountryCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM receiver country code TRACES">Receiver country code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM receiver country code TRACES placeholder=" placeholder="receiver country code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.receiverCountryCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader">Trader</mat-label>
                <input matInput i18n-placeholder="DOCOM trader placeholder" placeholder="trader" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.trader.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader TRACES">Trader in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM trader TRACES placeholder=" placeholder="trader TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.trader.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader type">Trader type</mat-label>
                <input matInput i18n-placeholder="DOCOM trader type placeholder" placeholder="trader type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.traderType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader type TRACES">Trader type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM trader type TRACES placeholder=" placeholder="trader type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.traderType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader country code">Trader country code</mat-label>
                <input matInput i18n-placeholder="DOCOM trader country code placeholder" placeholder="trader country code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.traderCountryCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM trader country code TRACES">Trader country code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM trader country code TRACES placeholder=" placeholder="trader country code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.traderCountryCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company">Transport company</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company" placeholder="transport company" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompany.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company TRACES">Transport company in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company TRACES placeholder=" placeholder="transport company TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompany.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company type">Transport company type</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company type" placeholder="transport company type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompanyType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company type TRACES">Transport company type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company type TRACES placeholder=" placeholder="transport company type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompanyType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company country code">Transport company country code</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company country code" placeholder="transport company country code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompanyCountryCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM transport company country code TRACES">Transport company country code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM transport company country code TRACES placeholder=" placeholder="transport company country code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.transportCompanyCountryCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company">Processing company</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company placeholder" placeholder="processing company" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompany.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company TRACES">Processing company in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company TRACES placeholder=" placeholder="processing company TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompany.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company type">Processing company type</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company type placeholder" placeholder="processing company type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompanyType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company type TRACES">Processing company type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company type TRACES placeholder=" placeholder="processing company type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompanyType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company country code">Processing company country code</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company country code placeholder" placeholder="processing company country code" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompanyCountryCode.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM processing company country code TRACES">Processing company country code in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM processing company country code TRACES placeholder=" placeholder="processing company country code TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.processingCompanyCountryCode.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM actual freights">Actual freights</mat-label>
                <input matInput i18n-placeholder="colli actual freights" placeholder="actual freights" type="number" [(ngModel)]="workItem!.content!.actualFreights" [disabled]="!workItem!.canEdit" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM actual load">Actual load</mat-label>
                <input matInput i18n-placeholder="colli actual load" placeholder="actual load" type="number" [(ngModel)]="workItem!.content!.actualLoad" [disabled]="!workItem!.canEdit" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM stop date">Stop date</mat-label>
                <input matInput i18n-placeholder="DOCOM stop date placeholder" placeholder="stop date" [matDatepicker]="stopDatePicker" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.stopDate" (change)="updateWorkItem()">
                <mat-datepicker-toggle matSuffix [for]="stopDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #stopDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM resume date">Resume date</mat-label>
                <input matInput i18n-placeholder="DOCOM resume date placeholder" placeholder="resume date" [matDatepicker]="resumeDatePicker" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.resumeDate" (change)="updateWorkItem()">
                <mat-datepicker-toggle matSuffix [for]="resumeDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #resumeDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM goods type">Goods type</mat-label>
                <input matInput i18n-placeholder="DOCOM goods type placeholder" placeholder="goods type" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.goodsType.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM goods type TRACES">Goods type in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM goods type TRACES placeholder=" placeholder="goods type TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.goodsType.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM license plate">License plate</mat-label>
                <input matInput i18n-placeholder="DOCOM license plate placeholder" placeholder="license plate" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.licensePlate.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM license plate TRACES">License plate in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM license plate TRACES placeholder=" placeholder="license plate TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.licensePlate.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM license plate trailer">License plate trailer</mat-label>
                <input matInput i18n-placeholder="DOCOM license plate trailer placeholder" placeholder="license plate trailer" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.licensePlateTrailer.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM license plate trailer TRACES">License plate trailer in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM license plate trailer TRACES placeholder=" placeholder="license plate trailer TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.licensePlateTrailer.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company signature">Company signature</mat-label>
                <input matInput i18n-placeholder="DOCOM company signature placeholder" placeholder="company signature" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignature.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company signature TRACES">Company signature in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM company signature TRACES placeholder=" placeholder="company signature TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignature.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company signature place">Company signature place</mat-label>
                <input matInput i18n-placeholder="DOCOM company signature place placeholder" placeholder="company signature place" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignaturePlace.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company signature place TRACES">Company signature place in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM company signature place TRACES placeholder=" placeholder="company signature place TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignaturePlace.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company sign by">Company sign by</mat-label>
                <input matInput i18n-placeholder="DOCOM company sign by" placeholder="company sign by" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignBy.sendValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="DOCOM company sign by TRACES">Company sign by in TRACES</mat-label>
                <input matInput i18n-placeholder="DOCOM company sign by TRACES placeholder=" placeholder="company sign by TRACES" [disabled]="!workItem!.canEdit" [(ngModel)]="workItem!.content!.companySignBy.documentValue" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
</ng-container>