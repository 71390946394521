import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DateRange, MatDatepickerInputEvent, MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDateRangePicker } from '@angular/material/datepicker';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { LoadingSpinnerService, BatchListFilter, WorkService, GenericWorkBatch, WorkType, WorkBatchStatus, WorkItemLogLevel, ExceptionHandlerService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { add } from 'date-fns/add';
import { Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { faExclamationTriangle, faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { withRestoreFilter } from '@app/_helpers';
import { FilterHeaderComponent, FilterItem } from '@app/_shared-components/filter-header/filter-header.component';
import { WorkBatchStatusPipe, WorkTypePipe } from '@app/_shared-components/enum';
import { WorkBatchStatusPipe as WorkBatchStatusPipe_1 } from '../../_shared-components/enum/work-batch-status.pipe';
import { WorkTypePipe as WorkTypePipe_1 } from '../../_shared-components/enum/work-type.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { FilterHeaderComponent as FilterHeaderComponent_1 } from '../../_shared-components/filter-header/filter-header.component';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';


@Component({
    selector: 'app-batch-list',
    templateUrl: './batch-list.component.html',
    styleUrls: ['./batch-list.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatSuffix, MatDateRangePicker, MatInput, MatIcon, MatSort, MatSortHeader, FilterHeaderComponent_1, NgIf, NgFor, RouterLink, NgClass, FaIconComponent, MatPaginator, WorkTypePipe_1, WorkBatchStatusPipe_1]
})
export class BatchListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
    @ViewChild('filterHeaderWorkTypes', { static: true }) filterHeaderWorkTypes!: FilterHeaderComponent<WorkType>;
    @ViewChild('filterHeaderStatusses', { static: true }) filterHeaderBatchStatusses!: FilterHeaderComponent<WorkBatchStatus>;


    faExclamationTriangle = faExclamationTriangle;
    faDiamondExclamation = faDiamondExclamation;

    filter!: BatchListFilter;
    pageSizes: Array<number> = [10, 25, 50, 100];
    totalRecordCount!: number;
    batches!: GenericWorkBatch[];
    workBatchStatus = WorkBatchStatus;
    workTypes$ = new Subject<FilterItem<WorkType>[]>();
    statusses$ = new Subject<FilterItem<WorkBatchStatus>[]>();
    storageKey = 'batchListFilter';

    constructor(
        private alertService: AlertService,
        private spinnerService: LoadingSpinnerService,
        private workService: WorkService,
        private exceptionHandlerService: ExceptionHandlerService) { }

    dateFilter = (d: Date | null): boolean => {
        return (d ?? new Date(0)) >= add(new Date(), { months: -3 });
    };

    ngAfterViewInit(): void {
        const search$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            map((event: any) => event.target.value),
            filter(res => res.length >= 1 || res.length === 0),
            debounceTime(300),
            distinctUntilChanged(),
            tap((searchTerm: string) => {
                this.filter.searchTerm = searchTerm;
                this.filter.pageNumber = null;
                this.paginator.pageIndex = 0;
            })
        );

        const sort$ = this.sort.sortChange.pipe(
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );

        const page$ = this.paginator.page.pipe(
            tap((event: PageEvent) => {
                this.filter.pageNumber = event.pageIndex;
                this.filter.pageSize = event.pageSize;
            })
        );

        const workTypesFilter$ = this.filterHeaderWorkTypes.selectedFilterItems$.pipe(
            tap((result) => {
                this.filter.workTypes = result;
            })
        );

        const statussesFilter$ = this.filterHeaderBatchStatusses.selectedFilterItems$.pipe(
            tap((result) => {
                this.filter.workBatchStates = result;
            })
        );

        merge(search$, sort$, page$, workTypesFilter$, statussesFilter$).pipe(
            tap(() => {
                this.filter.storeFilter(this.storageKey);
                this.loadBatches();
            })
        ).subscribe();

        this.workTypes$.next(Object.values(WorkType).filter(t => t != WorkType.Undefined).map(t => {
            return {
                value: t,
                label: new WorkTypePipe(this.exceptionHandlerService).transform(t),
                selected: !!this.filter.workTypes && this.filter.workTypes!.findIndex(x => x == t) >= 0
            }
        }));

        this.statusses$.next(Object.values(WorkBatchStatus).filter(s => s != WorkBatchStatus.Undefined).map(s => {
            return {
                value: s,
                label: new WorkBatchStatusPipe(this.exceptionHandlerService).transform(s),
                selected: !!this.filter.workBatchStates && this.filter.workBatchStates!.findIndex(x => x == s) >= 0
            }
        }));
    }

    ngOnInit(): void {
        this.filter = withRestoreFilter(BatchListFilter, this.storageKey).restoreFilter();
        this.filter.startDate = add(new Date(), { weeks: -1 });
        this.filter.endDate = new Date();
        this.searchInput.nativeElement.value = this.filter.searchTerm ?? '';
        this.loadBatches();
    }

    loadBatches(resetPager: boolean = false): void {
        if (resetPager) this.filter.pageNumber = 0;
        this.spinnerService.showLoader(this.workService.getGenericBatches(this.filter)).subscribe({
            next: result => {
                if (result?.resultSet) {
                    if (result.rowCount != null) this.totalRecordCount = result.rowCount;
                    this.batches = result.resultSet;
                }
                else {
                    this.totalRecordCount = 0
                    this.batches = [];
                }
            },
            error: error => {
                this.alertService.addErrorMessage(error);
            }
        });
    }




    startPeriodChanged(e: MatDatepickerInputEvent<Date | undefined, DateRange<Date | undefined>>): void {
        this.filter.startDate = e.value;
        this.filter.storeFilter(this.storageKey);
        this.loadBatches();
    }

    endPeriodChanged(e: MatDatepickerInputEvent<Date | undefined, DateRange<Date | undefined>>): void {
        this.filter.endDate = e.value;
        this.filter.storeFilter(this.storageKey);
        this.loadBatches();
    }

    hasErrors(batch: GenericWorkBatch): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Error || s.logLevel == WorkItemLogLevel.Critical) ?? false;
    }
    hasWarnings(batch: GenericWorkBatch): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Warning) ?? false;
    }
}
