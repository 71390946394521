import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { VlmMadBatch, VlmMadService, WorkItemOfVlmMadItemContent, AuthenticationService, WorkItemStatus, TaskType, WorkService, SignalRService, WorkItemFilter, GenericWorkBatch, BatchUpdateRequestOfVlmMadBatchAndVlmMadBatchContentAndVlmMadItemContent, VlmLoginMethod, VlmWebBotSession, VlmService, WorkItemLogLevel, Credentials, VlmBatchContent } from '@app/_services'
import { Router, RouterLink } from '@angular/router';
import { BaseBatchEditor } from '@app/editors/base-batch-editor-component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { LoadingSpinnerService } from '@app/_services/loading-spinner.service';
import { ConfirmDialogService } from '@app/_services/confirm-dialog.service';
import { ConfirmDialogData } from '@app/components/confirm-dialog/confirm-dialog.component';
import { AlertService } from '@app/_services/alert.service';
import { WorkItemLogComponent } from '@app/components/work-item-log/work-item-log.component';
import { VlmMadResultComponent } from '../vlm-mad-result/vlm-mad-result.component';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, tap } from 'rxjs/operators';
import { StorageService } from '@app/_services/storage.service';
import { DialogVlmLoginComponent, VlmSessionDialogData } from '../../dialog-vlm-login/dialog-vlm-login.component';
import { faExclamationTriangle, faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';
import { withRestoreFilter } from '@app/_helpers';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { faDownload, faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { WorkItemStatusPipe } from '../../../../_shared-components/enum/work-item-status.pipe';
import { VlmLoginMethodPipe } from '../../../../_shared-components/enum/vlm-login-method.pipe';
import { TaskTypePipe } from '../../../../_shared-components/enum/task-type.pipe';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatLabel, MatSuffix, MatFormField } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass } from '@angular/common';


@Component({
    selector: 'app-vlm-mad-batch',
    templateUrl: './vlm-mad-batch.component.html',
    styleUrls: ['./vlm-mad-batch.component.scss'],
    standalone: true,
    imports: [NgIf, MatLabel, MatRadioGroup, FormsModule, NgFor, MatRadioButton, MatIconButton, MatSuffix, MatIcon, MatFormField, MatInput, FaIconComponent,
        MatSort, MatSortHeader, RouterLink, NgClass, MatMenuTrigger, MatPaginator, MatMenu, MatMenuContent, MatMenuItem, TaskTypePipe,
        VlmLoginMethodPipe, WorkItemStatusPipe, FontAwesomeModule, MatButtonModule]
})
export class VlmMadBatchComponent extends BaseBatchEditor implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

    faExclamationTriangle = faExclamationTriangle;
    faDiamondExclamation = faDiamondExclamation;
    faMagnifyingGlass = faMagnifyingGlass;
    faDownload = faDownload;

    public loginForm!: FormGroup;
    public batch: VlmMadBatch | undefined;
    public tokenFormControls: Array<FormControl> = [];
    public loginMethods: Array<VlmLoginMethod> = Object.values(VlmLoginMethod).filter(m => m != VlmLoginMethod.Unknown);
    public editBatchContent: boolean = false;

    filter!: WorkItemFilter;
    pageSizes: Array<number> = [10, 25, 50, 100];
    totalRecordCount!: number;
    workItems!: WorkItemOfVlmMadItemContent[];

    workItemStatus = WorkItemStatus;
    vlmLoginMethod = VlmLoginMethod;
    sessionDialogRef!: MatDialogRef<DialogVlmLoginComponent>;
    storageKey: string = "vlmMadBatchFilter";

    constructor(
        private spinnerService: LoadingSpinnerService,
        private service: VlmMadService,
        protected workService: WorkService,
        private vlmService: VlmService,
        private router: Router,
        public dialog: MatDialog,
        private confirmService: ConfirmDialogService,
        private signalRService: SignalRService,
        private alertService: AlertService) {
        super(workService);
    }
    ngAfterViewInit(): void {
        const search$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            map((event: any) => event.target.value),
            filter(res => res.length >= 1 || res.length === 0),
            debounceTime(300),
            distinctUntilChanged(),
            tap((searchTerm: string) => {
                this.filter.pageNumber = 0;
                this.filter.searchTerm = searchTerm;
            })
        );

        let sort$ = this.sort.sortChange.pipe(
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );
        let page$ = this.paginator.page.pipe(
            tap((event: PageEvent) => {
                this.filter.pageSize = event.pageSize;
                this.filter.pageNumber = event.pageIndex;
            })
        );

        merge(search$, sort$, page$).pipe(
            tap(() => {
                this.filter.storeFilter(this.storageKey);
                this.loadBatch();
            })
        ).subscribe();
    }

    enableBatchContentEdit(): void {
        this.editBatchContent = true;
    }

    ngOnInit(): void {
        for (let index = 0; index < 25; index++) {
            this.tokenFormControls.push(new FormControl(''))
        }

        this.filter = withRestoreFilter(WorkItemFilter, this.storageKey).restoreFilter();
        this.filter.batchId = this.batchId!;
        this.searchInput.nativeElement.value = this.filter.searchTerm ?? '';
        if (this.filter.pageSize == null || this.filter.pageSize <= 0) this.filter.pageSize = this.pageSizes[0];
        this.loadBatch();
        this.signalRService.workItemStatusChanged.subscribe(key => {
            if (key?.workBatchId && this.batch?.workBatchId === key.workBatchId) this.loadBatch();
        })
        this.signalRService.sessionStatusChanged.subscribe(batchId => {
            if (batchId === this.batch?.workBatchId) {
                if (!this.sessionDialogRef || this.sessionDialogRef.getState() !== MatDialogState.OPEN) {
                    this.openSessionDialog();
                }
            }
        });
        this.vlmService.getSession(this.batchId).subscribe({
            next: result => {
                if (result) this.openSessionDialog();
            }
        })
    }

    public get canEditBatch(): boolean {
        if (!this.batch) return false;
        return this.batchEditableStatusses.findIndex(s => s === this.batch!.status) >= 0;
    }

    loadBatch() {
        this.spinnerService.showLoader(this.service.getWorkBatch(this.filter)).subscribe({
            next: result => {
                this.setBatch(result);
            },
            error: (error) => {
                this.batch = undefined;
                this.alertService.addErrorMessage(error);
            }
        });
    }

    private setBatch(batch: VlmMadBatch): void {
        this.batch = batch;
        this.workItems = batch.workItems!;
        if (batch.workItemCount) this.totalRecordCount = batch.workItemCount
        if (this.batch.content == null) this.batch.content = new VlmBatchContent({ loginMethod: VlmLoginMethod.Unknown, credentials: new Credentials(), testMode: true });
        if (this.batch.content?.credentials == null) this.batch.content.credentials = new Credentials
        this.batchStatusUpdated.emit(batch.status);
    }

    private openSessionDialog(): void {
        if (!this.sessionDialogRef) {
            this.sessionDialogRef = this.dialog.open(DialogVlmLoginComponent, { minWidth: "400px", minHeight: "400px", disableClose: true, data: <VlmSessionDialogData><unknown>{ batchId: this.batchId, session: null } });
            this.sessionDialogRef.afterClosed().subscribe(result => {
                const session = <VlmWebBotSession>result;
                if (session?.loginSuccess !== true && session?.loginMessage) {
                    this.alertService.addMessage(session.loginMessage, AlertType.Error, $localize`:MAD login error|Login failure title@@mad_login_failure_title:Login failure`, true, 0);
                }
                if (session?.loginSuccess === true) {
                    this.alertService.addMessage($localize`:MAD login success@@mad_login_success:Successfully logged into VLM`, AlertType.Success, undefined, true, 8);
                }
            });
        }
    }

    updateBatch(): void {
        this.spinnerService.showLoader(this.service.updateBatch(new BatchUpdateRequestOfVlmMadBatchAndVlmMadBatchContentAndVlmMadItemContent({ batch: this.batch!, filter: this.filter }))).subscribe({
            next: result => {
                this.setBatch(result);
                this.batchId = result.workBatchId!;
                this.router.navigate([], {
                    queryParams: { batchId: this.batchId },
                    queryParamsHandling: "merge",
                    preserveFragment: true
                });
            },
            error: error => {
                this.alertService.addErrorMessage(error);
            }
        });
    }

    // statusChanged(status: WorkBatchStatus): void {
    //     this.batch.status = status;
    //     if (status == WorkBatchStatus.Queued) this.batch.userId = this.authenticationService.currentUserValue.id
    //     this.updateBatch();
    // }



    addWorkItem(): void {
        //this.openDialog(new WorkItemOfVlmMadItemContent({ batch: new GenericWorkBatch(this.batch), status: WorkItemStatus.Undefined, taskType: TaskType.Undefined, canEdit: true }));
    }

    showResult(workItem: WorkItemOfVlmMadItemContent) {
    }

    confirmRemove(workItem: WorkItemOfVlmMadItemContent): void {
        this.confirmService.showDialog(new ConfirmDialogData({
            title: $localize`:VLM MAD confirm delete work item dialog title@@vlm_mad_confirm_delete_dialog_title:Delete work item`,
            message: $localize`:VLM MAD confirm delete work item dialog content@@vlm_mad_confirm_delete_dialog_content:Are you sure to delete work item with reference <strong>${workItem.customerReference ? workItem.customerReference : workItem.documentReference}</strong>? <br />This action is irreversibel.`,
            returnData: workItem
        })).subscribe(workItem => {
            if (workItem?.workItemId) {
                this.workService.deleteWorkItem(workItem.workItemId).subscribe(result => {
                    if (result) {
                        this.loadBatch();
                    }
                    else {
                        this.alertService.addMessage($localize`:VLM MAD could not delete work item:@@vlm_mad_could_not_delete_work_item:Couldn't delete work item ${workItem.customerReference}.`, AlertType.Warning);
                    }
                }, error => {
                    this.alertService.addErrorMessage(error);
                });
            }
        })
    }

    hasErrors(batch: WorkItemOfVlmMadItemContent): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Error || s.logLevel == WorkItemLogLevel.Critical) ?? false;
    }
    hasWarnings(batch: WorkItemOfVlmMadItemContent): boolean {
        return batch.logSummary?.some(s => s.logLevel == WorkItemLogLevel.Warning) ?? false;
    }
}


