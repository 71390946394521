import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserRole } from '@app/_services';
import { environment } from '../../environments/environment';
import { faAddressCard, faUsers, faBars, faRightFromBracket, faBoxesStacked, faCircleUser, faGears } from '@fortawesome/pro-regular-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton, MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss'],
    standalone: true,
    imports: [MatToolbar, NgIf, MatIconButton, MatMenuTrigger, FaIconComponent, MatMenu, MatMenuItem, RouterLinkActive, RouterLink, MatButton]
})
export class NavMenuComponent implements OnInit {
    faAddressCard = faAddressCard;
    faUsers = faUsers;
    faBars = faBars;
    faRightFromBracket = faRightFromBracket;
    faBoxesStacked = faBoxesStacked;
    faCircleUser = faCircleUser;
    faGears = faGears;

    public toolbarColor: string = environment.toolbarColor;

    isSmallScreen = false;

    constructor(public auth: AuthenticationService, private dialog: MatDialog) {
    }

    get isUserAdmin(): boolean {
        return this.auth.isUserInRole(UserRole.Admin) || this.auth.isUserInRole(UserRole.UserAdmin);
    }

    get currentUser() {
        return this.auth.currentUserValue;
    }

    ngOnInit(): void {
        this.checkScreenSize();
        window.onresize = () => this.checkScreenSize();
    }

    checkScreenSize() {
        this.isSmallScreen = window.innerWidth < 768; // example breakpoint
    }


}
