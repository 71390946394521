<app-vlm-mad-item-header [workItem]="closeWorkItem" (changed)="updateWorkItem()"></app-vlm-mad-item-header>
<ng-container *ngIf="closeWorkItem?.content">
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="MAD traces id">Traces ID</mat-label>
                <input matInput i18n-placeholder="MAD traces id placeholder" [(ngModel)]="closeWorkItem!.content!.tracesId" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="MAD actual freights">Actual freights</mat-label>
                <input matInput i18n-placeholder="MAD actual freights placeholder" type="number" placeholder="actual freights" [(ngModel)]="closeWorkItem!.content!.actualFreights" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="MAD actual load">Actual load</mat-label>
                <input matInput i18n-placeholder="MAD actual load placeholder" type="number" [(ngModel)]="closeWorkItem!.content!.actualLoad" (change)="updateWorkItem()">
            </mat-form-field>
        </div>
    </div>
</ng-container>