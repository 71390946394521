import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertComponent } from '@app/_shared-components/alert/alert.component';
import { LoadingSpinnerService, VlmMadDownload, VlmMadService, WorkItemOfVlmMadDownload, WorkItemOfVlmMadItemContent } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { VlmMadBaseItem } from '../vlm-mad-base-item';
import { VlmMadItemHeaderComponent } from '../vlm-mad-item-header/vlm-mad-item-header.component';

@Component({
    selector: 'app-vlm-mad-download',
    templateUrl: './vlm-mad-download.component.html',
    styleUrls: ['./vlm-mad-download.component.scss'],
    standalone: true,
    imports: [VlmMadItemHeaderComponent]
})
export class VlmMadDownloadComponent extends VlmMadBaseItem implements OnInit {
    downloadWorkItem: WorkItemOfVlmMadDownload | undefined;

    constructor(
        private service: VlmMadService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.workItemId) {
            this.fetchCreateWorkItem();
        }
    }

    private fetchCreateWorkItem(): void {
        this.spinnerService.showLoader(this.service.getDownload(this.workItemId)).subscribe(result => {
            this.downloadWorkItem = result;
        })
    }

    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateDownload(this.downloadWorkItem!)).subscribe(result => {
            this.downloadWorkItem = result;
            this.workItemUpdated.emit(this.downloadWorkItem);
        })
    }
}
