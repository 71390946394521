<div class="loginHeader m-3" *ngIf="setting">
    <form [formGroup]="form">
        <div class="loginMethod">
            <mat-label i18n="VLM MAD settings login method">Login method</mat-label>
            <mat-radio-group formControlName="loginMethod">
                <mat-radio-button *ngFor="let method of loginMethods" class="py-1" [value]="method">
                    {{method | vlmLoginMethod}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="credentials" formGroupName="credentials">
            <mat-form-field>
                <mat-label i18n="VLM MAD settings username">username</mat-label>
                <input matInput i18n-placeholder="VLM MAD username placeholder" placeholder="username" formControlName="userName">
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n="VLM MAD settings password">password</mat-label>
                <input matInput i18n-placeholder="VLM MAD settings password placeholder" type="password" placeholder="password" formControlName="password">
            </mat-form-field>
        </div>
        <div class="loginDevice" *ngIf="loginMethodControl.value == vlmLoginMethod.AuthenticationApp">
            <mat-form-field>
                <mat-label i18n="VLM MAD settings authentication device">authentication device name</mat-label>
                <input matInput i18n-placeholder="VLM MAD authentication device placeholder" placeholder="device name" formControlName="deviceName">
            </mat-form-field>
        </div>
        <div class="autoStart" *ngIf="loginMethodControl.value == vlmLoginMethod.Foreigner && userNameControl.value && passwordControl.value">
            <mat-checkbox formControlName="autoStartBatch" i18n="VLM MAD settings auto start">Auto start</mat-checkbox>
            <div *ngIf="autoStartBatchControl.value" i18n="Autostart vlm mad batch after description">Begin automatic document processing {{autoStartBatchTimeout$ | async | formatTimeSpan }} following the receipt of the latest data.</div>
        </div>
        <div class="testMode">
            <mat-checkbox formControlName="testMode" i18n="VLM MAD settings testmode checkbox">Test mode</mat-checkbox>
        </div>
    </form>
</div>