import { Component, Input, OnInit } from '@angular/core';
import { GenericWorkBatch, WorkType } from '@app/_services';
import { TracesDocomBatchComponent } from '../../editors/traces/docom/traces-docom-batch/traces-docom-batch.component';
import { VlmMadBatchComponent } from '../../editors/vlm/mad/vlm-mad-batch/vlm-mad-batch.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-batch-content',
    templateUrl: './batch-content.component.html',
    styleUrls: ['./batch-content.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, VlmMadBatchComponent, TracesDocomBatchComponent]
})
export class BatchContentComponent implements OnInit {
    @Input() batch: GenericWorkBatch | undefined;
    workType = WorkType;

    constructor() { }

    ngOnInit(): void {
    }

}
