import { Component, OnInit } from '@angular/core';
import { AlertService, LoadingSpinnerService, VlmCompanySetting, VlmLoginMethod, WorkType, VlmMadCompanySetting, VlmMadService } from '@app/_services';
import { WorkTypePipe } from '../../_shared-components/enum/work-type.pipe';
import { VlmMadSettingComponent } from '../../editors/vlm/mad/vlm-mad-setting/vlm-mad-setting.component';
import { TracesDocomSettingComponent } from '../../editors/traces/docom/traces-docom-setting/traces-docom-setting.component';
import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatTabGroup, MatTab, MatTabLabel } from '@angular/material/tabs';

@Component({
    selector: 'app-company-setting',
    templateUrl: './company-setting.component.html',
    styleUrls: ['./company-setting.component.scss'],
    standalone: true,
    imports: [MatTabGroup, NgFor, MatTab, MatTabLabel, NgSwitch, NgSwitchCase, TracesDocomSettingComponent, VlmMadSettingComponent, WorkTypePipe]
})
export class CompanySettingComponent implements OnInit {

    public workTypes: Array<WorkType> = Object.values(WorkType).filter(t => t != WorkType.Undefined);
    public workType = WorkType;

    public vlmMadSettings: VlmMadCompanySetting | undefined;

    constructor(private serviceVlmMad: VlmMadService, private spinnerService: LoadingSpinnerService, private alertService: AlertService) { }

    ngOnInit(): void {

    }



}
