import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, VlmLoginMethod } from '@app/_services';

@Pipe({
    name: 'vlmLoginMethod',
    standalone: true,
})
export class VlmLoginMethodPipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: VlmLoginMethod | undefined | null): string {
        if (value == null) {
            return $localize`:VlmLoginMethod undefined:`;
        }
        switch (value) {
            case VlmLoginMethod.Unknown:
                return $localize`:VlmLoginMethod unknown:`;
            case VlmLoginMethod.Foreigner:
                return $localize`:VlmLoginMethod foreigner:As foreigner`;
            case VlmLoginMethod.AuthenticationApp:
                return $localize`:VlmLoginMethod authentication app:Via authentication app`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `VlmLoginMethod enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
