import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AlertService } from '@app/_services/alert.service';
import { Subscription } from 'rxjs';
import { faTriangleExclamation, faDiamondExclamation, faCircleInfo, faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgFor, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    animations: [
        trigger('items', [
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),
                animate('700ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })) // final
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('700ms cubic-bezier(.8, -0.6, 0.2, 1.5)', style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [NgFor, NgClass, NgIf, FaIconComponent, NgTemplateOutlet]
})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() alerts: Array<AlertMessage> = []
    alertType = AlertType;

    alertSubscription: Subscription | undefined;
    routeSubscription: Subscription | undefined;

    faTriangleExclamation = faTriangleExclamation;
    faDiamondExclamation = faDiamondExclamation;
    faCircleInfo = faCircleInfo;
    faCircleCheck = faCircleCheck;

    constructor(private router: Router, private alertService: AlertService) { }


    ngOnDestroy(): void {
        this.alertSubscription?.unsubscribe();
        this.routeSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService.onAlert()
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert?.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

                    // remove 'keepAfterRouteChange' flag on the rest
                    this.alerts.forEach(x => delete x.keepAfterRouteChange);
                    return;
                }

                // add alert to array
                this.alerts.push(alert);

                // auto close alert if required
                if (alert.autoCloseSeconds) {
                    setTimeout(() => this.dismiss(alert), alert.autoCloseSeconds * 1000);
                }
            });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.alertService.clear();
            }
        });
    }

    dismiss(alert: AlertMessage): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1)
    }

    /**
     * @deprecated The method should not be used
     */
    public addMessage(message: string, type: AlertType, title?: string, dismissible: boolean = true): void {
        this.alerts.push(new AlertMessage({ title: title, message: message, type: type, dismissible: dismissible }));
    }

    /**
     * @deprecated The method should not be used
     */
    public addErrorMessage(error: any, dismissible: boolean = true): void {
        if (error instanceof ErrorEvent) {
            this.addMessage(error.message, AlertType.Error, undefined, dismissible)
        }
        else {
            let responseObject
            try {
                responseObject = JSON.parse(error.response);
            }
            catch {
                responseObject = undefined;
            }
            if (responseObject?.status && responseObject?.title)
                this.addMessage(`${error.message}`, AlertType.Error, `${responseObject.status} - ${responseObject.title}`, dismissible)
            else
                this.addMessage(`${error.message} ${error.response}`, AlertType.Error, error.status, dismissible)
        }
    }
}

export class AlertMessage {
    public title: string | undefined;
    public message: string | undefined;
    public type: AlertType = AlertType.Info;
    public dismissible: boolean = true;
    public autoCloseSeconds: number | undefined;
    public keepAfterRouteChange?: boolean;

    public constructor(init?: Partial<AlertMessage>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Info,
    Success,
    Warning,
    Error
}
