import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlmMadResultComponent } from './mad/vlm-mad-result/vlm-mad-result.component';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { DialogVlmLoginComponent } from './dialog-vlm-login/dialog-vlm-login.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, ConfirmDialogService, ExceptionHandlerService, LoadingSpinnerService } from '@app/_services';
import { VlmMadBatchComponent } from './mad/vlm-mad-batch/vlm-mad-batch.component';
import { VlmMadCancelComponent } from './mad/vlm-mad-cancel/vlm-mad-cancel.component';
import { VlmMadCloseComponent } from './mad/vlm-mad-close/vlm-mad-close.component';
import { VlmMadCreateComponent } from './mad/vlm-mad-create/vlm-mad-create.component';
import { VlmMadDownloadComponent } from './mad/vlm-mad-download/vlm-mad-download.component';
import { VlmMadItemComponent } from './mad/vlm-mad-item/vlm-mad-item.component';
import { VlmMadItemHeaderComponent } from './mad/vlm-mad-item-header/vlm-mad-item-header.component';
import { VlmMadLoginComponent } from './mad/vlm-mad-login/vlm-mad-login.component';
import { VlmMadSettingComponent } from './mad/vlm-mad-setting/vlm-mad-setting.component';
import { TaskTypePipe, VlmLoginMethodPipe, WorkItemStatusPipe } from '@app/_shared-components/enum';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedComponentsModule } from '@app/_shared-components/shared-components.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormatTimeSpanPipe } from '@app/_shared-components/format-time-span.pipe';


@NgModule({
    imports: [
        CommonModule,
        MatListModule,
        BrowserAnimationsModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        MatPaginatorModule,
        FontAwesomeModule,
        MatSelectModule,
        MatRadioModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        TaskTypePipe,
        MatIconModule,
        VlmLoginMethodPipe,
        WorkItemStatusPipe,
        MatMenuModule,
        MatDatepickerModule,
        SharedComponentsModule,
        MatCheckboxModule,
        FormatTimeSpanPipe,
        VlmMadResultComponent,
        DialogVlmLoginComponent,
        VlmMadBatchComponent,
        VlmMadCancelComponent,
        VlmMadCloseComponent,
        VlmMadCreateComponent,
        VlmMadDownloadComponent,
        VlmMadItemComponent,
        VlmMadItemHeaderComponent,
        VlmMadLoginComponent,
        VlmMadResultComponent,
        VlmMadSettingComponent
    ],
    exports: [
        VlmMadResultComponent,
        DialogVlmLoginComponent,
        VlmMadBatchComponent,
        VlmMadCancelComponent,
        VlmMadCloseComponent,
        VlmMadCreateComponent,
        VlmMadDownloadComponent,
        VlmMadItemComponent,
        VlmMadItemHeaderComponent,
        VlmMadLoginComponent,
        VlmMadResultComponent,
        VlmMadSettingComponent
    ],
    providers: [
        ExceptionHandlerService,
        AlertService,
        LoadingSpinnerService,
        ConfirmDialogService,
    ],
})
export class VlmModule { }
