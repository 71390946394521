<h1 mat-dialog-title>{{data.columnName}}</h1>
<div mat-dialog-content>
    <div>
        <mat-checkbox [checked]="allSelected" [indeterminate]="someSelected()" (change)="setAll($any($event).checked)">
            <strong i18n="ColumnFilterSelectAll">Select all</strong>
        </mat-checkbox>
        <hr />
        <ul class="list-unstyled ps-4">
            <li *ngFor="let item of data.items">
                <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateAllSelected()">
                    <ng-container [ngSwitch]="item.displayControl">
                        <ng-container *ngSwitchCase="'app-work-type'">
                            {{ item.value | castEnum: WorkType | workType}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'app-work-batch-status'">
                            {{item.value | castEnum: WorkBatchStatus | workBatchStatus}}
                        </ng-container>
                        <ng-container *ngSwitchCase="'app-log-level'">
                            {{item.value | castEnum: WorkItemLogLevel | workItemLogLevel}}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{item.display}}
                        </ng-container>
                    </ng-container>
                </mat-checkbox>
            </li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelClick()" cdkFocusInitial i18n="ColumnFilterCancel">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="getSelectedValues()" color="primary" i18n="ColumnFilterOk">Ok</button>
</div>