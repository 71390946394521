import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService, User, Authentication, UserRole, RefreshTokenRequest } from './api.service'
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    private storageKey = 'currentUser';
    private refreshTokenTimeout?: NodeJS.Timeout;
    private deviceId: string;


    constructor(
        private userService: UserService,
        private router: Router
    ) {
        this.userSubject = new BehaviorSubject<User | null>(this.getUserFromLocalStorage());
        this.user = this.userSubject.asObservable();
        this.deviceId = this.createDeviceId();
    }

    private getUserFromLocalStorage(): User | null {
        const userJson = localStorage.getItem(this.storageKey);
        return userJson ? JSON.parse(userJson) : null;
    }

    public get currentUserValue(): User | null {
        return this.userSubject.value;
    }

    public isUserInRole(role: UserRole): boolean {
        if (!this.currentUserValue?.roles) return false;
        return this.currentUserValue.roles.some(userRole => userRole == role);
    }

    login(username: string, password: string) {
        let auth = new Authentication({ username, password, deviceId: this.deviceId })
        return this.userService.authenticate(auth)
            .pipe(map(user => {
                this.userSubject.next(user);
                localStorage.setItem(this.storageKey, JSON.stringify(user));
                this.startRefreshTokenTimer();
                return user;
            }));

    }

    logout() {
        if (this.currentUserValue?.token) {
            this.userService.revokeToken(this.deviceId).subscribe({
                error: (error) => {
                    console.error(error);
                }
            });
        }
        this.stopRefreshTokenTimer();
        this.userSubject.next(null);
        localStorage.removeItem(this.storageKey);
        if (!this.router.url.startsWith('/login')) {
            this.router.navigate(['/login']);
        }
    }

    refreshToken() {
        const token = this.getUserFromLocalStorage()?.refreshToken;
        if (!token) {
            console.error('No refresh token found');
            return of();
        }
        return this.userService.refreshToken(new RefreshTokenRequest({ token: token, deviceId: this.deviceId }))
            .pipe(map((user) => {
                this.userSubject.next(user);
                localStorage.setItem(this.storageKey, JSON.stringify(user));
                this.startRefreshTokenTimer();
                return user;
            }), catchError((error) => {
                console.error("Failed to refresh token");
                console.error(error);
                this.logout();
                return of();
            }));
    }

    private createDeviceId(): string {
        const navigatorInfo = window.navigator;
        const screenInfo = window.screen;
        let uid = navigatorInfo.mimeTypes.length.toString();
        uid += navigatorInfo.userAgent.replace(/\D+/g, '');
        uid += navigatorInfo.plugins.length;
        uid += screenInfo.height || '';
        uid += screenInfo.width || '';
        uid += screenInfo.pixelDepth || '';

        return uid;
    }

    // helper methods
    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtBase64 = this.currentUserValue!.refreshToken!;
        const jwtToken = JSON.parse(atob(jwtBase64));

        // set a timeout to refresh the token a minute before it expires
        const expires = Date.parse(jwtToken.accessTokenExpires);
        let timeout = expires - (new Date()).getTime();
        if (timeout > 300000) timeout = timeout - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
