import { AuthenticationService } from '@app/_services';
import { catchError, finalize, of } from 'rxjs';

export function appInitializer(authenticationService: AuthenticationService) {
    //return () => of();
    return () => authenticationService.refreshToken()
        .pipe(
            // catch error to start app on success or failure
            catchError(() => of())
        );
}
