import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr'
import { API_BASE_URL, SessionProgress } from './api.service';
import { AuthenticationService } from './authentication.service';
import { WorkItemBatchKey } from '.';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {

    private connection!: signalR.HubConnection;
    private baseUrl: string;
    workItemStatusChanged: BehaviorSubject<WorkItemBatchKey | null>;
    sessionStatusChanged: BehaviorSubject<number | null>;
    sessionProgressUpdate: BehaviorSubject<SessionProgress | null>;



    constructor(private auth: AuthenticationService, @Inject(API_BASE_URL) baseUrl?: string) {
        this.baseUrl = baseUrl ? baseUrl : "https://localhost:44344";
        this.workItemStatusChanged = new BehaviorSubject<WorkItemBatchKey | null>(null);
        this.sessionStatusChanged = new BehaviorSubject<number | null>(null);
        this.sessionProgressUpdate = new BehaviorSubject<SessionProgress | null>(null);
    }

    public initiateSignalrConnection(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(this.baseUrl + '/workBatchHub') // the SignalR server url as set in the .NET Project properties and Startup class
                .build();

            this.setSignalrClientMethods();

            this.connection
                .start()
                .then(() => {
                    console.log(
                        `SignalR connection success! connectionId: ${this.connection.connectionId} `
                    );
                    resolve();
                })
                .catch((error) => {
                    console.log(`SignalR connection error: ${error}`);
                    reject();
                });
        });
    }

    private setSignalrClientMethods(): void {
        this.connection.on('WorkItemStatusChanged', (key: WorkItemBatchKey) => {
            this.workItemStatusChanged.next(key);
        });
        this.connection.on('SessionStateChanged', (workBatchId: number) => {
            this.sessionStatusChanged.next(workBatchId);
        });
        this.connection.on("SessionProgressUpdate", (progress: SessionProgress) => {
            this.sessionProgressUpdate.next(progress);
        })
    }

    public joinGroup(groupName: string): void {
        this.connection.invoke("JoinGroup", groupName).catch(error => {
            console.error(error.toString())
        });
    }

}


