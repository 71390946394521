<ng-container *ngIf="workItem">
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="VLM MAD customer reference">Reference</mat-label>
                <input matInput i18n-placeholder="VLM MAD customer reference" [disabled]="!!workItem!.workItemId && !workItem.canEdit " [(ngModel)]="workItem.customerReference" (change)="dataChanged()">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="VLM MAD operator code">Operator code</mat-label>
                <input matInput i18n-placeholder="VLM MAD operator" placeholder="operator code" [disabled]="!workItem.canEdit" [(ngModel)]="workItem.content!.operatorCode!.sendValue" (change)="dataChanged()">
            </mat-form-field>
        </div>
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="VLM MAD operator code">Operator code in VLM</mat-label>
                <input matInput i18n-placeholder="VLM MAD contract code VLM" placeholder="operator code VLM" [disabled]="!workItem.canEdit" [(ngModel)]="workItem.content!.operatorCode!.documentValue" (change)="dataChanged()">
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!isCreate">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="VLM MAD document reference">Document reference</mat-label>
                <input matInput i18n-placeholder="VLM MAD document reference" [disabled]="!workItem.canEdit" [(ngModel)]="workItem.documentReference" (change)="dataChanged()">
            </mat-form-field>
        </div>
    </div>
</ng-container>