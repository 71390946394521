import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { AlertService, SessionProgress, SignalRService, VlmLoginMethod, VlmLoginState, VlmMadService, VlmService, VlmWebBotSession } from '@app/_services';
import { VlmLoginMethodPipe } from '../../../_shared-components/enum/vlm-login-method.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { NgIf, NgSwitch, NgSwitchCase, NgFor, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';


export const fadeInOutTimeout = 100;
export const fadeInOut = trigger('fadeInOut', [
    transition('void => *', [style({ opacity: '0', transform: 'translateX(-10%)' }), animate(fadeInOutTimeout)]),
    transition('* => void', [animate(fadeInOutTimeout, style({ opacity: '0' }))]),
    transition('* => *', [
        style({ opacity: '0', transform: 'translateX(-10%)' }),
        animate(fadeInOutTimeout, style({ opacity: '1', transform: 'translateX(0%)' })),
    ]),
]);

export interface VlmSessionDialogData {
    batchId: number;
    session: VlmWebBotSession;
}

@Component({
    selector: 'app-dialog-vlm-login',
    templateUrl: './dialog-vlm-login.component.html',
    styleUrls: ['./dialog-vlm-login.component.scss'],
    animations: [fadeInOut],
    standalone: true,
    imports: [NgIf, NgSwitch, NgSwitchCase, MatLabel, MatRadioGroup, FormsModule, NgFor, MatRadioButton, MatFormField, MatInput, MatButton, MatSelect, MatOption, NgSwitchDefault, NgTemplateOutlet, MatProgressBar, MatProgressSpinner, VlmLoginMethodPipe]
})

export class DialogVlmLoginComponent implements OnInit {
    webBotSession: VlmWebBotSession | undefined;
    loginState = VlmLoginState;
    spinnerStates: Array<VlmLoginState> = [VlmLoginState.CredentialsSet, VlmLoginState.DeviceNameSet, VlmLoginState.AuthenticationCodeSet]
    public loginMethods: Array<VlmLoginMethod> = Object.values(VlmLoginMethod).filter(m => m != VlmLoginMethod.Unknown);
    sessionProgress: SessionProgress | null = new SessionProgress({ message: $localize`:Loading|:Loading`, progress: 0, isErrorMessage: false });

    constructor(
        public dialogRef: MatDialogRef<DialogVlmLoginComponent>, @Inject(MAT_DIALOG_DATA) public data: VlmSessionDialogData,
        private service: VlmService,
        private signalRService: SignalRService,
        private alertService: AlertService
    ) { }

    ngOnInit(): void {
        this.signalRService.joinGroup(this.data.batchId.toString());
        this.signalRService.sessionStatusChanged.subscribe(batchId => {
            if (batchId === this.data.batchId) {
                this.getSession();
            }
        })
        this.getSession();
        this.signalRService.sessionProgressUpdate.subscribe(progress => {
            this.sessionProgress = progress!;
            console.log(this.sessionProgress);
            if (this.sessionProgress?.progress >= 100) {
                // sluit modal na 6 seconden zodat eerste workitem opgepakt kan worden
                setTimeout(() => {
                    this.dialogRef.close();
                }, 6000);
            }
        })
    }

    private getSession() {
        if (!this.data.batchId) return;
        this.service.getSession(this.data.batchId).subscribe({
            next: result => {
                this.webBotSession = result
                if (this.webBotSession?.loginSuccess === true || this.webBotSession?.loginMessage) {
                    this.dialogRef.close(result);
                }
            },
            error: (error) => {
                this.webBotSession = undefined;
                this.alertService.addErrorMessage(error);
            }
        });
    }

    private showSpinner(): void {
        // if (this.webBotSession?.loginSuccess == null && this.spinnerStates.findIndex(s => this.webBotSession?.loginState === s) >= 0) {
        //     this.spinnerService.show($localize`:MAD login spinner@@mad_login_spinner:Robot is working`);
        // }
        // else {
        //     this.spinnerService.hide();
        // }
    }

    sendLogin(state: VlmLoginState): void {
        this.webBotSession!.loginState = state;
        this.service.updateSession(this.webBotSession!).subscribe({
            next: (result) => this.webBotSession = result,
            error: (error) => this.alertService.addErrorMessage(error)
        })
    }

    checkAutoSubmit(): void {
        if (this.webBotSession!.authenticationCode!.length === 6) {
            this.sendLogin(VlmLoginState.AuthenticationCodeSet);
        }
    }
}
