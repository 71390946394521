<div class="rsPageHeader">
    <h1 i18n="Data translations title">Translations</h1>
</div>
<div>
    <mat-form-field>
        <mat-label i18n="Batch list search box label">Search</mat-label>
        <input matInput #searchInput>
        <fa-icon matSuffix [icon]="faMagnifyingGlass" class="me-3"></fa-icon>
    </mat-form-field>
</div>
<div matSort>
    <div class="rowWrapper rsGridHeader">
        <div class=" task">
            <h5 mat-sort-header="tasktype" i18n="Translation list tasktype header">Task</h5>
            <app-filter-header #filterHeaderTaskType [filterItems$]="taskTypes$" i18n-title="Data translations task filter title" title="Task filter"></app-filter-header>
        </div>
        <div class="property">
            <h5 mat-sort-header="property" i18n="Translation list property header">Property</h5>
            <app-filter-header #filterHeaderPropertyNames [filterItems$]="propertyNames$" i18n-title="Data translations property name filter title" title="Property filter"></app-filter-header>
        </div>
        <div class="value">
            <h5 mat-sort-header="value" i18n="Translation list value header">Value</h5>
        </div>
        <div class="translation">
            <h5 mat-sort-header="translation" i18n="Translation list translation header">Translation</h5>
        </div>
    </div>
    <hr />
    <ng-container *ngIf="list$ | async as list; else noItems" [formGroup]="form">
        <ng-container *ngIf="list.length > 0; else noItems" formArrayName="translations">
            <ng-container *ngFor="let item of list; let i=index">
                <div class="rowWrapper">
                    <div class="task">
                        {{item.taskType | taskType: true}}
                    </div>
                    <div class="property">
                        {{item.propertyName}}
                    </div>
                    <div class="value">
                        {{item.translation?.sendValue}}
                    </div>
                    <div class="translation">
                        <mat-form-field>
                            <input matInput [formControlName]="i">
                        </mat-form-field>
                    </div>
                </div>
                <hr />
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-template i18n="Translation list no items found" #noItems>No translations found!</ng-template>
    <mat-paginator [pageSizeOptions]="pageSizes" [length]="totalRecordCount" [pageSize]="filter.pageSize"></mat-paginator>
</div>