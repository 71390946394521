import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { AuthenticationService } from '../_services';


//https://jasonwatmore.com/post/2019/08/06/angular-8-role-based-authorization-tutorial-with-example

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.currentUserValue;
        if (user) {
            if (route.data.roles && (<Array<string>>route.data.roles).filter(r => user.roles!.map(r => r.toString()).indexOf(r) > -1).length <= 0) {
                // role not authorised so redirect to home page
                return false;
            }
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
