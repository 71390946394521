<div class="rsPageHeader">
    <h1>{{batch?.workType| workType}}</h1>
</div>
<div class="state my-3">
    <div>
        <span [ngClass]="{'text-danger': batch?.status === workBatchStatus.Faulted, 'text-success': batch?.status === workBatchStatus.Finished}">
            <strong>
                {{batch?.status| workBatchStatus}}
            </strong>
        </span>
    </div>
    <button *ngIf="batch?.status !== workBatchStatus.Finished && batch?.status !== workBatchStatus.Queued" mat-raised-button matTooltip="Enqueue batch" i18n-matTooltip="Batch header start batch button tooltip" i18n="Batch header start button" (click)="updateBatchStatus(workBatchStatus.Queued)">Start</button>
</div>
<ng-container *ngIf="batch" [ngSwitch]="batch.workType">
    <app-vlm-mad-login *ngSwitchCase="workType.Vlm_Mad" [batchId]="batch.workBatchId!"></app-vlm-mad-login>
</ng-container>