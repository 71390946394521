<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <mat-checkbox [checked]="allSelected" [indeterminate]="someSelected()" (change)="setAll($any($event).checked)">
        <strong i18n="ColumnFilterSelectAll">Select all</strong>
    </mat-checkbox>
    <hr />
    <ul class="list-unstyled ps-4">
        <li *ngFor="let item of data.filterItems">
            <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateAllSelected()">
                {{item.label}}
            </mat-checkbox>
        </li>
    </ul>
</div>
<div mat-dialog-actions class="footer mb-3">
    <button mat-raised-button (click)="cancelClick()" cdkFocusInitial i18n="Filter header dialog cancel button">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="getSelectedValues()" color="primary" i18n="Filter header dialog confirm button">Ok</button>
</div>