import { BaseFilter, FilterSortExpression, SortOperator } from '@app/_services';

BaseFilter.prototype.addSortExpression = function (sortExpression: string, sortDirection: string, storageKey: string): void {
    if (!this.sortExpressions) this.sortExpressions = [];
    this.sortExpressions = this.sortExpressions.filter(e => e.expression !== sortExpression);
    switch (sortDirection) {
        case "asc":
            this.sortExpressions.splice(0, 0, new FilterSortExpression({ expression: sortExpression, direction: SortOperator.Ascending }));
            break;
        case "desc":
            this.sortExpressions.splice(0, 0, new FilterSortExpression({ expression: sortExpression, direction: SortOperator.Descending }));
            break;
    }
    this.storeFilter(storageKey);
}

BaseFilter.prototype.storeFilter = function (storageKey: string) {
    const replacer = (key: string, value: any) => {
        return ['searchTerm', 'pageNumber'].includes(key) || value === null ? undefined : value;
    };
    localStorage.setItem(storageKey, JSON.stringify(this, replacer));
}


export function withRestoreFilter<T extends { new(...args: any[]): BaseFilter }>(Base: T, storageKey: string) {
    return class extends Base {
        static restoreFilter(): InstanceType<T> {
            const serializedData = localStorage.getItem(storageKey);
            const filter = new this(); // Create a new instance

            if (serializedData) {
                try {
                    const data = JSON.parse(serializedData);
                    filter.init(data); // Initialize with stored data if available
                }
                catch (e) {
                    console.error(e);
                    localStorage.removeItem(storageKey);
                }
            }

            // Using type assertion to assure TypeScript about the type
            return filter as InstanceType<T>;
        }
    };
}
