import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PushNotificationsService, PushSubscription } from './api.service';
import { SwPush } from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})
export class PushNotifications {
    constructor(
        private pushNotificationsService: PushNotificationsService,
        private swPush: SwPush
    ) {
        //this.swPush.unsubscribe().then(()=>{console.log("unsubscribed")}).catch(error=>console.error(error));
    }


    public subscribeToNotifications(batchId: number) {
        if (!this.swPush.isEnabled) {
            console.warn("Push notifications are disabled.");
            return;
        }

        this.swPush.subscription.subscribe((subscription) => {
            if (subscription == null) {
                this.pushNotificationsService.getVapidPublicKey().subscribe({
                    next: key => {
                        this.swPush.requestSubscription({
                            serverPublicKey: key
                        }).then(sub => {
                            this.attachPushSubscription(batchId, sub);
                        }).catch(err => console.error("Could not subscribe to notifications", err));
                    },
                    error: error => console.error(error)
                });
            }
            else {
                this.attachPushSubscription(batchId, subscription);
            }
        })
    }

    private attachPushSubscription(batchId: number, subscription: globalThis.PushSubscription) {
        this.pushNotificationsService.subscribeNotifications(batchId, new PushSubscription({
            auth: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(subscription.getKey('auth')!)))),
            endpoint: subscription.endpoint,
            p256DH: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(subscription.getKey('p256dh')!))))
        })
        ).subscribe()
    }
}
