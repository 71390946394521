import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: string[] | number[]) {
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName, documentData.join()]
      }}]);
  }

  onDataReady() {
    setTimeout(() => {
      // For print view testing: comment these 3 lines and set browser devtools to emulate print CSS media type
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    }, 1);
  }

  // https://medium.com/@Idan_Co/angular-print-service-290651c721f9
}
