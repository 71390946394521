import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, LoadingSpinnerService, TracesDocomResult, TracesDocomService, WorkItemStatus } from '@app/_services';
import { WorkItemStatusPipe } from '../../../../_shared-components/enum/work-item-status.pipe';
import { TaskTypePipe } from '../../../../_shared-components/enum/task-type.pipe';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'app-traces-docom-item-result',
    templateUrl: './traces-docom-item-result.component.html',
    styleUrls: ['./traces-docom-item-result.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, NgIf, MatFormField, MatLabel, MatInput, FormsModule, MatSelect, NgFor, MatOption, MatCheckbox, TaskTypePipe, WorkItemStatusPipe]
})
export class TracesDocomItemResultComponent implements OnInit {

    workItemResult: TracesDocomResult | undefined;
    resetWebHook: boolean = true;
    resultChanged: boolean = false;
    workItemStatusses: Array<WorkItemStatus> = new Array<WorkItemStatus>(WorkItemStatus.Created, WorkItemStatus.Queued, WorkItemStatus.Processing, WorkItemStatus.Finished, WorkItemStatus.Faulted)

    constructor(
        public dialogRef: MatDialogRef<TracesDocomItemResultComponent>,
        @Inject(MAT_DIALOG_DATA) public workItemId: number,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private service: TracesDocomService
    ) { }

    ngOnInit(): void {
        this.loadResult();
    }

    private loadResult(): void {
        this.spinnerService.showLoader(
            this.service.getWorkResult(this.workItemId))
            .subscribe({
                next: result => {
                    this.workItemResult = result;
                },
                error: (error) => {
                    this.alertService.addErrorMessage(error);
                }
            });
    }

    public updateWorkItemResult(): void {
        this.resultChanged = true;
    }
}
