<div class="position-fixed start-50 translate-middle-x col-6" style="top:20%; z-index: 9999;">
    <ng-container *ngFor="let alert of alerts">
        <div @items class="alert fade show" [class.alert-dismissible]="alert.dismissible" [ngClass]="{'alert-info': alert.type === alertType.Info, 'alert-success': alert.type === alertType.Success, 'alert-warning': alert.type === alertType.Warning, 'alert-danger': alert.type === alertType.Error }">
            <div class="center-v">
                <fa-icon [icon]="faCircleCheck" size="xl" *ngIf="alert.type === alertType.Success"></fa-icon>
                <fa-icon [icon]="faCircleInfo" size="xl" *ngIf="alert.type === alertType.Info"></fa-icon>
                <fa-icon [icon]="faTriangleExclamation" size="xl" *ngIf="alert.type === alertType.Warning"></fa-icon>
                <fa-icon [icon]="faDiamondExclamation" size="xl" *ngIf="alert.type === alertType.Error"></fa-icon>
                <ng-container *ngTemplateOutlet="alert.title ? extendedMessage : shortMessage; context: {$implicit: alert}"></ng-container>
                <button type="button" class="btn btn-close" data-dismiss="alert" aria-label="Close" *ngIf="alert.dismissible" (click)="dismiss(alert)"></button>
            </div>
        </div>
    </ng-container>
    <ng-template #shortMessage let-alert>
        {{alert.message}}
    </ng-template>
    <ng-template #extendedMessage let-alert>
        {{alert.title}}
        <hr />
        <p class="mb-0 small opacity-70">{{alert.message}}</p>
    </ng-template>
</div>