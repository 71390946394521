import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AlertService, Credentials, LoadingSpinnerService, VlmCompanySetting, VlmLoginMethod, VlmMadCompanySetting, VlmMadService } from '@app/_services';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subscription, debounce, debounceTime, distinctUntilChanged, map, shareReplay, tap, filter, startWith, mergeMap, switchMap } from 'rxjs';
import { deepMerge } from '@app/_helpers/deep-merge';
import { FormatTimeSpanPipe } from '../../../../_shared-components/format-time-span.pipe';
import { VlmLoginMethodPipe } from '../../../../_shared-components/enum/vlm-login-method.pipe';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-vlm-mad-setting',
    templateUrl: './vlm-mad-setting.component.html',
    styleUrls: ['./vlm-mad-setting.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, MatLabel, MatRadioGroup, NgFor, MatRadioButton, MatFormField, MatInput, MatCheckbox, AsyncPipe, VlmLoginMethodPipe, FormatTimeSpanPipe]
})

export class VlmMadSettingComponent implements OnInit, OnDestroy {
    public loginMethods: Array<VlmLoginMethod> = Object.values(VlmLoginMethod).filter(m => m != VlmLoginMethod.Unknown);
    public vlmLoginMethod = VlmLoginMethod;
    form: FormGroup;
    private subscriptions = new Subscription();
    public setting: VlmMadCompanySetting = new VlmMadCompanySetting();
    public autoStartBatchTimeout$!: Observable<string>;

    constructor(
        private service: VlmMadService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            loginMethod: new FormControl<VlmLoginMethod>(VlmLoginMethod.Unknown),
            credentials: this.fb.group({
                userName: [''],
                password: ['']
            }),
            deviceName: [''],
            autoStartBatch: [false],
            testMode: [false]
        });
    }

    get loginMethodControl() {
        return this.form.get('loginMethod') as FormControl<VlmLoginMethod>;
    }
    get userNameControl() {
        return this.form.get('credentials.userName') as FormControl;
    }
    get passwordControl() {
        return this.form.get('credentials.password') as FormControl;
    }
    get autoStartBatchControl() {
        return this.form.get('autoStartBatch') as FormControl<boolean>;
    }

    private getSettingsObservable() {
        return this.spinnerService.showLoader(this.service.getSetting())
            .pipe(
                tap((setting: VlmMadCompanySetting) => {
                    setting.credentials ??= new Credentials();
                    this.form.patchValue(setting, { emitEvent: false });
                    this.setting = setting;
                })
            );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.form.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap((value: VlmMadCompanySetting) => {
                    let update = deepMerge(this.setting ?? new VlmMadCompanySetting(), value);
                    if (update.loginMethod != VlmLoginMethod.Foreigner || !update.credentials?.userName || !update.credentials.password)
                        update.autoStartBatch = false;
                    this.service.updateSetting(update)
                        .subscribe({
                            next: result => {

                            }
                        })
                })
            )
            .subscribe());
        this.subscriptions.add(this.getSettingsObservable().subscribe());
        this.autoStartBatchTimeout$ = this.service.getAutoStartTimeout();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


}
