<div class="mb-2">
    <a [routerLink]="['/']" i18n="bread crumb batches"><fa-icon [icon]="faHome" size="xs" /></a> /
    <a [routerLink]="['/batches']" i18n="bread crumb batches">batches</a> /
    <a [routerLink]="['/batch']" [queryParams]="{id: workItem?.batch?.workBatchId}">{{workItem?.batch?.workBatchId}}</a> /
</div>
<div class="rsPageHeader">
    <h1>
        {{workItem?.batch?.workType | workType}}
        {{workItem?.taskType | taskType}}
    </h1>
    <h3>
        {{workItem?.documentReference}}
    </h3>
</div>
<span [ngClass]="{'text-danger': workItem?.status === workItemStatus.Faulted, 'text-success': workItem?.status === workItemStatus.Finished}">
    <strong>
        {{workItem?.status| workItemStatus}}
    </strong>
</span>
<div class="my-3">
    <div *ngIf="workItem?.status !== workItemStatus.Created">
        <app-work-item-log [workItemId]="workItem?.workItemId!"></app-work-item-log>
    </div>
    <ng-container *ngIf="workItem" [ngSwitch]="workItem.taskType">
        <app-traces-docom-item *ngSwitchCase="taskType.Traces_Docom_Cancel" [workItemId]="workItemId"></app-traces-docom-item>
        <app-traces-docom-item *ngSwitchCase="taskType.Traces_Docom_Create" [workItemId]="workItemId"></app-traces-docom-item>
        <app-traces-docom-item *ngSwitchCase="taskType.Traces_Docom_Download" [workItemId]="workItemId"></app-traces-docom-item>
        <app-vlm-mad-item *ngSwitchCase="taskType.Vlm_Mad_Cancel" [workItemId]="workItemId"></app-vlm-mad-item>
        <app-vlm-mad-item *ngSwitchCase="taskType.Vlm_Mad_Close" [workItemId]="workItemId"></app-vlm-mad-item>
        <app-vlm-mad-item *ngSwitchCase="taskType.Vlm_Mad_Create" [workItemId]="workItemId"></app-vlm-mad-item>
        <app-vlm-mad-item *ngSwitchCase="taskType.Vlm_Mad_Download" [workItemId]="workItemId"></app-vlm-mad-item>
        <app-alert *ngSwitchDefault [alerts]="alertsUnknownTaskType"></app-alert>
    </ng-container>
</div>