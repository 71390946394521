<h1 mat-dialog-title>
    <button mat-icon-button (click)="dialogRef.close()" class="float-end">
        <mat-icon>clear</mat-icon>
    </button>
    <span i18n="VLM MAD result">VLM MAD</span>
    {{workItemResult?.task | taskType }}
</h1>
<div mat-dialog-content>
    <ng-container *ngIf="workItemResult">
        <div class="row">
            <div class="col-6">
                <mat-form-field class="col-12" appearance="fill">
                    <mat-label i18n="VLM MAD result document reference">Document reference</mat-label>
                    <input matInput i18n-placeholder="VLM MAD result document reference placeholder" [(ngModel)]="workItemResult.documentReference" (change)="updateWorkItemResult()">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="col-12" appearance="fill">
                    <mat-label i18n="VLM MAD result document id id">Document ID</mat-label>
                    <input matInput i18n-placeholder="VLM MAD result document id placeholder" [(ngModel)]="workItemResult.documentId" (change)="updateWorkItemResult()">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <mat-form-field class="col-12" appearance="fill">
                    <mat-label i18n="VLM MAD result document id id">Status</mat-label>
                    <mat-select [(value)]="workItemResult.status" (change)="updateWorkItemResult()">
                        <mat-option *ngFor="let status of workItemStatusses" [value]="status">
                            {{status|workItemStatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="resultChanged">
            <div class="col-6">
                <mat-form-field class="col-12" appearance="fill">
                    <mat-checkbox [(ngModel)]="resetWebHook" (change)="updateWorkItemResult()">Reset webhook</mat-checkbox>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
</div>