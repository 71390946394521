import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog } from "../_services/api.service";
import { isDevMode } from '@angular/core';
import { debounceTime } from "rxjs";


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    service: ExceptionHandlerService;

    constructor(private injector: Injector) {
        this.service = this.injector.get(ExceptionHandlerService);
    }

    handleError(error: Error) {
        let log = new ExceptionLog();
        log.url = window.location.href;
        log.message = error.message ? error.message : error.toString();

        log.stackTrace = error.stack;
        if (window.location.href.endsWith("login")) {
            this.service.logWarn(log).subscribe();
        }
        else {
            this.service.logError(log).subscribe();
        }

        if (isDevMode()) throw error;
    }

    private createLog(message: string): ExceptionLog {
        let log = new ExceptionLog();
        log.url = window.location.href;
        log.message = message;
        return log;
    }

    public logTraceMessage(message: string): void {
        this.service.logTrace(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }

    public logDebugMessage(message: string): void {
        this.service.logDebug(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }

    public logInfoMessage(message: string): void {
        this.service.logInfo(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }

    public logWarnMessage(message: string): void {
        this.service.logWarn(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }

    public logErrorMessage(message: string): void {
        this.service.logError(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }

    public logFatalMessage(message: string): void {
        this.service.logFatal(this.createLog(message)).pipe(
            debounceTime(500)
        )
            .subscribe();
    }
}
