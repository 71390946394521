import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService, TracesDocomDownload, TracesDocomService, WorkItemOfTracesDocomDownload } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { TracesDocomBaseItem } from '../traces-docom-base-item';
import { TracesDocomItemHeaderComponent } from '../traces-docom-item-header/traces-docom-item-header.component';


@Component({
    selector: 'app-traces-docom-download',
    templateUrl: './traces-docom-download.component.html',
    styleUrls: ['./traces-docom-download.component.scss'],
    standalone: true,
    imports: [TracesDocomItemHeaderComponent]
})
export class TracesDocomDownloadComponent extends TracesDocomBaseItem implements OnInit {
    downloadWorkItem: WorkItemOfTracesDocomDownload | undefined;

    constructor(
        private service: TracesDocomService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.workItemId) {
            this.fetchCreateWorkItem();
        }
    }

    private fetchCreateWorkItem(): void {
        this.spinnerService.showLoader(this.service.getDownload(this.workItemId)).subscribe(result => {
            this.downloadWorkItem = result;
        })
    }

    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateDownload(this.downloadWorkItem!)).subscribe(result => {
            this.downloadWorkItem = result;
            this.workItemUpdated.emit(this.downloadWorkItem);
        })
    }
}
