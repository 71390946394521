<div class="rsPageHeader">
    <h1 *ngIf="user?.id; else newUser">{{user?.firstName}} {{user?.lastName}}</h1>
    <button mat-raised-button i18n="Remove user button" (click)="removeUser()" *ngIf="user?.id">
        <fa-icon [icon]="faTrashCan"></fa-icon> Remove user
    </button>
    <ng-template #newUser>
        <h1 i18n="New user title">New user</h1>
    </ng-template>
</div>
<form [formGroup]="form">
    <fieldset class="border rounded account">
        <legend i18n="User editor account fieldset">Account</legend>
        <div>
            <mat-form-field>
                <mat-label i18n="User editor first name">First name</mat-label>
                <input matInput formControlName="firstName" i18n-placeholder="User editor first name placeholder" placeholder="first name">
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n="User editor last name">Last name</mat-label>
                <input matInput formControlName="lastName" i18n-placeholder="User editor last name placeholder" placeholder="last name">
            </mat-form-field>
        </div>
    </fieldset>
    <fieldset class="border rounded login">
        <legend i18n="User editor login fieldset">Login</legend>
        <div>
            <mat-form-field>
                <mat-label i18n="User editor email">Email</mat-label>
                <input matInput formControlName="username" i18n-placeholder="User editor email placeholder" placeholder="email address">
            </mat-form-field>

            <div class="passwordReset">
                <button mat-raised-button *ngIf="user?.id && !newPassword" i18n="User editor reset password" (click)="resetPassword()">Reset password</button>
                <div *ngIf="user?.id && newPassword" class="alert alert-success" role="alert">
                    <span i18n="User editor new password">New password: </span>
                    <span class="fw-bold fs-3">{{newPassword}}</span>
                </div>
            </div>
        </div>
    </fieldset>
    <fieldset class="border rounded roles">
        <legend i18n="User editor roles fieldset">Roles</legend>
        <ul class="list-unstyled" formArrayName="roles">
            <li *ngFor="let role of rolesFormArray.controls;let i = index">
                <mat-checkbox [formControlName]="i">{{userRoles[i] | userRole}}</mat-checkbox>
            </li>
        </ul>
    </fieldset>
</form>