import { Component, Input, OnInit } from '@angular/core';
import { AlertMessage, AlertType } from '@app/_shared-components/alert/alert.component';
import { AlertService, GenericWorkItem, LoadingSpinnerService, TaskType, TracesDocomService, WorkItemOfTracesDocomItemContent } from '@app/_services';
import { TaskTypePipe } from '../../../../_shared-components/enum/task-type.pipe';
import { AlertComponent } from '../../../../_shared-components/alert/alert.component';
import { TracesDocomDownloadComponent } from '../traces-docom-download/traces-docom-download.component';
import { TracesDocomCreateComponent } from '../traces-docom-create/traces-docom-create.component';
import { TracesDocomCancelComponent } from '../traces-docom-cancel/traces-docom-cancel.component';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'app-traces-docom-item',
    templateUrl: './traces-docom-item.component.html',
    styleUrls: ['./traces-docom-item.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatSelect, FormsModule, MatOption, NgFor, NgSwitch, NgSwitchCase, TracesDocomCancelComponent, TracesDocomCreateComponent, TracesDocomDownloadComponent, NgSwitchDefault, AlertComponent, TaskTypePipe]
})
export class TracesDocomItemComponent implements OnInit {
    workItem!: WorkItemOfTracesDocomItemContent;
    @Input() workItemId: number | undefined;


    alertsUnknownTaskType: Array<AlertMessage> = [new AlertMessage({ message: $localize`:Traces Docom unkown work item type@@traces_docom_unknown_work_item_type:Unknown task type`, type: AlertType.Error })]

    taskType = TaskType;
    readonly taskTypes: Array<TaskType> = Object.values(TaskType);

    constructor(private service: TracesDocomService, private spinnerService: LoadingSpinnerService, private alertService: AlertService) {
    }

    ngOnInit(): void {
        if (this.workItemId) this.fetchTracesDocomWorkItem();
    }

    private fetchTracesDocomWorkItem() {
        this.spinnerService.showLoader(this.service.getWorkItem(this.workItemId)).subscribe(result => {
            this.workItem = result;
        })
    }

    workItemUpdated(workItem: WorkItemOfTracesDocomItemContent): void {
        this.workItem = workItem;
    }

}
