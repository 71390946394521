import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { WorkBatchStatus, WorkItemFilter, WorkService } from '@app/_services';
import { StorageService } from '@app/_services/storage.service';


@Component({
    selector: 'app-base-batch-editor',
    template: ''
})
export abstract class BaseBatchEditor implements OnChanges {

    @Input() batchId: number | undefined;
    @Input() batchStatus: WorkBatchStatus | undefined;
    @Output() batchStatusUpdated: EventEmitter<WorkBatchStatus> = new EventEmitter<WorkBatchStatus>();

    pageSizes: Array<number> = [10, 25, 50, 100];
    filter!: WorkItemFilter;

    batchEditableStatusses: Array<WorkBatchStatus> = [WorkBatchStatus.Undefined, WorkBatchStatus.Created, WorkBatchStatus.Invalid, WorkBatchStatus.Faulted];

    constructor(protected workService: WorkService) { }

    abstract loadBatch(): void;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.batchStatus?.previousValue) this.loadBatch();
    }

    downloadDocument(workItemId: number | null | undefined) {
        if (!workItemId) return;
        this.workService.getFile(workItemId).subscribe({
            next: fileResponse => {
                const blob = new Blob([fileResponse.data], { type: fileResponse.headers ? fileResponse.headers['content-type'] : 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = fileResponse.fileName ?? 'download';
                anchor.href = url;
                anchor.click();
                anchor.remove();
                window.URL.revokeObjectURL(url);
            }
        });
    }
}
