import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { withRestoreFilter } from '@app/_helpers';
import { DataTranslationEdit, DataTranslationFilter, DataTranslationService, ExceptionHandlerService, LoadingSpinnerService, TaskType } from '@app/_services';
import { TaskTypePipe } from '@app/_shared-components/enum';
import { FilterHeaderComponent, FilterItem } from '@app/_shared-components/filter-header/filter-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, filter, fromEvent, map, merge, shareReplay, startWith, switchMap, tap } from 'rxjs';

@Component({
    selector: 'app-data-translation-editor',
    standalone: true,
    imports: [
        MatInputModule,
        MatFormFieldModule,
        FontAwesomeModule,
        CommonModule,
        MatPaginatorModule,
        MatSortModule,
        TaskTypePipe,
        FilterHeaderComponent,
        ReactiveFormsModule
    ],
    providers: [
        DataTranslationService,
        LoadingSpinnerService,
        ExceptionHandlerService
    ],
    templateUrl: './data-translation-editor.component.html',
    styleUrl: './data-translation-editor.component.scss'
})
export class DataTranslationEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
    @ViewChild('filterHeaderTaskType', { static: true }) filterHeaderTaskType!: FilterHeaderComponent<TaskType>;
    @ViewChild('filterHeaderPropertyNames', { static: true }) filterHeaderPropertyNames!: FilterHeaderComponent<TaskType>;

    faMagnifyingGlass = faMagnifyingGlass;

    private readonly storageKey: string = 'data-translation-list-filter';
    pageSizes: Array<number> = [10, 25, 50];
    totalRecordCount: number | null | undefined = null;
    filter!: DataTranslationFilter;
    list$: Observable<DataTranslationEdit[] | null | undefined> | null = null;
    taskTypes$ = new Subject<FilterItem<TaskType>[]>();
    propertyNames$: Observable<FilterItem<string>[]>;
    form: FormGroup;
    private subscriptions$ = new Subscription();

    constructor(
        private loaderService: LoadingSpinnerService,
        private dataTranslationService: DataTranslationService,
        private exceptionHandlerService: ExceptionHandlerService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            translations: this.fb.array([])
        });
        this.propertyNames$ = this.dataTranslationService.getPropertyNames().pipe(
            map((result) => result?.map((item) => <FilterItem<string>>{
                value: item,
                label: item,
                selected: this.filter.propertyNames && this.filter.propertyNames?.findIndex(x => x == item) >= 0
            }))
        );
    }

    get translations(): FormArray {
        return this.form.get('translations') as FormArray;
    }

    ngOnInit(): void {
        this.filter = withRestoreFilter(DataTranslationFilter, this.storageKey).restoreFilter();
    }

    ngAfterViewInit(): void {
        let search$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            }),
            filter(res => res.length > 2 || res.length === 0),
            debounceTime(500),
            distinctUntilChanged(),
            tap((searchTerm) => {
                this.filter.pageNumber = 0;
                this.filter.searchTerm = searchTerm;
            })
        )
        let sort$ = this.sort.sortChange.pipe(
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );
        let page$ = this.paginator.page.pipe(
            tap((event: PageEvent) => {
                this.filter.pageSize = event.pageSize;
                this.filter.pageNumber = event.pageIndex;
            })
        );
        let taskTypeFilter$ = this.filterHeaderTaskType.selectedFilterItems$.pipe(
            tap((taskTypes) => {
                this.filter.tasks = taskTypes;
            })
        );
        let propertyNameFilter$ = this.filterHeaderPropertyNames.selectedFilterItems$.pipe(
            tap((propertyNames) => {
                this.filter.propertyNames = propertyNames;
            })
        );

        this.list$ = merge(search$, sort$, page$, taskTypeFilter$, propertyNameFilter$).pipe(
            startWith(null),
            tap(() => {
                this.filter.storeFilter(this.storageKey);
            }),
            switchMap(() => this.getListObservable()),
            shareReplay(1)
        );

        this.taskTypes$.next(Object.values(TaskType).filter(t => t != TaskType.Undefined).map(t => {
            return {
                value: t,
                label: new TaskTypePipe(this.exceptionHandlerService).transform(t, true),
                selected: !!this.filter.tasks && this.filter.tasks!.findIndex(x => x == t) >= 0
            }
        }));

    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        this.clearFormControlSubscriptions();
    }

    private getListObservable(): Observable<DataTranslationEdit[] | null | undefined> {
        return this.loaderService.showLoader(
            this.dataTranslationService.getList(this.filter).pipe(
                tap((result) => {
                    if (result.rowCount != null) this.totalRecordCount = result.rowCount;
                    this.bindForm(result.resultSet);
                }),
                map((result) => result.resultSet)
            )
        );
    }

    private bindForm(data: DataTranslationEdit[] | null | undefined): void {
        this.clearFormControlSubscriptions();
        if (!data || data.length === 0) {
            this.translations.clear();
            return;
        }
        data?.forEach((item, index) => {
            let control = this.translations.at(index) as FormControl;

            if (!control) {
                control = this.fb.control(item.translation?.documentValue);
                this.translations.push(control);
            } else {
                control.patchValue(item.translation?.documentValue, { emitEvent: false });
            }

            this.controlSubscriptions.push(control.valueChanges.pipe(
                debounceTime(500),
                filter((value) => item != null),
                switchMap((value) => this.dataTranslationService.updateTranslation(item.id!, value ?? "")),
                switchMap(() => this.dataTranslationService.getList(this.filter))
            ).subscribe(result => this.bindForm(result.resultSet)));

        });
        // Remove excess controls
        for (let i = this.translations.length - 1; i >= data.length; i--) {
            this.translations.removeAt(i);
        }
    }

    // Array to keep track of subscriptions
    private controlSubscriptions: Subscription[] = [];

    // Method to clear subscriptions
    private clearFormControlSubscriptions() {
        this.controlSubscriptions.forEach(sub => sub.unsubscribe());
        this.controlSubscriptions = [];
    }
}
