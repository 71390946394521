import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingSpinnerService, VlmMadCancel, VlmMadService, WorkItemOfVlmMadCancel } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { VlmMadBaseItem } from '../vlm-mad-base-item';
import { VlmMadItemHeaderComponent } from '../vlm-mad-item-header/vlm-mad-item-header.component';

@Component({
    selector: 'app-vlm-mad-cancel',
    templateUrl: './vlm-mad-cancel.component.html',
    styleUrls: ['./vlm-mad-cancel.component.scss'],
    standalone: true,
    imports: [VlmMadItemHeaderComponent]
})
export class VlmMadCancelComponent extends VlmMadBaseItem implements OnInit {

    cancelWorkItem: WorkItemOfVlmMadCancel | undefined;

    constructor(
        private service: VlmMadService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.workItemId) {
            this.fetchCancelWorkItem();
        }
    }

    private fetchCancelWorkItem(): void {
        this.spinnerService.showLoader(this.service.getCancel(this.workItemId)).subscribe({
            next: result => {
                this.cancelWorkItem = result;
            }
        })
    }

    updateWorkItem(): void {
        this.spinnerService.showLoader(this.service.updateCancel(this.cancelWorkItem!)).subscribe({
            next: result => {
                this.cancelWorkItem = result;
                this.workItemUpdated.emit(this.cancelWorkItem);
            }
        })
    }
}
