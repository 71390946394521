import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericWorkItem, WorkItemOfVlmMadItemContent } from '@app/_services';

@Component({
    selector: 'app-mad-base-item',
    template: ''
})
export class VlmMadBaseItem {
    @Input() workItemId: number | undefined;
    @Output() workItemUpdated: EventEmitter<WorkItemOfVlmMadItemContent> = new EventEmitter<WorkItemOfVlmMadItemContent>();

    readonly debounceTime: number = 543;
}
