import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogData } from '@app/components/confirm-dialog/confirm-dialog.component';
import { LoadingSpinnerService, ResetPasswordRequest, User, UserRole, UserService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfirmDialogService } from '@app/_services/confirm-dialog.service';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Observable, Subscription, debounceTime, distinctUntilChanged, mergeMap, of, tap } from 'rxjs';
import { deepMerge } from '@app/_helpers';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { UserRolePipe } from '../../_shared-components/enum/user-role.pipe';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    standalone: true,
    imports: [NgIf, MatButton, FaIconComponent, FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgFor, MatCheckbox, UserRolePipe]
})
export class UserComponent implements OnInit, OnDestroy {

    form: FormGroup;
    private subscriptions = new Subscription();
    public user: User | undefined;
    userRoles: UserRole[];
    public newPassword: string | undefined;
    faTrashCan = faTrashCan;

    constructor(
        private route: ActivatedRoute,
        private spinnerService: LoadingSpinnerService,
        private userService: UserService,
        private alertService: AlertService,
        private confirmService: ConfirmDialogService,
        private fb: FormBuilder,
        private router: Router
    ) {
        this.userRoles = Object.keys(UserRole).filter((role) => role !== UserRole.None).map((role) => <UserRole>role);
        this.form = this.fb.group({
            firstName: [''],
            lastName: [''],
            username: ['', Validators.required, Validators.email],
            roles: this.fb.array(this.userRoles.map((role) => this.fb.control(false))),
        });
    }

    get rolesFormArray() {
        return this.form.controls.roles as FormArray;
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.route.params.pipe(
                mergeMap(params => this.getUser(+params['id'])))
                .subscribe()
        );
        this.subscriptions.add(
            this.form.valueChanges
                .pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                    mergeMap(user => {
                        user.roles = this.userRoles.filter((role, index) => this.rolesFormArray.controls[index].value);
                        let update = deepMerge(this.user ?? new User(), user);
                        return this.updateUser(update);
                    })
                )
                .subscribe()
        );
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }



    private getUser(id: number | null | undefined): Observable<User> {
        if (id == null || isNaN(id)) {
            this.user = new User();
            this.setUser(this.user);
            return of(new User());
        }
        return this.spinnerService.showLoader(this.userService.getById(id))
            .pipe(
                tap({
                    next: user => {
                        this.setUser(user);
                    }
                })
            );
    }

    private updateUser(user: User): Observable<User> {
        return this.spinnerService.showLoader(
            this.userService.updateUser(user)
                .pipe(
                    tap({
                        next: user => {
                            if (!this.user?.id) this.router.navigate([`/user/${user.id}`]);
                            this.setUser(user);
                        }
                    })
                )
        );
    }

    private setUser(user: User): void {
        this.user = user;
        this.form.patchValue(user, { emitEvent: false });
        this.rolesFormArray.controls.forEach((control, index) => {
            control.setValue(user.roles?.includes(this.userRoles[index]), { emitEvent: false });
        });
    }

    resetPassword(): void {
        this.confirmService.showDialog(new ConfirmDialogData({
            title: $localize`:User editor reset password dialog title:Password reset`,
            message: $localize`:User editor reset password dialog content:Are you sure you want to reset the password for <strong>${this.user?.firstName} ${this.user?.lastName}</strong>?<br />An email will be sent to reset the password`,
            returnData: Math.random().toString(36).slice(-8) // generate random password.
        })).subscribe(password => {
            if (password) {
                this.spinnerService.showLoader(this.userService.resetPassword(new ResetPasswordRequest({ userId: <number>this.user?.id, password: password }))).subscribe({
                    next: () => {
                        this.newPassword = password;
                    }
                })
            }
        });
    }

    removeUser(): void {
        this.confirmService.showDialog(new ConfirmDialogData({
            title: $localize`:User editor delete user dialog title:Remove user`,
            message: $localize`:User editor delete user dialog content:Are you sure you want to remove <strong>${this.user?.firstName} ${this.user?.lastName}</strong>?`,
            returnData: true
        })).subscribe(confirm => {
            if (confirm) {
                this.spinnerService.showLoader(this.userService.deleteUser(<number | undefined>this.user?.id)).subscribe({
                    next: result => {
                        this.router.navigate(['/users']);
                        this.alertService.addMessage($localize`:User editor remove user success:User removed`, AlertType.Success);
                    }
                })
            }
        });
    }
}
