<div>
    <mat-tab-group>
        <mat-tab *ngFor="let wt of workTypes">
            <ng-template mat-tab-label>
                {{wt | workType}}
            </ng-template>
            <ng-container [ngSwitch]="wt">
                <app-traces-docom-setting *ngSwitchCase="workType.Traces_Docom"></app-traces-docom-setting>
                <app-vlm-mad-setting *ngSwitchCase="workType.Vlm_Mad"></app-vlm-mad-setting>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>