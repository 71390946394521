<div class="rsPageHeader">
    <h1 i18n="Batch list title">Batches</h1>
</div>
<div class="filterHeader">
    <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [dateFilter]="dateFilter">
            <input matStartDate placeholder="Start date" [value]="filter.startDate" (dateChange)="startPeriodChanged($event)">
            <input matEndDate placeholder="End date" [value]="filter.endDate" (dateChange)="endPeriodChanged($event)">
        </mat-date-range-input>
        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field>
        <mat-label i18n>Search batch</mat-label>
        <input matInput #searchInput>
        <mat-icon aria-hidden="false" matSuffix i18n-aria-label aria-label="Search batch">search</mat-icon>
    </mat-form-field>
</div>

<div matSort>
    <div class="gridRow rsGridHeader">
        <div class="id">
            <h5 mat-sort-header="id" i18n="Batchlist batch id header">ID</h5>
        </div>
        <div class="type">
            <h5 mat-sort-header="type" i18n="Batchlist batch work type header">Type</h5>
            <app-filter-header #filterHeaderWorkTypes [filterItems$]="workTypes$" i18n-title="Batchlist work type filter title" title="Document type filter"></app-filter-header>
        </div>
        <div class="status">
            <h5 mat-sort-header="state" i18n="Batchlist batch status header"> Status </h5>
            <app-filter-header #filterHeaderStatusses [filterItems$]="statusses$" i18n-title="Batchlist status filter title" title="Status filter"></app-filter-header>
        </div>
        <div class="log">

        </div>
    </div>
    <hr />
    <ng-container *ngIf="totalRecordCount>0; else noBatches">
        <ng-container *ngFor="let batch of batches">
            <div class="gridRow">
                <div class="id">
                    <a [routerLink]="['/batch']" [queryParams]="{id: batch.workBatchId}">{{batch.workBatchId}}</a>
                </div>
                <div class="type">
                    {{batch.workType|workType}}
                </div>
                <div class="status">
                    <a [routerLink]="['/batch']" [queryParams]="{id: batch.workBatchId}" [ngClass]="{'text-danger': batch.status === workBatchStatus.Faulted, 'text-success': batch.status === workBatchStatus.Finished}">
                        {{batch.status|workBatchStatus}}
                    </a>
                </div>
                <div class="log">
                    <fa-icon size="lg" *ngIf="hasWarnings(batch)" class="text-warning" [icon]="faExclamationTriangle"></fa-icon>
                    <fa-icon size="lg" *ngIf="hasErrors(batch)" class="text-danger" [icon]="faDiamondExclamation"></fa-icon>
                </div>
            </div>
            <hr />
        </ng-container>
    </ng-container>
    <mat-paginator [pageSizeOptions]="pageSizes" [length]="totalRecordCount" [pageSize]="filter.pageSize"></mat-paginator>
    <ng-template #noBatches>
        <span i18n="No batches found">No batches found</span>
    </ng-template>
</div>
