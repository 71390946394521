<div class="rsPageHeader">
    <h1 i18n="Change password title">Change password</h1>
</div>


<form #form="ngForm" (ngSubmit)="changePassword()" [formGroup]="changePasswordForm" role="form" autocomplete="off">
    <fieldset class="border rounded changePassword">
        <legend i18n="Change password legend">Change password</legend>
        <div>
            <mat-form-field *ngIf="!token">
                <mat-label i18n="Change password current password">Current password</mat-label>
                <input type="password" matInput name='currentPassword' formControlName='currentPassword'>
                <mat-error *ngIf='currentPassword?.invalid' i18n="Change password current password required error message">
                    Current password is required
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n="Change password new password">New password</mat-label>
                <input type="password" matInput name='newPassword' formControlName='newPassword'>
                <mat-error *ngIf='newPassword?.invalid' i18n="Change password new password error message">
                    The password must be between 6 and 25 characters long and must <em>not</em> contain spaces
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label i18n="Change password verify password">Verify password</mat-label>
                <input type="password" matInput name='verifyPassword' formControlName='verifyPassword'>
                <mat-error *ngIf="verifyPassword?.hasError('matching')" i18n="Passwords must match error message">
                    Passwords must match
                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" i18n="Change password save button">
                Save
            </button>
        </div>
    </fieldset>
</form>