<div *ngIf="workItem && !workItem?.workItemId">
    <mat-form-field>
        <mat-label i18n="VLM MAD task type">Task</mat-label>
        <mat-select [(ngModel)]="workItem.taskType">
            <mat-option value="" selected></mat-option>
            <mat-option *ngFor="let task of taskTypes" [value]="task">
                {{task | taskType}}
            </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf='email.invalid' i18n="User login invalid email error message">Invalid email</mat-error> -->
    </mat-form-field>
</div>
<ng-container *ngIf="workItem" [ngSwitch]="workItem.taskType">
    <app-vlm-mad-cancel *ngSwitchCase="taskType.Vlm_Mad_Cancel" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-vlm-mad-cancel>
    <app-vlm-mad-close *ngSwitchCase="taskType.Vlm_Mad_Close" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-vlm-mad-close>
    <app-vlm-mad-create *ngSwitchCase="taskType.Vlm_Mad_Create" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-vlm-mad-create>
    <app-vlm-mad-download *ngSwitchCase="taskType.Vlm_Mad_Download" [workItemId]="workItemId" (workItemUpdated)="workItemUpdated($event)"></app-vlm-mad-download>
    <app-alert *ngSwitchDefault [alerts]="alertsUnknownTaskType"></app-alert>
</ng-container>