import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingSpinnerComponent } from '@app/_shared-components/loading-spinner/loading-spinner.component';
import { combineLatest, merge, Observable, timer } from 'rxjs';
import { distinctUntilChanged, mapTo, share, startWith, takeUntil, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingSpinnerService {
    readonly delay: number = 1000;
    readonly duration: number = 1000;

    private dialogRef: MatDialogRef<LoadingSpinnerComponent> | null = null;

    constructor(private dialog: MatDialog) {

    }

    showLoader<T>(source$: Observable<T>, message?: string): Observable<T> {

        const result$ = source$.pipe(share());

        const showLoadingIndicator$ = merge(
            // ON in 1second
            timer(this.delay).pipe(map(() => true), takeUntil(result$)),

            // OFF once we receive a result, yet at least in 2s
            combineLatest([result$, timer(this.duration + this.delay)]).pipe(map(() => false))
        )
            .pipe(
                startWith(false),
                distinctUntilChanged()
            );

        showLoadingIndicator$.subscribe({
            next: isLoading => {
                if (isLoading && !this.dialogRef) {
                    this.dialogRef = this.dialog.open(LoadingSpinnerComponent, {
                        disableClose: true,
                        data: message
                    });
                }
                if (!isLoading && this.dialogRef) {
                    this.dialogRef.close();
                }
            },
            complete: () => {
                if (this.dialogRef) {
                    this.dialogRef.close();
                }
            }
        });
        return result$;
    }

    show(message?: string): void {
        this.dialogRef = this.dialog.open(LoadingSpinnerComponent, {
            disableClose: true,
            data: message
        });
    }

    hide(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

}


