import { Injectable } from '@angular/core';
import { AlertMessage, AlertType } from '@app/_shared-components/alert/alert.component';
import { ErrorInterceptor } from '@app/_helpers';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private subject = new Subject<AlertMessage | null>();
    //private defaultId = 'default-alert';


    constructor() { }

    onAlert(): Observable<AlertMessage | null> {
        return this.subject.asObservable();
    }

    public addMessage(message: string, type: AlertType, title?: string, dismissible: boolean = true, autoCloseSeconds: number = 7, keepAfterRouteChange: boolean = false): void {
        this.alert(new AlertMessage({ title: title, message: message, type: type, dismissible: dismissible, autoCloseSeconds: autoCloseSeconds, keepAfterRouteChange: keepAfterRouteChange }))
    }

    /**
     * @deprecated The method should not be used
     */
    public addErrorMessage(error: any, dismissible: boolean = true, autoCloseSeconds: number = 0): void {
        //console.error(error);
        if (error.status == 401) return;
        else {
            if (error instanceof ErrorEvent) {
                this.addMessage(error.message, AlertType.Error, undefined, dismissible, autoCloseSeconds)
            }
            else {
                let responseObject
                try {
                    responseObject = JSON.parse(error.response);
                }
                catch {
                    responseObject = undefined;
                }
                if (responseObject?.status && responseObject?.title)
                    this.addMessage(`${error.message}`, AlertType.Error, `${responseObject.status} - ${responseObject.title}`, dismissible, autoCloseSeconds)
                else
                    this.addMessage(`${error.message} ${error.response ? error.response : ''}`, AlertType.Error, error.status, dismissible, autoCloseSeconds)
            }
        }
    }
    // main alert method
    alert(alert: AlertMessage) {
        this.subject.next(alert);
    }

    // clear alerts
    clear() {
        this.subject.next(null);
    }
}
