import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convert a string or number to an enum value.
 */

@Pipe({
    name: 'castEnum',
    standalone: true,
})
export class CastEnumPipe implements PipeTransform {
    /**
     * Convert a string or number (value) to an enum value.
     * @param value The string or number to convert.
     * @param enumType The enum to cast to.
     */
    transform<T>(value: any, enumType: T): T[keyof T] | undefined {
        if (typeof value === 'string') {
            return enumType[value as keyof T];
        } else if (typeof value === 'number') {
            return enumType[value as keyof T] as T[keyof T];
        }
        return undefined;
    }
}
