import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AlertService, AuthenticationService } from '@app/_services';
import { LoadingSpinnerService } from '@app/_services/loading-spinner.service';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { first } from 'rxjs';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatError, RouterLink, MatButton]
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    public static readonly emailAddressKey = 'emailAddress';

    constructor(
        private auth: AuthenticationService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.loginForm = new FormGroup(
            {
                emailAddress: new FormControl('', [Validators.required, Validators.email]),
                password: new FormControl('', Validators.required)
            });
    }

    get email() {
        return this.loginForm.get('emailAddress');
    }
    get password() {
        return this.loginForm.get('password');
    }

    ngOnInit(): void {
        this.email?.setValue(localStorage.getItem(LoginComponent.emailAddressKey));
    }


    public login() {
        if (this.loginForm.invalid) {
            return;
        }
        this.spinnerService.showLoader(this.auth.login(this.email?.value, this.password?.value))
            .pipe(first())
            .subscribe({
                next: result => {
                    localStorage.setItem(LoginComponent.emailAddressKey, this.email?.value);
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    this.router.navigate([returnUrl]);
                },
                error: (error) => {
                    this.alertService.addMessage($localize`:Failed login message:Unknown username and/or password.`, AlertType.Error, $localize`:Failed login title:Login failed`)
                }
            });
    }
}
