import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, ConfirmDialogService, ExceptionHandlerService, LoadingSpinnerService } from '@app/_services';
import { TaskTypePipe, VlmLoginMethodPipe, WorkItemStatusPipe } from '@app/_shared-components/enum';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TracesDocomBatchComponent } from './docom/traces-docom-batch/traces-docom-batch.component';
import { TracesDocomCancelComponent } from './docom/traces-docom-cancel/traces-docom-cancel.component';
import { TracesDocomCreateComponent } from './docom/traces-docom-create/traces-docom-create.component';
import { TracesDocomDownloadComponent } from './docom/traces-docom-download/traces-docom-download.component';
import { TracesDocomItemComponent } from './docom/traces-docom-item/traces-docom-item.component';
import { TracesDocomItemHeaderComponent } from './docom/traces-docom-item-header/traces-docom-item-header.component';
import { TracesDocomItemResultComponent } from './docom/traces-docom-item-result/traces-docom-item-result.component';
import { TracesDocomSettingComponent } from './docom/traces-docom-setting/traces-docom-setting.component';
import { SharedComponentsModule } from '@app/_shared-components/shared-components.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormatTimeSpanPipe } from '@app/_shared-components/format-time-span.pipe';



@NgModule({
    imports: [
        CommonModule,
        SharedComponentsModule,
        MatListModule,
        BrowserAnimationsModule,
        MatSortModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        MatPaginatorModule,
        FontAwesomeModule,
        MatSelectModule,
        MatRadioModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        TaskTypePipe,
        MatIconModule,
        VlmLoginMethodPipe,
        WorkItemStatusPipe,
        MatMenuModule,
        MatDatepickerModule,
        MatCheckboxModule,
        FormatTimeSpanPipe,
        TracesDocomBatchComponent,
        TracesDocomCancelComponent,
        TracesDocomCreateComponent,
        TracesDocomDownloadComponent,
        TracesDocomItemComponent,
        TracesDocomItemHeaderComponent,
        TracesDocomItemResultComponent,
        TracesDocomSettingComponent
    ],
    exports: [
        TracesDocomBatchComponent,
        TracesDocomCancelComponent,
        TracesDocomCreateComponent,
        TracesDocomDownloadComponent,
        TracesDocomItemComponent,
        TracesDocomItemHeaderComponent,
        TracesDocomItemResultComponent,
        TracesDocomSettingComponent
    ],
    providers: [
        ExceptionHandlerService,
        AlertService,
        LoadingSpinnerService,
        ConfirmDialogService,
    ],
})
export class TracesModule { }
