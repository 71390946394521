import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, MatSortHeader } from '@angular/material/sort';
import { AuthenticationService, LoadingSpinnerService, User, UserFilter, UserService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { Observable, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, tap, startWith, switchMap, shareReplay } from 'rxjs/operators';

import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { withRestoreFilter } from '@app/_helpers';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    standalone: true,
    imports: [MatButton, RouterLink, FaIconComponent, MatFormField, MatLabel, MatInput, MatSuffix, MatSort, MatSortHeader, NgIf, NgFor, MatPaginator, AsyncPipe]
})
export class UserListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

    faPlus = faPlus;
    faMagnifyingGlass = faMagnifyingGlass;

    pageSizes: Array<number> = [10, 25, 50, 100];
    totalRecordCount: number | null | undefined = null;
    filter!: UserFilter;
    list$: Observable<User[] | null | undefined> | null = null;


    private readonly storageKey = "userListFilter";


    constructor(
        private alertService: AlertService,
        private spinnerService: LoadingSpinnerService,
        private userService: UserService,
        private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.filter = withRestoreFilter(UserFilter, this.storageKey).restoreFilter();
    }

    ngAfterViewInit(): void {
        let search$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            map((event: any) => {
                return event.target.value;
            }),
            filter(res => res.length > 2 || res.length === 0),
            debounceTime(500),
            distinctUntilChanged(),
            tap((searchTerm) => {
                this.filter.pageNumber = 0;
                this.filter.searchTerm = searchTerm;
            })
        )
        let sort$ = this.sort.sortChange.pipe(
            tap((event: Sort) => {
                this.filter.addSortExpression(event.active, event.direction, this.storageKey);
            })
        );
        let page$ = this.paginator.page.pipe(
            tap((event: PageEvent) => {
                this.filter.pageSize = event.pageSize;
                this.filter.pageNumber = event.pageIndex;
            })
        );

        this.list$ = merge(search$, sort$, page$).pipe(
            startWith(null),
            tap(() => {
                this.filter.storeFilter(this.storageKey);
            }),
            switchMap(() => this.getBatchListObservable()),
            shareReplay(1)
        );

    }


    private getBatchListObservable(): Observable<User[] | null | undefined> {
        return this.spinnerService.showLoader(
            this.userService.getList(this.filter).pipe(
                tap({
                    next: (result) => {
                        this.totalRecordCount = result.rowCount;
                    },
                    error: (error) => {
                        this.alertService.addErrorMessage(error);
                    }
                }),
                map(result => result.resultSet)
            )
        );
    }



    deleteUser(userId: number) {
        this.spinnerService.showLoader(this.userService.deleteUser(userId)).subscribe({
            next: () => {
                this.alertService.addMessage($localize`:User list editor user deleted@@user_list_delete_success_message:Gebruiker is verwijderd.`, AlertType.Success);
            },
            error: error => {
                this.alertService.addErrorMessage(error);
            }
        })
    }
}
