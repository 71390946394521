import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FilterItem } from '../filter-header.component';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-filter-header-dialog',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        FormsModule
    ],
    templateUrl: './filter-header-dialog.component.html',
    styleUrl: './filter-header-dialog.component.scss'
})
export class FilterHeaderDialogComponent<T> implements OnInit {
    allSelected: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FilterHeaderDialogComponent<T>>,
        @Inject(MAT_DIALOG_DATA) public data: FilterHeaderDialogData<T>) {

    }

    ngOnInit(): void {
        this.data.filterItems?.sort((a, b) => a.label.localeCompare(b.label));
        if (this.data?.filterItems?.every(t => !t.selected)) this.data.filterItems.forEach(i => i.selected = true);
        this.allSelected = this.data?.filterItems != null && this.data.filterItems.every(t => t.selected);
    }

    cancelClick(): void {
        this.dialogRef.close();
    }

    updateAllSelected() {
        this.allSelected = this.data?.filterItems != null && this.data.filterItems.every(t => t.selected);
    }

    someSelected(): boolean {
        if (this.data?.filterItems == null) {
            return false;
        }
        return this.data.filterItems.filter(t => t.selected).length > 0 && !this.allSelected;
    }

    setAll(selected: boolean) {
        this.allSelected = selected;
        if (this.data?.filterItems == null) {
            return;
        }
        this.data.filterItems.forEach(t => t.selected = selected);
    }

    getSelectedValues() {
        return this.data.filterItems?.every(t => t.selected) ? null : this.data.filterItems?.filter(f => f.selected).map(i => i.value);
    }
}

export class FilterHeaderDialogData<T> {
    public title: string | undefined;
    public filterItems: FilterItem<T>[] | null = null;

    public constructor(init?: Partial<FilterHeaderDialogData<T>>) {
        Object.assign(this, init);
    }
}
