import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user-list/user-list.component';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserService } from '@app/_services';
import { LoginComponent } from './login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedComponentsModule } from '@app/_shared-components/shared-components.module';
import { UserRolePipe } from '@app/_shared-components/enum/user-role.pipe';

@NgModule({
    imports: [
        CommonModule,
        MatListModule,
        BrowserAnimationsModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule,
        MatPaginatorModule,
        SharedComponentsModule,
        FontAwesomeModule,
        MatCheckboxModule,
        UserRolePipe,
        SharedComponentsModule,
        ChangePasswordComponent,
        RecoverPasswordComponent,
        UserComponent,
        UserListComponent,
        LoginComponent
    ],
    providers: [
        UserService
    ]
})
export class UsersModule { }
