import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, WorkItemLogLevel } from '@app/_services';

@Pipe({
    name: 'workItemLogLevel',
    standalone: true,
})
export class WorkItemLogLevelPipe implements PipeTransform {
    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: WorkItemLogLevel | undefined | null): string {
        if (value == null) {
            return $localize`:WorkItemLogLevel undefined:`;
        }
        switch (value) {
            case WorkItemLogLevel.None:
                return $localize`:WorkItemLogLevel name:`;
            case WorkItemLogLevel.Trace:
                return $localize`:WorkItemLogLevel name:Trace`;
            case WorkItemLogLevel.Debug:
                return $localize`:WorkItemLogLevel name:Debug`;
            case WorkItemLogLevel.Information:
                return $localize`:WorkItemLogLevel name:Information`;
            case WorkItemLogLevel.Warning:
                return $localize`:WorkItemLogLevel name:Warning`;
            case WorkItemLogLevel.Error:
                return $localize`:WorkItemLogLevel name:Error`;
            case WorkItemLogLevel.Critical:
                return $localize`:WorkItemLogLevel name:Critical`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `WorkItemLogLevel enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }

}
