import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, AuthenticationService, LoadingSpinnerService, PasswordRecoveryMailStatus, RecoverPasswordRequest, UserService } from '@app/_services';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { LoginComponent } from '@app/users/login/login.component';
import { MatButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgIf, MatError, MatButton]
})
export class RecoverPasswordComponent implements OnInit {
    recoverPasswordForm: FormGroup;

    constructor(
        private userService: UserService,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private router: Router,
    ) {
        this.recoverPasswordForm = new FormGroup(
            {
                emailAddress: new FormControl('', [Validators.required, Validators.email]),
            });
    }

    get email() {
        return this.recoverPasswordForm.get('emailAddress');
    }

    ngOnInit(): void {
        this.email?.setValue(localStorage.getItem(LoginComponent.emailAddressKey));
    }

    recover(): void {
        localStorage.setItem(LoginComponent.emailAddressKey, this.email?.value);
        this.spinnerService.showLoader(this.userService.sendPasswordRecoveryLink(this.email?.value)).subscribe({
            next: result => {
                this.email?.markAsPristine();
                switch (result) {
                    case PasswordRecoveryMailStatus.Success:
                        this.alertService.addMessage($localize`:Recover password email has been send:The password recovery link has been sent via email`, AlertType.Success);
                        break;
                    case PasswordRecoveryMailStatus.AlreadySend:
                        this.alertService.addMessage($localize`:Recover password email allready send:The password recovery link has already been sent recently. Please try again in 10 minutes`, AlertType.Warning);
                        break;
                    case PasswordRecoveryMailStatus.UserNotFound:
                        this.alertService.addMessage($localize`:Recover password email not send user not found:Unable to send the password recovery email because this email address is unknown`, AlertType.Error);
                        break;
                    case PasswordRecoveryMailStatus.Failed:
                        this.alertService.addMessage($localize`:Recover password email has been send:An error occurred during the sending of the password recovery email`, AlertType.Success);
                        break;
                }
            },
            error: (error) => {
                this.alertService.addErrorMessage(error);
            }
        });
    }
}
