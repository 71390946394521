import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { GenericWorkBatch, WorkType, WorkBatchStatus } from '@app/_services';
import { PushNotifications } from '@app/_services/push-notifications.service';
import { WorkBatchStatusPipe } from '../../_shared-components/enum/work-batch-status.pipe';
import { WorkTypePipe } from '../../_shared-components/enum/work-type.pipe';
import { VlmMadLoginComponent } from '../../editors/vlm/mad/vlm-mad-login/vlm-mad-login.component';
import { MatButton } from '@angular/material/button';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-batch-header',
    templateUrl: './batch-header.component.html',
    styleUrls: ['./batch-header.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, MatButton, NgSwitch, NgSwitchCase, VlmMadLoginComponent, WorkTypePipe, WorkBatchStatusPipe]
})
export class BatchHeaderComponent implements OnInit {
    @Input() batch: GenericWorkBatch | undefined;
    @Output() statusChanged = new EventEmitter<WorkBatchStatus>();
    workType = WorkType;
    workBatchStatus = WorkBatchStatus;


    constructor(
        private notifications: PushNotifications
    ) { }

    ngOnInit(): void {

    }

    public updateBatchStatus(status: WorkBatchStatus): void {
        if (status === WorkBatchStatus.Queued) {
            this.notifications.subscribeToNotifications(this.batch!.workBatchId!);
        }
        this.statusChanged.emit(status)
    }
}
