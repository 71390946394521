<ng-container *ngIf="workItem">
    <div class="row">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="Traces Docom customer reference">Reference</mat-label>
                <input matInput i18n-placeholder="Traces Docom customer reference" [disabled]="!!workItem.workItemId && !workItem.canEdit" [(ngModel)]="workItem.customerReference" (change)="dataChanged()">
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="!isCreate">
        <div class="col-4">
            <mat-form-field class="col-12" appearance="fill">
                <mat-label i18n="Traces Docom document reference">Document reference</mat-label>
                <input matInput i18n-placeholder="Traces Docom document reference" [disabled]="!workItem.canEdit" [(ngModel)]="workItem.documentReference" (change)="dataChanged()">
            </mat-form-field>
        </div>
    </div>
</ng-container>