<mat-toolbar [style.background-color]="toolbarColor">
    <ng-container *ngIf="currentUser?.token; else login">
        <!-- <button class="text-end" mat-raised-button color="primary" class="mx-2" *ngIf="!auth.currentUserValue" routerLink="/login" i18n="Login menu button">Login</button> -->
        <button mat-icon-button *ngIf="isSmallScreen" [matMenuTriggerFor]="menu">
            <fa-icon [icon]="faBars" size="2x"></fa-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="menuBatch" i18n="Menu bar batch button">
                <fa-icon [icon]="faBoxesStacked" size="lg"></fa-icon> Batch
            </button>
            <mat-menu #menuBatch="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/batches"]' i18n="Menu batch list menu item">Batch list</button>
            </mat-menu>
            <button mat-menu-item [matMenuTriggerFor]="menuSettings" i18n="Menu bar settings button">
                <fa-icon [icon]="faGears" size="lg"></fa-icon> Settings
            </button>
            <mat-menu #menuSettings="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/settings/document"]' i18n="Menu document settings menu item">Document settings</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/translations"]' i18n="Menu translations menu item">Data translations</button>
            </mat-menu>
            <button mat-menu-item [matMenuTriggerFor]="menuUsers" i18n="Menu bar Users button" *ngIf="isUserAdmin">
                <fa-icon [icon]="faUsers" size="lg"></fa-icon> Users
            </button>
            <mat-menu #menuUsers="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/user/null"]' i18n="Menu new user menu item">New user</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/users"]' i18n="Menu user list menu item">User list</button>
            </mat-menu>
            <button mat-menu-item [matMenuTriggerFor]="menuAccount" i18n="Menu bar Account button" *ngIf="auth.currentUserValue">
                <fa-icon [icon]="faCircleUser" size="lg"></fa-icon> Account
            </button>
            <mat-menu #menuAccount="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/user/change-password"]' i18n="Menu change password menu item">Change password</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" (click)='auth.logout(); false' i18n="Menu logout menu item">Logout</button>
            </mat-menu>
        </mat-menu>

        <div class="menuBar ms-5" *ngIf="!isSmallScreen">
            <button mat-button [matMenuTriggerFor]="menuBatch" i18n="Menu bar batch button">
                <fa-icon [icon]="faBoxesStacked" size="lg"></fa-icon> Batch
            </button>
            <mat-menu #menuBatch="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/batches"]' i18n="Menu batch list menu item">Batch list</button>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="menuSettings" i18n="Menu bar settings button">
                <fa-icon [icon]="faGears" size="lg"></fa-icon> Settings
            </button>
            <mat-menu #menuSettings="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/settings/document"]' i18n="Menu document settings menu item">Document settings</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/translations"]' i18n="Menu translations menu item">Data translations</button>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="menuUsers" i18n="Menu bar users button" *ngIf="isUserAdmin">
                <fa-icon [icon]="faUsers" size="lg"></fa-icon> Users
            </button>
            <mat-menu #menuUsers="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/user/null"]' i18n="Menu new users menu item">New user</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/users"]' i18n="Menu users list menu item">Users list</button>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="menuAccount" i18n="Menu bar Account button" *ngIf="auth.currentUserValue">
                <fa-icon [icon]="faCircleUser" size="lg"></fa-icon> Account
            </button>
            <mat-menu #menuAccount="matMenu">
                <button mat-menu-item [routerLinkActive]="['menu-active']" [routerLink]='["/user/change-password"]' i18n="Menu change password menu item">Change password</button>
                <button mat-menu-item [routerLinkActive]="['menu-active']" (click)='auth.logout(); false' i18n="Menu logout menu item">Logout</button>
            </mat-menu>
        </div>
    </ng-container>
    <ng-template #login>
        <button mat-raised-button color="primary" [routerLinkActive]="['menu-active']" [routerLink]='["/login"]' i18n="Menu login menu item">Login</button>
    </ng-template>
    <div class="title me-2">
        <h1 class="text-uppercase fw-bold fs-3 mt-2">Docmation</h1>
        <span class="fs-6">{{auth.currentUserValue?.firstName}} {{auth.currentUserValue?.lastName}}</span>
    </div>
</mat-toolbar>