<div class="loginHeader m-3" *ngIf="setting">
    <form [formGroup]="form">
        <div class="credentials" formGroupName="credentials">
            <mat-form-field>
                <mat-label i18n="Traces Docom settings username">username</mat-label>
                <input matInput i18n-placeholder="Traces Docom username placeholder" placeholder="username" formControlName="userName">
            </mat-form-field>

            <mat-form-field class="col-12">
                <mat-label i18n="Traces Docom settings password">password</mat-label>
                <input matInput i18n-placeholder="Traces Docom settings password placeholder" type="password" placeholder="password" formControlName="password">
            </mat-form-field>
        </div>
        <div class="loginMethod">
            <mat-label i18n="Traces DOCOM settings login method">2FA method</mat-label>
            <mat-radio-group formControlName="method2FA">
                <mat-radio-button *ngFor="let method of methods2FA" class="py-1" [value]="method">
                    {{method | traces2faMethod}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="loginDevice" *ngIf="method2FAControl.value == traces2FAMethod.AuthenticationAppPincode">
            <mat-form-field>
                <mat-label i18n="Traces DOCOM settings authentication device">authentication device name</mat-label>
                <input matInput i18n-placeholder="Traces DOCOM authentication device placeholder" placeholder="device name" formControlName="deviceName">
            </mat-form-field>
        </div>
        <div class="autoStart" *ngIf="userNameControl.value && passwordControl.value">
            <mat-checkbox formControlName="autoStartBatch" i18n="Traces DOCOM settings auto start checkbox">Auto start</mat-checkbox>
            <div *ngIf="autoStartBatchControl.value" i18n="Autostart traces docom batch after description">Begin automatic document processing {{autoStartBatchTimeout$ | async | formatTimeSpan }} following the receipt of the latest data.</div>
        </div>
        <div class="testMode">
            <mat-checkbox formControlName="testMode" i18n="Traces DOCOM settings testmode checkbox">Test mode</mat-checkbox>
        </div>

    </form>
</div>