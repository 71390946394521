<h1 mat-dialog-title></h1>
<div mat-dialog-content>
    <div class="row">
        <div class="col">
            <ng-container *ngIf="webBotSession; else loading" [ngSwitch]="webBotSession.loginState">
                <ng-container *ngSwitchCase="loginState.AskLoginMethod">
                    <mat-label i18n="VLM MAD settings login method">Login method</mat-label>
                    <mat-radio-group [(ngModel)]="webBotSession.loginMethod" class="d-flex flex-column" (change)="sendLogin(loginState.LoginMethodSet)">
                        <mat-radio-button *ngFor="let method of loginMethods" class="py-1" [value]="method">
                            {{ method | vlmLoginMethod }}
                        </mat-radio-button>
                    </mat-radio-group>
                </ng-container>

                <ng-container *ngSwitchCase="loginState.AskCredentials">
                    <mat-form-field class="col-12">
                        <mat-label i18n="VLM user name">VLM Login name</mat-label>
                        <input matInput [(ngModel)]="webBotSession.username">
                        <!-- <mat-error *ngIf='email.invalid' i18n="User login invalid email error message">Invalid email</mat-error> -->
                    </mat-form-field>
                    <mat-form-field class="col-12">
                        <mat-label i18n="VLM login password">VLM password</mat-label>
                        <input matInput type="password" [(ngModel)]="webBotSession.password">
                        <!-- <mat-error *ngIf='password.invalid' i18n="User login password mandatory error message">Password is mandatory</mat-error> -->
                    </mat-form-field>
                    <div class="alert alert-danger my-3" *ngIf="sessionProgress?.isErrorMessage">
                        {{sessionProgress?.message}}
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <button mat-raised-button i18n="VLM send login credentials" (click)="sendLogin(loginState.CredentialsSet)">Send</button>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="loginState.AskDeviceName">
                    <mat-form-field class="col-12">
                        <mat-label i18n="VLM Device name">Device name</mat-label>
                        <mat-select [(ngModel)]="webBotSession.selectedDeviceName">
                            <mat-option value="" selected></mat-option>
                            <mat-option *ngFor="let name of webBotSession.deviceNames" [value]="name">
                                {{name}}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-error *ngIf='email.invalid' i18n="User login invalid email error message">Invalid email</mat-error> -->
                    </mat-form-field>
                    <div class="col-12 d-flex justify-content-center">
                        <button mat-raised-button i18n="VLM send login credentials" (click)="sendLogin(loginState.DeviceNameSet)">Send</button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="loginState.AskAuthenticationCode">
                    <mat-form-field class="col-12">
                        <mat-label i18n="VLM authentication code">Authentication code</mat-label>
                        <input maxlength="6" minlength="6" matInput [(ngModel)]="webBotSession.authenticationCode" (keyup)="checkAutoSubmit()">
                        <!-- <mat-error *ngIf='email.invalid' i18n="User login invalid email error message">Invalid email</mat-error> -->
                    </mat-form-field>
                    <div class="alert alert-danger my-3" *ngIf="sessionProgress?.isErrorMessage">
                        {{sessionProgress?.message}}
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <button mat-raised-button i18n="VLM send login credentials" (click)="sendLogin(loginState.AuthenticationCodeSet)">Send</button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ng-container *ngTemplateOutlet="loading"></ng-container>
                </ng-container>
            </ng-container>
            <mat-progress-bar *ngIf="sessionProgress?.progress" mode="determinate" class="my-4" [value]="sessionProgress?.progress"></mat-progress-bar>
        </div>

    </div>
</div>
<div mat-dialog-actions>

</div>

<ng-template #loading>
    <mat-spinner [diameter]="80" class="mx-auto mb-4"></mat-spinner>
    <div class="text-center pt-5" *ngIf="!sessionProgress?.isErrorMessage">
        <strong [@fadeInOut]="sessionProgress?.message">{{sessionProgress?.message}}</strong>
    </div>
</ng-template>