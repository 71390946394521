import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService, ConfirmDialogService, ExceptionHandlerService, LoadingSpinnerService } from '@app/_services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertComponent, AlertType } from './alert/alert.component';
import { LoadingSpinnerComponent } from '../_shared-components/loading-spinner/loading-spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { WorkBatchStatusPipe, WorkItemStatusPipe, WorkTypePipe } from './enum';
import { MatPaginatorModule } from '@angular/material/paginator';
import { WorkItemLogLevelPipe } from './enum/work-item-log-level.pipe';
import { CastEnumPipe } from '@app/_helpers/cast-enum.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';






@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatSelectModule,
        FontAwesomeModule,
        MatDatepickerModule,
        MatIconModule,
        RouterModule,
        WorkTypePipe,
        WorkBatchStatusPipe,
        WorkItemStatusPipe,
        MatPaginatorModule,
        CastEnumPipe,
        WorkItemLogLevelPipe,
        MatCheckboxModule,
        WorkTypePipe,
        MatDialogModule,
        AlertComponent,
        LoadingSpinnerComponent,
        ColumnFilterComponent
    ],
    exports: [
        AlertComponent,
        LoadingSpinnerComponent,
        ColumnFilterComponent,
        LoadingSpinnerComponent,
    ],
    providers: [
        ExceptionHandlerService,
        AlertService,
        LoadingSpinnerService,
        ConfirmDialogService,
    ]
})
export class SharedComponentsModule { }
