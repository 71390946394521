import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../_services';
import { AlertMessage, AlertType } from '@app/_shared-components/alert/alert.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private alertService: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.authenticationService.currentUserValue) {
                // auto logout if 401 or 403 response returned from api
                this.authenticationService.refreshToken().subscribe({
                    next: user => {
                        location.reload();
                        if (!user) {
                            this.alertService.addMessage($localize`:Error message:Your session has expired. Please log in again.`, AlertType.Warning, $localize`:Error title:Session expired`, true, 0, true);
                        }
                    }
                });
            }
            if ([500].includes(err.status)) {
                (async () => {
                    this.alertService.alert(new AlertMessage({
                        title: `${err.status} - ${err.statusText}`,
                        message: await err.error.text(),
                        type: AlertType.Error,
                        dismissible: true,
                        autoCloseSeconds: 0,
                        keepAfterRouteChange: true
                    }));
                })();
            }

            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(() => error);
        }))
    }
}
