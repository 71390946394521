import { DialogModule } from '@angular/cdk/dialog';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { Observable, Subject } from 'rxjs';
import { FilterHeaderDialogComponent } from './filter-header-dialog/filter-header-dialog.component';
import { AsyncPipe } from '@angular/common';
import { After } from 'v8';

@Component({
    selector: 'app-filter-header',
    standalone: true,
    imports: [
        MatButtonModule,
        FontAwesomeModule,
        DialogModule,
        AsyncPipe,
        FilterHeaderDialogComponent
    ],
    templateUrl: './filter-header.component.html',
    styleUrl: './filter-header.component.scss'
})
export class FilterHeaderComponent<T> implements OnDestroy, AfterViewInit {
    @Input() title: string = "";
    @Input() filterItems$: Observable<FilterItem<T>[]> | null = null;
    public selectedFilterItems$ = new Subject<T[]>();

    faFilter = faFilter;
    isActive: boolean = false;
    filterItems: FilterItem<T>[] = [];

    constructor(
        private dialog: MatDialog
    ) {
        this.selectedFilterItems$.subscribe(result => {
            this.isActive = result && result.length > 0;
        });

    }

    ngAfterViewInit(): void {
        this.filterItems$?.subscribe(result => {
            this.filterItems = result.sort((a, b) => a.label.localeCompare(b.label));
            this.isActive = this.filterItems.some(t => t.selected);
        });
    }

    public showDialog() {
        const dialogRef = this.dialog.open(FilterHeaderDialogComponent, {
            data: {
                title: this.title,
                filterItems: this.filterItems
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.selectedFilterItems$.next(result);
        });
    }

    ngOnDestroy(): void {
        this.selectedFilterItems$.unsubscribe();
    }
}
export class FilterItem<T> {
    public value: T | null | undefined = null;
    public label: string = "";
    public selected: boolean = false;
}
