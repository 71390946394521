import { Component } from '@angular/core';
import { AuthenticationService, PrintService } from '@app/_services'
import { AlertComponent } from './_shared-components/alert/alert.component';
import { RouterOutlet } from '@angular/router';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NavMenuComponent, RouterOutlet, AlertComponent]
})
export class AppComponent {
    title = 'Docmation';
    constructor(public auth: AuthenticationService, public printService: PrintService) { }

}
