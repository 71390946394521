<div class="row">
    <div class="col-12 col-sm-8 col-md-6 col-lg-4 mat-elevation-z8 mx-auto my-5 p-5">
        <div class="card-header">
            <h3 class="mb-0" i18n="Login title">Login</h3>
        </div>
        <div class="card-body">
            <form class="form" #form="ngForm" (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="form-group">
                    <mat-form-field class="col-12">
                        <mat-label i18n="User login email">Email</mat-label>
                        <input matInput name='email' formControlName='emailAddress'>
                        <mat-error *ngIf='email?.invalid' i18n="User login invalid email error message">Invalid email</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="col-12">
                        <input matInput type="password" name='password' formControlName='password'>
                        <mat-label i18n="User login password">Password</mat-label>
                        <mat-error *ngIf='password?.invalid' i18n="User login password mandatory error message">Password is mandatory</mat-error>
                    </mat-form-field>
                    <div class="col-4"></div>
                </div>
                <div class="form-group buttons">
                    <a [routerLink]="['/user/recover-password']" i18n="User login recover password link">Password reset</a>
                    <button mat-raised-button type="submit" color="primary" i18n="User login login button">Login</button>
                </div>
            </form>
        </div>
    </div>
</div>