import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { WorkBatchStatus, WorkItemLogLevel, WorkType } from '@app/_services';
import { WorkItemLogLevelPipe } from '../enum/work-item-log-level.pipe';
import { CastEnumPipe } from '../../_helpers/cast-enum.pipe';
import { WorkBatchStatusPipe } from '../enum/work-batch-status.pipe';
import { WorkTypePipe } from '../enum/work-type.pipe';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';


@Component({
    selector: 'app-column-filter',
    templateUrl: './column-filter.component.html',
    styleUrls: ['./column-filter.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatDialogContent, MatCheckbox, NgFor, FormsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, MatDialogActions, MatButton, MatDialogClose, WorkTypePipe, WorkBatchStatusPipe, CastEnumPipe, WorkItemLogLevelPipe]
})
export class ColumnFilterComponent<T extends WorkType | WorkBatchStatus | WorkItemLogLevel> implements OnInit {
    WorkItemLogLevel = WorkItemLogLevel;
    WorkBatchStatus = WorkBatchStatus;
    WorkType = WorkType;

    allSelected: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ColumnFilterComponent<T>, Array<T>>,
        @Inject(MAT_DIALOG_DATA) public data: ColumnFilterModalData<T>) { }

    ngOnInit(): void {
        if (this.data?.items?.every(t => !t.selected)) this.data.items.forEach(i => i.selected = true);
        this.allSelected = this.data?.items != null && this.data.items.every(t => t.selected);
    }

    cancelClick(): void {
        this.dialogRef.close();
    }

    updateAllSelected() {
        this.allSelected = this.data?.items != null && this.data.items.every(t => t.selected);
    }

    someSelected(): boolean {
        if (this.data?.items == null) {
            return false;
        }
        return this.data.items.filter(t => t.selected).length > 0 && !this.allSelected;
    }

    setAll(selected: boolean) {
        this.allSelected = selected;
        if (this.data?.items == null) {
            return;
        }
        this.data.items.forEach(t => t.selected = selected);
    }

    getSelectedValues() {
        return this.data.items.every(t => t.selected) ? null : this.data.items.filter(f => f.selected).map(i => i.value);
    }
}


export class ColumnFilterItem<T> {
    value: T | undefined;
    display: string | undefined;
    selected: boolean = false;
    displayControl: string = "";
}
export class ColumnFilterModalData<T> {
    columnName: string | undefined;
    items: Array<ColumnFilterItem<T>> = [];
}
