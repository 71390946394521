<div matSort>
    <div class="gridRow rsGridHeader">
        <div mat-sort-header="timeStamp" class="timeStamp">
            <h5 i18n="Time stamp">Time stamp</h5>
        </div>
        <div class="category">
            <h5 i18n="Category">Category</h5>
            <app-filter-header #filterHeaderCategory [filterItems$]="categories$" i18n-title="Log category filter title" title="Category filter"></app-filter-header>
        </div>
        <div class="code">

        </div>
        <div class="message">

        </div>
        <div class="screenshot">

        </div>
    </div>
    <hr />
    <ng-container *ngIf="list$ | async as list; else noItems">
        <ng-container *ngFor="let log of list">
            <div class="gridRow">
                <div class="timeStamp">
                    {{log.date | date:'short'}}:{{log.date | date:'ss'}}
                </div>
                <div class="category">
                    <span role="alert" class="alert" [class.alert-danger]="log.logLevel === logLevel.Critical || log.logLevel === logLevel.Error" [class.alert-warning]="log.logLevel === logLevel.Warning" [class.alert-info]="log.logLevel === log.logLevel" [class.alert-light]="log.logLevel === logLevel.Debug || log.logLevel === logLevel.Trace || log.logLevel === logLevel.None">
                        {{log.logLevel | workItemLogLevel}}
                    </span>
                </div>
                <div class="code">
                    {{log.workLogCode.text}}
                </div>
                <div class="message">
                    {{log.message}}
                </div>
                <div class="screenshot">
                    <button *ngIf="log.screenShotId" mat-mini-fab aria-label="Screenshot" (click)="openDialog(log)">
                        <mat-icon>photo_camera</mat-icon>
                    </button>
                </div>
            </div>
            <hr />
        </ng-container>
    </ng-container>
    <mat-paginator [pageSizeOptions]="pageSizes" [length]="totalRecordCount" [pageSize]="filter.pageSize"></mat-paginator>
    <ng-template #noItems>
        <span i18n="No work item logs available">No logs available</span>
    </ng-template>
</div>