import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AlertService, Translation, WorkItemOfTracesDocomCreate, WorkItemOfTracesDocomItemContent } from '@app/_services';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-traces-docom-item-header',
    templateUrl: './traces-docom-item-header.component.html',
    styleUrls: ['./traces-docom-item-header.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatInput, FormsModule]
})
export class TracesDocomItemHeaderComponent implements OnInit, OnChanges {
    @Input() workItem: WorkItemOfTracesDocomItemContent | undefined;
    @Output() changed: EventEmitter<unknown> = new EventEmitter();


    constructor(private alertService: AlertService) { }

    get isCreate(): boolean {
        if (!this.workItem) return false;
        return this.workItem instanceof WorkItemOfTracesDocomCreate;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workItem?.currentValue?.content && !changes.workItem.currentValue.content.operatorCode) changes.workItem.currentValue.content.operatorCode = new Translation();
    }

    ngOnInit(): void {
    }

    dataChanged(): void {
        this.changed.emit(null);
    }
}
