export function deepMerge<T>(target: T, source: any): T {
    const isObject = (obj: any): obj is Record<string, any> =>
        !!obj && typeof obj === 'object' && !Array.isArray(obj);

    const isDate = (obj: any): obj is Date =>
        Object.prototype.toString.call(obj) === '[object Date]';

    const isArray = (obj: any): obj is Array<any> =>
        Array.isArray(obj);

    if (!isObject(target)) {
        throw new Error('Target must be an object');
    }

    Object.keys(source).forEach(key => {
        if (isDate(source[key])) {
            (target as any)[key] = new Date(source[key].getTime());
        } else if (isArray(source[key])) {
            if (!isArray(target[key as keyof T])) {
                // Initialize as empty array if not already an array
                (target as any)[key] = [];
            }

            // Merge arrays
            source[key].forEach((item: any, index: number) => {
                if (isObject(item)) {
                    if (index < target[key].length) {
                        // Merge objects if index exists in target
                        deepMerge(target[key][index], item);
                    } else {
                        // Insert item if index does not exist in target
                        target[key].push(item);
                    }
                } else {
                    // Direct assignment for non-object items
                    target[key][index] = item;
                }
            });

            // Remove extra items from target array
            if (target[key].length > source[key].length) {
                target[key].length = source[key].length;
            }
        } else if (isObject(source[key])) {
            if (!isObject(target[key as keyof T])) {
                (target as any)[key] = {};
            }
            deepMerge((target as any)[key], source[key]);
        } else {
            (target as any)[key] = source[key];
        }
    });

    return target;
}
