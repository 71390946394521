/// <reference types="@angular/localize" />

import { enableProdMode, ErrorHandler, LOCALE_ID, APP_INITIALIZER, isDevMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ComponentsModule } from './app/components/components.module';
import { SharedComponentsModule } from './app/_shared-components/shared-components.module';
import { UsersModule } from './app/users/users.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { appInitializer } from './app/_helpers/app.initializer';
import { ErrorInterceptor } from './app/_helpers/error.interceptor';
import { JwtInterceptor } from './app/_helpers/jwt.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { nl } from 'date-fns/locale';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PushNotifications } from './app/_services/push-notifications.service';
import { GlobalErrorHandler } from './app/_helpers/globalErrorHandler';
import { DatePipe, registerLocaleData, CommonModule } from '@angular/common';
import { ExceptionHandlerService, SignalRService, UserService, VlmService, VlmMadService, TracesDocomService, WorkService, PushNotificationsService, API_BASE_URL, AuthenticationService } from './app/_services';
import { DateFnsAdapter, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import localeNl from '@angular/common/locales/nl';

const localeId = 'nl'



//export function getBaseUrl() {
//  return document.getElementsByTagName('base')[0].href;
//}

//const providers = [
//  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
//];

if (environment.production) {
    enableProdMode();
}

registerLocaleData(localeNl);

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule, MatSidenavModule, MatCardModule, MatFormFieldModule, MatInputModule, MatListModule, MatProgressSpinnerModule, MatButtonModule, MatChipsModule, MatIconModule, MatMenuModule, MatToolbarModule, MatTableModule, MatPaginatorModule, MatSortModule, MatDatepickerModule, MatDateFnsModule, MatRadioModule, MatCheckboxModule, MatTooltipModule, MatTabsModule, MatSelectModule, MatDialogModule, DragDropModule, MatExpansionModule, MatBadgeModule, LayoutModule, MatGridListModule, ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            //registrationStrategy: 'registerWhenStable:30000'
            registrationStrategy: 'registerImmediately'
        }), MatProgressBarModule, FontAwesomeModule, UsersModule, SharedComponentsModule, ComponentsModule),
        DateFnsAdapter,
        ExceptionHandlerService,
        SignalRService,
        DatePipe,
        ExceptionHandlerService, {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        UserService,
        VlmService,
        VlmMadService,
        TracesDocomService,
        WorkService,
        PushNotifications,
        PushNotificationsService,
        {
            provide: DateFnsAdapter,
            useClass: DateFnsAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: nl
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: API_BASE_URL,
            useValue: environment.apiRoot
        },
        {
            provide: LOCALE_ID,
            useValue: localeId //https://lokalise.com/blog/angular-i18n/
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (signalrService: SignalRService) => () => signalrService.initiateSignalrConnection(),
            deps: [SignalRService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [
                AuthenticationService,
                UserService
            ]
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
    .catch(err => console.log(err));

