<ng-container *ngLet="workItem$ | async as workItem">
    <app-vlm-mad-item-header [workItem]="workItem" (changed)="updateWorkItem()"></app-vlm-mad-item-header>
    <form [formGroup]="form">

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD previous document reference">Previous document reference</mat-label>
            <input matInput i18n-placeholder="MAD previous document reference placeholder" placeholder="previous document reference" formControlName="previousDocumentReference">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD previous document id">Previous document ID</mat-label>
            <input matInput i18n-placeholder="MAD previous document id placeholder" type="number" placeholder="previous document ID" formControlName="previousDocumentId">
        </mat-form-field>

        <div class="translation" formGroupName="deliveredApproval">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD deliveredApproval">Delivered approval</mat-label>
                <input matInput i18n-placeholder="MAD deliveredApproval" placeholder="deliveredApproval" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD deliveredApproval VLM">Delivered approval in VLM</mat-label>
                <input matInput i18n-placeholder="MAD deliveredApproval VLM placeholder" placeholder="deliveredApproval VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="deliveredApprovalNumber">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD deliveredApprovalNumber">Delivered approval number</mat-label>
                <input matInput i18n-placeholder="MAD deliveredApprovalNumber" placeholder="deliveredApprovalNumber" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD deliveredApprovalNumber VLM">Delivered approval number in VLM</mat-label>
                <input matInput i18n-placeholder="MAD deliveredApprovalNumber VLM placeholder" placeholder="deliveredApprovalNumber VLM" formControlName="documentValue">
            </mat-form-field>
        </div>
        <div class="translation" formGroupName="transporter">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD transporter">Transporter</mat-label>
                <input matInput i18n-placeholder="MAD transporter code" placeholder="transporter" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD transporter VLM">Transporter in VLM</mat-label>
                <input matInput i18n-placeholder="MAD transporter VLM placeholder" placeholder="transporter VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="contractCode">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD contract code">Contract code</mat-label>
                <input matInput i18n-placeholder="MAD contract code" placeholder="contract code" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD contract code">Contract code in VLM</mat-label>
                <input matInput i18n-placeholder="MAD contract code VLM" placeholder="contract code VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="sender">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender">Sender</mat-label>
                <input matInput i18n-placeholder="MAD sender placeholder" placeholder="sender" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender VLM">Sender in VLM</mat-label>
                <input matInput i18n-placeholder="MAD sender VLM placeholder" placeholder="sender VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="senderAlias">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender alias">Sender alias</mat-label>
                <input matInput i18n-placeholder="MAD sender alias placeholder" placeholder="sender alias" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender alias VLM">Sender alias in VLM</mat-label>
                <input matInput i18n-placeholder="MAD sender alias VLM placeholder" placeholder="sender alias VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="senderCountry">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender country">Sender country</mat-label>
                <input matInput i18n-placeholder="MAD sender country placeholder" placeholder="sender country" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender country VLM">Sender country in VLM</mat-label>
                <input matInput i18n-placeholder="MAD sender country VLM placeholder" placeholder="sender country VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="senderTransportCharacter">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender transport character">Sender character</mat-label>
                <input matInput i18n-placeholder="MAD sender transport character placeholder" placeholder="sender transport type" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender transport character VLM">Sender character VLM</mat-label>
                <input matInput i18n-placeholder="MAD sender transport character VLM placeholder" placeholder="sender transport type VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="senderCharacterNumber">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender transport character number">Sender character number</mat-label>
                <input matInput i18n-placeholder="MAD sender transport character number placeholder" placeholder="sender transport type number" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD sender transport character number VLM">Sender character number in VLM</mat-label>
                <input matInput i18n-placeholder="MAD sender transport character number VLM placeholder" placeholder="sender transport type number VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="receiver">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver">Receiver</mat-label>
                <input matInput i18n-placeholder="MAD receiver placeholder" placeholder="receiver" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver VLM">Receiver in VLM</mat-label>
                <input matInput i18n-placeholder="MAD receiver VLM placeholder" placeholder="receiver VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="receiverAlias">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver alias">Receiver alias</mat-label>
                <input matInput i18n-placeholder="MAD receiver alias placeholder" placeholder="receiver alias" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver alias VLM">Receiver alias in VLM</mat-label>
                <input matInput i18n-placeholder="MAD receiver alias VLM placeholder" placeholder="receiver alias VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="receiverCountry">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver country">Receiver country</mat-label>
                <input matInput i18n-placeholder="MAD receiver country placeholder" placeholder="receiver country" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver country VLM">Receiver country in VLM</mat-label>
                <input matInput i18n-placeholder="MAD receiver country VLM placeholder" placeholder="receiver country VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="receiverTransportCharacter">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver transport character">Receiver character</mat-label>
                <input matInput i18n-placeholder="MAD receiver transport character placeholder" placeholder="receiver" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver transport character VLM">Receiver character in VLM</mat-label>
                <input matInput i18n-placeholder="MAD receiver transport character VLM placeholder" placeholder="receiver VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="receiverCharacterNumber">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver transport character number">Receiver character number</mat-label>
                <input matInput i18n-placeholder="MAD receiver transport character number placeholder" placeholder="receiver" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD receiver transport character number VLM">Receiver character number in VLM</mat-label>
                <input matInput i18n-placeholder="MAD receiver transport character number VLM placeholder" placeholder="receiver VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="manureType">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD manure type">Manure type</mat-label>
                <input matInput i18n-placeholder="MAD manure type placeholder" placeholder="manure type" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD manure type VLM">Manure type in VLM</mat-label>
                <input matInput i18n-placeholder="MAD manure type VLM placeholder" placeholder="manure type VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="manureContentValue">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD manure content value">Manure content value</mat-label>
                <input matInput i18n-placeholder="MAD manure content value placeholder" placeholder="manure content value place holder" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD manure content value VLM">Manure content value in VLM</mat-label>
                <input matInput i18n-placeholder="MAD manure content value VLM placeholder" placeholder="MAD manure content value VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD date">Date</mat-label>
            <input matInput i18n-placeholder="MAD date placeholder" placeholder="date" [matDatepicker]="datePicker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label i18n="MAD resume date">Resume date</mat-label>
            <input matInput i18n-placeholder="MAD resume date placeholder" placeholder="date" [matDatepicker]="resumeDatePicker" formControlName="resumeDate">
            <mat-datepicker-toggle matSuffix [for]="resumeDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #resumeDatePicker></mat-datepicker>
        </mat-form-field>

        <div class="translation" formGroupName="licensePlate">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD license plate">License plate</mat-label>
                <input matInput i18n-placeholder="MAD license plate placeholder" placeholder="license plate" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD license plate VLM">License plate in VLM</mat-label>
                <input matInput i18n-placeholder="MAD license plate VLM placeholder" placeholder="license plate VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <div class="translation" formGroupName="licensePlateTrailer">
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD license plate trailer">License plate trailer</mat-label>
                <input matInput i18n-placeholder="MAD license plate trailer placeholder" placeholder="license plate trailer" formControlName="sendValue">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="MAD license plate trailer VLM">License plate trailer in VLM</mat-label>
                <input matInput i18n-placeholder="MAD license plate trailer VLM placeholder" placeholder="license plate trailer VLM" formControlName="documentValue">
            </mat-form-field>
        </div>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD planned load">Planned load</mat-label>
            <input matInput i18n-placeholder="MAD planned load placeholder" type="number" placeholder="planned load" formControlName="plannedLoad">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD content kg ton N">kg ton N</mat-label>
            <input matInput i18n-placeholder="MAD content kg ton N placeholder" type="number" placeholder="kg ton N" formControlName="contentKgTonN">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD content kg ton P2O2">kg ton P2O2</mat-label>
            <input matInput i18n-placeholder="MAD content kg ton P2O2 placeholder" type="number" placeholder="kg ton P2O2" formControlName="contentKgTonP2O2">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label i18n="MAD planned freights">Planned freights</mat-label>
            <input matInput i18n-placeholder="MAD planned freights placeholder" type="number" placeholder="planned freights" formControlName="plannedFreights">
        </mat-form-field>

    </form>

</ng-container>