import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

@Pipe({
    name: 'formatTimeSpan',
    standalone: true
})
export class FormatTimeSpanPipe implements PipeTransform {

    transform(value: string | null, options?: any): string {
        if (value == null) {
            return '';
        }
        // Split the input string to separate days (if present) and the time
        let parts = value.split('.');
        let days = 0;
        let time = value;
        let ticks = 0;
        if (parts[0].indexOf(':') == -1) {
            days = parseInt(parts[0], 10);
        }
        else {
            time = parts[0];
        }
        if (parts[parts.length - 1].indexOf(':') == -1) {
            ticks = parseInt(parts[parts.length - 1], 10);
        }

        // Split the time part into hours, minutes, seconds, and milliseconds
        const timeParts = time.split(':');
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const secondsParts = timeParts[2].split('.');
        const seconds = parseInt(secondsParts[0], 10);

        // Calculate the total duration in milliseconds
        const totalMilliseconds = (((days * 24 + hours) * 60 + minutes) * 60 + seconds) * 1000 + (ticks / 10000);

        // Use date-fns to format the duration
        const duration = intervalToDuration({ start: 0, end: totalMilliseconds });
        let formattedDuration = formatDuration(duration, options);

        return formattedDuration;

    }
}
