import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, Authentication, AuthenticationService, ChangePasswordRequest, LoadingSpinnerService, RecoverPasswordRequest, ResetPasswordRequest, UserService } from '@app/_services';
import { AlertType } from '@app/_shared-components/alert/alert.component';
import { LoginComponent } from '@app/users/login/login.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatFormField, MatLabel, MatInput, MatError, MatButton]
})
export class ChangePasswordComponent implements OnInit {
    @ViewChild("form") form: NgForm | null = null;
    changePasswordForm: FormGroup;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private spinnerService: LoadingSpinnerService,
        private alertService: AlertService,
        private userService: UserService,
        private authService: AuthenticationService
    ) {
        this.changePasswordForm = new FormGroup({
            currentPassword: new FormControl('', [Validators.required]),
            newPassword: new FormControl('', [
                Validators.required,
                //Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
                Validators.minLength(6),
                Validators.maxLength(25),
                matchValidator('verifyPassword', true)]),
            verifyPassword: new FormControl('', [
                Validators.required,
                matchValidator('newPassword')])
        });
    }

    get currentPassword() {
        return this.changePasswordForm.get("currentPassword")
    }
    get newPassword() {
        return this.changePasswordForm.get("newPassword")
    }
    get verifyPassword() {
        return this.changePasswordForm.get("verifyPassword")
    }
    private sub: any;
    public token: string | null = null;

    ngOnInit(): void {

        this.sub = this.route.params.subscribe(params => {
            if (params['token']) {
                this.token = params['token'];
                this.currentPassword?.clearValidators();
            }
        });
    }

    changePassword(): void {
        if (this.changePasswordForm.invalid) return;
        if (this.token) {
            this.spinnerService.showLoader(this.userService.recoverPassword(new RecoverPasswordRequest({ password: this.newPassword?.value, recoverPasswordData: this.token }))).subscribe({
                next: username => {
                    this.authService.login(username, this.newPassword?.value).subscribe({
                        next: () => {
                            localStorage.setItem(LoginComponent.emailAddressKey, username);
                            this.router.navigate(['/']);
                            this.form?.resetForm();
                            this.alertService.addMessage($localize`:Change password has been saved:Password has been changed`, AlertType.Success);
                        },
                        error: error => {
                            this.alertService.addErrorMessage(error);
                        }
                    });
                },
                error: error => {
                    this.alertService.addErrorMessage(error);
                }
            });
        }
        else {
            this.spinnerService.showLoader(this.userService.changePassword(new ChangePasswordRequest({ oldPassword: this.currentPassword?.value, newPassword: this.newPassword?.value }))).subscribe({
                next: () => {
                    this.form?.resetForm();
                    this.router.navigate(['/']);
                    this.alertService.addMessage($localize`:Change password has been saved:Password has been changed.`, AlertType.Success);
                },
                error: error => {
                    this.alertService.addErrorMessage(error);
                }
            })
        }
    }
}

//https://dev.to/jdgamble555/angular-confirm-password-validation-custom-validator-3pkl
export function matchValidator(
    matchTo: string,
    reverse?: boolean
): ValidatorFn {
    return (control: AbstractControl):
        ValidationErrors | null => {
        if (control.parent && reverse) {
            const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
            if (c) {
                c.updateValueAndValidity();
            }
            return null;
        }
        return !!control.parent &&
            !!control.parent.value &&
            control.value ===
            (control.parent?.controls as any)[matchTo].value
            ? null
            : { matching: true };
    };
}
