import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LoadingSpinnerService, VlmMadService, SignalRService, AlertService } from '@app/_services'
import { from, mergeMap, timeout, timer } from 'rxjs';

@Component({
    selector: 'app-vlm-mad-login',
    templateUrl: './vlm-mad-login.component.html',
    styleUrls: ['./vlm-mad-login.component.scss'],
    standalone: true
})
export class VlmMadLoginComponent implements OnInit {
    @Input() batchId: number | undefined;
    // login: VlmMadLogin;
    // loginState = VlmMadLoginState;

    // spinnerStates: Array<VlmMadLoginState> = [VlmMadLoginState.CredentialsSet, VlmMadLoginState.DeviceNameSet, VlmMadLoginState.AuthenticationCodeSet]

    constructor(private service: VlmMadService, private signalRService: SignalRService, private spinnerService: LoadingSpinnerService, private alertService: AlertService) { }


    ngOnInit(): void {
        this.signalRService.sessionStatusChanged.subscribe((workBatchId: number | null) => {
            if (workBatchId === this.batchId) this.getRpaLogin();
        });
        this.getRpaLogin();
    }

    private getRpaLogin(): void {
        // this.service.getRpaLogin(this.batchId).subscribe({
        //     next: result => {
        //         this.login = result
        //         this.showSpinner();
        //         if (this.login?.loginSuccess !== true && this.login?.loginMessage) {
        //             this.alertService.addMessage(this.login.loginMessage, AlertType.Warning, $localize`:MAD login error|Login failure title@@mad_login_failure_title:Login failure`, true, 0);
        //         }
        //         if (this.login?.loginSuccess === true) {
        //             this.alertService.addMessage($localize`:MAD login success@@mad_login_success:Successfully logged into VLM`, AlertType.Success, null, true, 5);
        //         }
        //     },
        //     error: (error) => {
        //         this.login = undefined;
        //         this.alertService.addErrorMessage(error);
        //     }
        // });
    }



    private showSpinner(): void {
        // if (this.login?.loginSuccess == null && this.spinnerStates.findIndex(s => this.login?.loginState === s) >= 0) {
        //     this.spinnerService.show($localize`:MAD login spinner@@mad_login_spinner:Robot is working`);
        // }
        // else {
        //     this.spinnerService.hide();
        // }
    }

    // sendLogin(state: VlmMadLoginState): void {
    //     this.login.loginState = state;
    //     this.spinnerService.showLoader(this.service.updateRpaLogin(this.login)).subscribe({
    //         next: (result) => {
    //             this.login = result
    //             this.showSpinner();
    //         },
    //         error: (error) => {
    //             this.login = undefined;
    //             this.alertService.addErrorMessage(error);
    //         }
    //     });
    // }

    // checkAutoSubmit(): void {
    //     if (this.login.authenticationCode.length === 6) {
    //         this.sendLogin(VlmMadLoginState.AuthenticationCodeSet);
    //     }
    // }
}
