import * as i0 from '@angular/core';
import { Directive, Input, NgModule } from '@angular/core';

/**
 * @ngModule NgLetModule
 *
 * @description
 *
 * The `*ngLet` directive it's a Angular structural directive for sharing data as local variable into html component template..
 *
 * @usageNotes
 *
 * ### Usage
 *
 * ```html
 * <ng-container *ngLet="(num1 + num2); let total"> <!-- single computation -->
 *    <div>
 *       1: {{ total }}
 *     </div>
 *     <div>
 *       2: {{ total }}
 *     </div>
 * </ng-container>
 * ```
 *
 * @publicApi
 */
class NgLetDirective {
  constructor(viewContainer, templateRef) {
    this.viewContainer = viewContainer;
    this.templateRef = templateRef;
    this.context = {
      ngLet: null,
      $implicit: null
    };
    this.hasView = false;
  }
  set ngLet(value) {
    this.context.$implicit = this.context.ngLet = value;
    if (!this.hasView) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    }
  }
  /**
   * Asserts the correct type of the context for the template that `NgLet` will render.
   *
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgLet` structural directive renders its template with a specific context type.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static ngTemplateContextGuard(dir, ctx) {
    return true;
  }
  static {
    this.ɵfac = function NgLetDirective_Factory(t) {
      return new (t || NgLetDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgLetDirective,
      selectors: [["", "ngLet", ""]],
      inputs: {
        ngLet: "ngLet"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgLetDirective, [{
    type: Directive,
    args: [{
      selector: '[ngLet]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.TemplateRef
  }], {
    ngLet: [{
      type: Input
    }]
  });
})();
class NgLetModule {
  static {
    this.ɵfac = function NgLetModule_Factory(t) {
      return new (t || NgLetModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgLetModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgLetModule, [{
    type: NgModule,
    args: [{
      declarations: [NgLetDirective],
      exports: [NgLetDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-let
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgLetDirective, NgLetModule };
