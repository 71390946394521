import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, WorkItemStatus } from '@app/_services';

@Pipe({
    name: 'workItemStatus',
    standalone: true,
})
export class WorkItemStatusPipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: WorkItemStatus | undefined | null): string {
        if (value == null) {
            return $localize`:WorkItemStatus undefined:`;
        }
        switch (value) {
            case WorkItemStatus.Undefined:
                return $localize`:WorkItemStatus undefined:`;
            case WorkItemStatus.Invalid:
                return $localize`:WorkItemStatus invalid:Invalid`;
            case WorkItemStatus.Created:
                return $localize`:WorkItemStatus created:Created`;
            case WorkItemStatus.Queued:
                return $localize`:WorkItemStatus queued:Queued`;
            case WorkItemStatus.Processing:
                return $localize`:WorkItemStatus processing:Processing`;
            case WorkItemStatus.Faulted:
                return $localize`:WorkItemStatus faulted:Faulted`;
            case WorkItemStatus.Finished:
                return $localize`:WorkItemStatus finished:Finished`;
            case WorkItemStatus.FeedbackSend:
                return $localize`:WorkItemStatus feedback send:Feedback send`;
            case WorkItemStatus.Deleted:
                return $localize`:WorkItemStatus deleted:Deleted`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `WorkItemStatus enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
