import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BatchListFilter, User, WorkItemFilter } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() { }

    public saveData(key: string, value: any): void {
        let data = JSON.stringify(value);
        localStorage.setItem(key, data);
    }

    public getData(key: string): any | undefined {
        let data = localStorage.getItem(key) || "";
        if (!data) return undefined;
        return JSON.parse(data);
    }

    public removeData(key: string) {
        localStorage.removeItem(key);
    }

    public clearData() {
        localStorage.clear();
    }

    private readonly keyEmailAddress = "emailAddress";
    public saveEmailAddress(emailAddress: string){
        this.saveData(this.keyEmailAddress, emailAddress)
    }
    public getEmailAddress(): string{
        return this.getData(this.keyEmailAddress)
    }
    private readonly keyCurrentUser = "currentUser";
    public saveCurrentUser(user: User){
        this.saveData(this.keyCurrentUser, user)
    }
    public getCurrentUser(): User{
        return User.fromJS(this.getData(this.keyCurrentUser));
    }
    public removeCurrentUser(){
        this.removeData(this.keyCurrentUser);
    }

    private readonly keyBatchListFilter = "batchListFilterKey";
    public saveBatchListFilter(filter: BatchListFilter): void{
        this.saveData(this.keyBatchListFilter, filter.toJSON());
    }
    public getBatchListFilter(): BatchListFilter {
        return BatchListFilter.fromJS(this.getData(this.keyBatchListFilter));
    }

    private readonly keyWorkItemFilter = "workItemFilterKey";
    public saveWorkItemFilter(filter: WorkItemFilter): void{
        this.saveData(this.keyWorkItemFilter, filter.toJSON());
    }
    public getWorkItemFilter(): WorkItemFilter {
        return WorkItemFilter.fromJS(this.getData(this.keyWorkItemFilter));
    }
}
