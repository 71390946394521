import { Component, Input, OnInit } from '@angular/core';
import { LoadingSpinnerService, VlmMadService, TaskType, WorkItemOfVlmMadItemContent } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { AlertMessage, AlertType } from '@app/_shared-components/alert/alert.component';
import { TaskTypePipe } from '../../../../_shared-components/enum/task-type.pipe';
import { AlertComponent } from '../../../../_shared-components/alert/alert.component';
import { VlmMadDownloadComponent } from '../vlm-mad-download/vlm-mad-download.component';
import { VlmMadCreateComponent } from '../vlm-mad-create/vlm-mad-create.component';
import { VlmMadCloseComponent } from '../vlm-mad-close/vlm-mad-close.component';
import { VlmMadCancelComponent } from '../vlm-mad-cancel/vlm-mad-cancel.component';
import { MatOption } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    selector: 'app-vlm-mad-item',
    templateUrl: './vlm-mad-item.component.html',
    styleUrls: ['./vlm-mad-item.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormField, MatLabel, MatSelect, FormsModule, MatOption, NgFor, NgSwitch, NgSwitchCase, VlmMadCancelComponent, VlmMadCloseComponent, VlmMadCreateComponent, VlmMadDownloadComponent, NgSwitchDefault, AlertComponent, TaskTypePipe]
})
export class VlmMadItemComponent implements OnInit {
    @Input() workItem: WorkItemOfVlmMadItemContent | undefined;
    @Input() workItemId?: number;

    alertsUnknownTaskType: Array<AlertMessage> = [new AlertMessage({ message: $localize`:VLM MAD unkown work item type@@vlm_mad_unknown_work_item_type:Unknown task type`, type: AlertType.Error })]

    taskType = TaskType;
    readonly taskTypes: Array<TaskType> = Object.values(TaskType);

    constructor(private service: VlmMadService, private spinnerService: LoadingSpinnerService, private alertService: AlertService) {
    }

    ngOnInit(): void {
        if (!this.workItem && this.workItemId) this.fetchVlmMadWorkItem();
    }

    private fetchVlmMadWorkItem() {
        this.spinnerService.showLoader(this.service.getWorkItem(this.workItemId)).subscribe(result => {
            this.workItem = result;
        })
    }

    workItemUpdated(workItem: WorkItemOfVlmMadItemContent): void {
        this.workItem = workItem;
    }


}
