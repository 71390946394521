import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap, RouterLink } from '@angular/router';
import { WorkService, GenericWorkBatch, WorkBatchStatus } from '@app/_services/api.service'
import { LoadingSpinnerService, SignalRService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { faHome } from '@fortawesome/pro-regular-svg-icons';
import { BatchContentComponent } from '../batch-content/batch-content.component';
import { BatchHeaderComponent } from '../batch-header/batch-header.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-batch-item',
    templateUrl: './batch-item.component.html',
    styleUrls: ['./batch-item.component.scss'],
    standalone: true,
    imports: [RouterLink, FaIconComponent, BatchHeaderComponent, BatchContentComponent]
})
export class BatchItemComponent implements OnInit {
    faHome = faHome;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private workService: WorkService,
        private spinnerService: LoadingSpinnerService,
        private signalRService: SignalRService,
        private alertService: AlertService) { }

    private sub: any;
    batchId!: number;
    loading = false;
    batch!: GenericWorkBatch;


    ngOnInit(): void {
        this.sub = this.route.queryParams.subscribe(params => {
            if (params['id']) {
                this.batchId = +params['id'];
                this.loadBatch();
            }
        });

        this.signalRService.workItemStatusChanged.subscribe(key => {
            if (key?.workBatchId && this.batch?.workBatchId === key?.workBatchId) {
                this.loadBatch();
            }
        })

    }

    private setIdParam(id: any): void {
        if (id) {
            this.batchId = +id;
            this.loadBatch;
        }
    }

    private loadBatch(): void {
        this.spinnerService.showLoader(this.workService.getGenericBatch(this.batchId)).subscribe({
            next: (result) => {
                this.batch = result;
            },
            error: (error) => {
                this.alertService.addErrorMessage(error);
            }
        });
    }

    statusChanged(status: WorkBatchStatus): void {
        this.spinnerService.showLoader(this.workService.changeBatchStatus(this.batch.workBatchId, status)).subscribe({
            next: (result) => {
                this.batch = result;
            },
            error: (error) => {
                this.alertService.addErrorMessage(error);
            }
        });
    }

}
