<div class="row">
    <div class="col-12 col-sm-8 col-md-6 col-lg-4 mat-elevation-z8 mx-auto my-5 p-5">
        <div class="card-header">
            <h3 class="mb-0" i18n="Recover password title">Recover password</h3>
        </div>
        <div class="card-body">
            <form class="form" #form="ngForm" (ngSubmit)="recover()" [formGroup]="recoverPasswordForm">
                <div class="form-group">
                    <mat-form-field class="col-12">
                        <mat-label i18n="User login email">Email</mat-label>
                        <input matInput name='email' formControlName='emailAddress'>
                        <mat-error *ngIf='email?.invalid' i18n="User login invalid email error message">Invalid email address</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <button mat-raised-button type="submit" color="primary" i18n="User recover password button">Recover</button>
                </div>
            </form>
        </div>
    </div>
</div>