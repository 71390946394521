import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    private dialogRef: MatDialogRef<ConfirmDialogComponent> | undefined;

    constructor(private dialog: MatDialog) {

    }

    showDialog(data: ConfirmDialogData, disableClose: boolean = false): Observable<any> {
        this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: disableClose,
            data: data
        })
        return this.dialogRef.afterClosed();
    }

}
