import { Pipe, PipeTransform } from '@angular/core';
import { ExceptionHandlerService, ExceptionLog, WorkType } from '@app/_services';

@Pipe({
    name: 'workType',
    standalone: true,
})
export class WorkTypePipe implements PipeTransform {

    constructor(private exceptionHandlerService: ExceptionHandlerService) {
    }

    transform(value: WorkType | undefined | null): string {
        if (value == null) {
            return $localize`:WorkType undefined:`;
        }
        switch (value) {
            case WorkType.Undefined:
                return $localize`:WorkType undefined:`;
            case WorkType.Vlm_Mad:
                return $localize`:WorkType mad:VLM MAD`;
            case WorkType.Traces_Docom:
                return $localize`:WorkType traces:Traces DOCOM`;
            default:
                this.exceptionHandlerService.logFatal(new ExceptionLog({ message: `WorkType enum '${value}' doesn't have an Angular translation` })).subscribe();
                return value;
        }
    }
}
